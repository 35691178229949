import React from 'react'

export const OrganizationsIcon: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M20.7925 9.52349C21.5826 10.3135 21.5826 11.5944 20.7925 12.3844C20.0025 13.1745 18.7216 13.1745 17.9316 12.3844C17.1415 11.5944 17.1415 10.3135 17.9316 9.52349C18.7216 8.73346 20.0025 8.73346 20.7925 9.52349"
        stroke="#3B3B3B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2026 5.91236C15.4191 7.12884 15.4191 9.10115 14.2026 10.3176C12.9861 11.5341 11.0138 11.5341 9.79731 10.3176C8.58083 9.10116 8.58083 7.12885 9.79731 5.91236C11.0138 4.69588 12.9861 4.69588 14.2026 5.91236"
        stroke="#3B3B3B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.06846 9.52349C6.85849 10.3135 6.85849 11.5944 6.06846 12.3844C5.27843 13.1745 3.99754 13.1745 3.20751 12.3844C2.41748 11.5944 2.41748 10.3135 3.20751 9.52349C3.99754 8.73346 5.27843 8.73346 6.06846 9.52349"
        stroke="#3B3B3B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 19.0001V17.9041C23 16.5231 21.881 15.4041 20.5 15.4041H19.699"
        stroke="#3B3B3B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 19.0001V17.9041C1 16.5231 2.119 15.4041 3.5 15.4041H4.301"
        stroke="#3B3B3B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.339 19V17.399C17.339 15.466 15.772 13.899 13.839 13.899H10.16C8.22697 13.899 6.65997 15.466 6.65997 17.399V19"
        stroke="#3B3B3B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
