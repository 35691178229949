import { history } from '@busie/utils'
import qs from 'qs'

export const getStatusQueryParam = () => {
  return (
    qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    }).status || 'ALL'
  )
}
