import { track } from '@amplitude/analytics-browser'
import { createGroup } from '@busie/api'
import { useGroupsSearch, useUserContext } from '@busie/core'
import {
  NumberInputEvent,
  SelectAsync,
  SelectAsyncOption,
  TextInputEvent,
} from '@busie/ui-kit'
import { Group } from '@busie/utils'
import { FC, useState } from 'react'
import { SingleValue } from 'react-select'
import store from '../../../store'
import { CustomerFormFieldsKeys } from '../../../store/customerForm/store.types'

interface GroupsSelectAsyncProps {
  handlerOnChange: (
    e: TextInputEvent | NumberInputEvent,
    name: CustomerFormFieldsKeys
  ) => void
}

export const GroupSelectAsync: FC<GroupsSelectAsyncProps> = ({
  handlerOnChange,
}) => {
  const { customersAuthToken } = useUserContext()
  const customerForm = store.customerForm

  const [query, setQuery] = useState<string | undefined>()

  const [selectedGroupOption, setSelectedGroupOption] =
    useState<SingleValue<SelectAsyncOption>>(null)

  const {
    data: groups,
    isFetching,
    invalidateQueryCallback,
  } = useGroupsSearch(customersAuthToken, query, {
    enabled: !!query && query.length >= 2,
  })

  const promiseOptions = (inputValue: string) => {
    return new Promise<SelectAsyncOption[]>((resolve) => {
      if (inputValue.length >= 2) {
        setQuery(inputValue)
        setTimeout(() => {
          const options = groups?.results.map(createGroupOption) || []
          resolve(options)
        }, 300)
      } else {
        resolve([])
      }
    })
  }

  const handleCreateGroup = async (groupName: string) => {
    const group = await createGroup(customersAuthToken, { name: groupName })

    track('trip planning customer group created')
    handlerOnChange(
      { target: { value: group.id } } as React.ChangeEvent<HTMLInputElement>,
      'organization'
    )

    customerForm.setGroup(group)
    setSelectedGroupOption({ value: group.id, label: group.name })
  }

  const handleSelectGroup = (
    newValue: SingleValue<SelectAsyncOption>,
    { action }: { action: string }
  ) => {
    switch (action) {
      case 'clear':
        handlerOnChange({ target: { value: '' } }, 'organization')
        customerForm.setGroup(null)
        setSelectedGroupOption(null)
        break

      case 'select-option':
        if (newValue) {
          handlerOnChange(
            {
              target: { value: newValue.value },
            } as React.ChangeEvent<HTMLInputElement>,
            'organization'
          )
          customerForm.setGroup({
            id: newValue.value,
            name: newValue.label,
          } as Group)

          setSelectedGroupOption(newValue)
        }
        break

      default:
        break
    }
  }

  const validateNewGroupName = (inputValue: string) =>
    /\w/.test(inputValue) && inputValue.length >= 2

  const invalidateQuery = () => {
    if (query) {
      invalidateQueryCallback()
      setQuery(undefined)
    }
  }

  const createGroupOption = (group: Group): SelectAsyncOption => ({
    value: group.id,
    label: group.name,
  })

  return (
    <SelectAsync
      label="Group (search or create)"
      loadOptions={promiseOptions}
      value={selectedGroupOption}
      onChange={handleSelectGroup}
      placeholder="Search..."
      fullWidth
      onCreateOption={handleCreateGroup}
      isLoading={isFetching}
      isValidNewOption={validateNewGroupName}
      onBlur={invalidateQuery}
    />
  )
}
