import { useEffect, useState } from 'react'
import { TripLeg, TripLegPriceStructure } from '@busie/utils'

export const useTripLegVehiclePricing = (
  leg: TripLeg,
  vehicleId: string
): TripLegPriceStructure => {
  const [pricing, setPricing] = useState<TripLegPriceStructure>(
    {} as TripLegPriceStructure
  )

  useEffect(() => {
    if (!leg || !vehicleId) return setPricing({} as TripLegPriceStructure)

    const vehiclePricingMap = new Map(
      leg._legPrice.map((legPrice) => [legPrice.vehicleId, legPrice])
    )

    setPricing(
      vehiclePricingMap.get(vehicleId) || ({} as TripLegPriceStructure)
    )
  }, [leg, vehicleId])

  return pricing
}
