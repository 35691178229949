import { useAuth0 } from '@auth0/auth0-react'
import { type FC, type PropsWithChildren, useCallback } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { NotificationContainer } from '@busie/features'
import { Layout } from '@busie/ui-kit'
import { User } from '@busie/utils'

import { isFeatureEnabled } from '../../utils/featureFlags'
import { routes as routesConfig } from './config'
import { useAuthAndFeatures } from './hooks/useAuthAndFeatures'
import { useHeaderLinks } from './hooks/useHeaderLinks'
import { useOnboarding } from './hooks/useOnboarding'
import { RouteConfig } from './types'
import { ensureFunctionComponent, LazyRoute } from './utils'
/**
 * Internal pages component that handles routing and layout for authenticated sections
 * of the application.
 *
 * This component:
 * - Manages authentication state using Auth0
 * - Handles feature flags and subscription plan data
 * - Configures navigation header links
 * - Renders routes based on configuration
 * - Provides layout wrapper with navigation
 * - Shows notifications
 *
 * @component
 * @param {PropsWithChildren<unknown>} props - React component props
 * @returns {JSX.Element} Rendered component
 */

export const InternalPages: FC<
  PropsWithChildren<unknown>
> = (): JSX.Element => {
  const { user } = useAuth0<User>()
  const { flags, plan } = useAuthAndFeatures({ user })

  const routes = routesConfig.filter((route) => {
    const isEnabled = isFeatureEnabled(flags, route.featureFlag)
    return isEnabled
  })

  useOnboarding(user)

  const buildHeaderLinks = useHeaderLinks({ routes, flags, plan })

  const renderRoute = useCallback((route: RouteConfig) => {
    if (route.redirectTo) {
      return (
        <Route key={route.path} path={route.path} exact={route.exact}>
          <Redirect
            to={{
              pathname: route.redirectTo,
              search: window.location.search,
            }}
          />
        </Route>
      )
    }

    if (!route.component) return null

    const LazyComponent = LazyRoute({ component: route.component })
    const Component = route.protected
      ? ensureFunctionComponent(LazyComponent)
      : LazyComponent

    return (
      <Route
        key={route.path}
        path={route.path}
        exact={route.exact}
        strict={route.strict}
        component={Component}
      />
    )
  }, [])

  return (
    <Layout headerLinks={buildHeaderLinks()}>
      <Switch>{routes.map(renderRoute)}</Switch>
      <NotificationContainer />
    </Layout>
  )
}
