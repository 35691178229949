import { dayjsExt, DayjsExt, getDateTime } from '@busie/utils'

import { RouteStopDeparture } from './store.types'

export const validateForm = (departures: RouteStopDeparture[]): boolean => {
  const results = departures.map((departure) => {
    return departure.date && departure.time
      ? !getDateTime(departure.date, departure.time).isBefore(
          departure.minDateTime
        )
      : false
  })
  // check if falsy element is last,
  // because 'Last stop does not have a departure date.'
  return results.findIndex((item) => item === false) === departures.length - 1
}

export const getArrivalDateTime = (
  date: Date,
  time: DayjsExt | null,
  travelTime: number
): Date => {
  const arrivalDate = getDateTime(date, time).add(
    dayjsExt.duration({ seconds: travelTime })
  )

  return arrivalDate.toDate()
}
