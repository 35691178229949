import { styled, Theme } from '@mui/system'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import { paperClasses } from '@mui/material/Paper'

interface Props {
  bgcolor?: string
  theme?: Theme
  maxWidth?: string
  minWidth?: string
  zIndex?: string
  extraStyles?: { [key: string]: string }
}

const PopupStyled = styled(Dialog)<DialogProps & Props>((props) => {
  const palette = props.theme?.palette
  const { extraStyles = {} } = props
  return {
    zIndex: props.zIndex,
    ...extraStyles,

    [`.${paperClasses.root}`]: {
      overflow: 'initial',
      backgroundColor: props.bgcolor || palette.black.plus100,
      maxWidth: props.maxWidth || '1024px',
      minWidth: props.minWidth || '400px',
    },
  }
})

export default PopupStyled
