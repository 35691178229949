import React from 'react'
import { VehicleIconProps as Props } from '.'

export const MiniBusIcon: React.FC<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="5" y="37" width="4" height="1" rx="0.5" fill="#222222" />
      <rect x="18" y="25" width="8" height="8" rx="1" stroke="#FF0018" />
      <path
        d="M13.25 43H7C5.89543 43 5 42.1046 5 41V31.8379C5 31.6142 5.03752 31.3921 5.11101 31.1809L8.53288 21.343C8.81256 20.5389 9.57057 20 10.4219 20H48C49.1046 20 50 20.8954 50 22V41C50 42.1046 49.1046 43 48 43H42.75M20.875 43H27.5H35.125"
        stroke="#FF0018"
      />
      <path
        d="M7 25H13C13.5523 25 14 25.4477 14 26V32C14 32.5523 13.5523 33 13 33H5"
        stroke="#FF0018"
      />
      <rect x="28" y="25" width="8" height="8" rx="1" stroke="#FF0018" />
      <rect x="38" y="25" width="8" height="8" rx="1" stroke="#FF0018" />
      <circle cx="17" cy="42" r="4" stroke="#222222" />
      <circle cx="39" cy="42" r="4" stroke="#222222" />
    </svg>
  )
}

export default MiniBusIcon
