import React from 'react'
import { Box } from '@mui/material'
import palette from '../theme/palette'
import { styled } from '@mui/system'

const TypeTitle = styled('p')(() => {
  return {
    fontSize: '10px',
    fontWeight: 500,
    lineHeight: '15px',
  }
})

interface Props {
  children: React.ReactNode
  title: string
  isActive: boolean
  onClick: () => void
}

const BigIconSelect: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  onClick,
  isActive,
  children,
}) => {
  return (
    <Box
      sx={{
        '&:hover': {
          cursor: 'pointer',
        },
        maxWidth: 80,
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          height: 80,
          width: 80,
          borderRadius: '4px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: isActive
            ? palette?.red?.plus45
            : palette?.black?.plus85,
          border: `1px solid ${
            isActive ? palette?.red?.main : palette?.black?.plus80
          }`,
        }}
      >
        {children}
      </Box>
      <TypeTitle>{title}</TypeTitle>
    </Box>
  )
}

export default BigIconSelect
