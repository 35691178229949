import { InfoIcon, palette, Text, theme } from '@busie/ui-kit'
import { Stack, useMediaQuery } from '@mui/material'
import ItineraryReminderBlockStyled from './styled/ItineraryReminderBlockStyled'

const ItineraryReminderBlock: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'))

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <ItineraryReminderBlockStyled>
        <InfoIcon
          size={isMobile ? '24px' : '16px'}
          color={palette.black.plus10 || '#00000'}
        />
        <Text
          color={palette.black.plus10 || '#00000'}
          weight={600}
          fontSize="12px"
        >
          Please be sure to include <em>all</em> stops in your itinerary so we
          can give you the most accurate price!
        </Text>
      </ItineraryReminderBlockStyled>
    </Stack>
  )
}

export default ItineraryReminderBlock
