import { styled } from '@mui/system'

const DateRangePickerControlledStyled = styled('div')((props) => {
  const palette = props.theme.palette
  return {
    // display: "inline-flex",
    position: 'relative',
    '.close-button': {
      height: '24px',
      cursor: 'pointer',
    },
    '.calendar-toolbox': {
      position: 'absolute',
      background: palette.black.plus100,
      borderRadius: '4px',
      zIndex: '1',
      left: '-100%',
      right: '-100%',
      boxShadow: '0px 8px 24px rgb(34 34 34 / 15%)',
    },
  }
})

export default DateRangePickerControlledStyled
