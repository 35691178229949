import React from 'react'
import IconButtonStyled, {
  Props as ButtonProps,
} from './styled/IconButtonStyled'

interface Props extends ButtonProps {
  icon: React.ReactNode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (event: any) => void
  type?: 'button' | 'reset' | 'submit'
  disabled?: boolean
  highlightOnHover?: boolean
  size?: number
  className?: string
  style?: React.CSSProperties
}

const IconButton: React.FC<React.PropsWithChildren<Props>> = ({
  icon,
  onClick,
  type = 'button',
  disabled,
  highlightOnHover,
  size,
  className,
  style,
  ...props
}: Props) => {
  return (
    <IconButtonStyled
      style={style}
      disabled={disabled}
      type={type}
      size={size}
      onClick={onClick}
      className={className}
      highlightOnHover={highlightOnHover == null ? true : highlightOnHover}
      {...props}
    >
      {icon}
    </IconButtonStyled>
  )
}

export default IconButton
