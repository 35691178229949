import React from 'react'

interface Props {
  color?: string
  size?: number
}

export const ChevronNext: React.FC<React.PropsWithChildren<Props>> = (
  props
) => {
  const size = props.size || 24
  return (
    <svg
      width={size}
      height={size}
      style={{ minWidth: size, minHeight: size }}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 18L15 12L9 6"
        stroke={props.color || '#1460FF'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
