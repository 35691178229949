import { lazy } from 'react'
import { type RouteConfig } from './types'
/**
 * Application route configuration array
 * Defines all routes and their properties including:
 * - Path
 * - Component to render
 * - Protection status
 * - Header navigation configuration
 * - Visibility rules based on feature flags
 *
 * @type {RouteConfig[]}
 */

const baseRouteConfig: RouteConfig = {
  path: '/',
  redirectTo: '/quotes-and-bookings/quotes',
}

// TODO: [BUSIE-2392] Add improve header item validation and/or refactor routeConfig to define headerItem group/groupOrder differently (e.g. as an array of objects)
const routeConfigs: RouteConfig[] = [
  {
    path: '/vehicles',
    component: lazy(() =>
      import('~/vehicles/VehiclesRoutes').then((module) => ({
        default: module.VehiclesRoutes,
      }))
    ),
    protected: true,
  },
  {
    path: '/reservations',
    component: lazy(() =>
      import('~/vehicles/VehiclesRoutes').then((module) => ({
        default: module.VehiclesRoutes,
      }))
    ),
    protected: true,
    headerItem: {
      name: 'Reservations',
      group: 'Dispatch & Operations',
      order: 1,
      groupOrder: 3,
    },
  },
  {
    path: '/rates',
    component: lazy(() => import('~/rates/RateMenuPage/')),
    protected: true,
    strict: true,
    headerItem: {
      name: 'Rate Menu',
      order: 3,
    },
  },
  {
    path: '/quotes-and-bookings/quotes',
    component: lazy(() =>
      import('~/QuotesAndBookingsPage').then((module) => ({
        default: module.QuotesPage,
      }))
    ),
    protected: true,
    exact: true,
    strict: true,
    headerItem: {
      name: 'Quotes & Bookings',
      group: 'Sales',
      order: 1,
      groupOrder: 1,
    },
  },
  {
    path: '/quotes-and-bookings/bookings',
    component: lazy(() =>
      import('~/QuotesAndBookingsPage').then((module) => ({
        default: module.BookingsPage,
      }))
    ),
    protected: true,
    exact: true,
    strict: true,
  },
  {
    path: '/quotes-and-bookings/archived/quotes',
    component: lazy(() =>
      import('~/QuotesAndBookingsPage').then((module) => ({
        default: module.ArchivedQuotesPage,
      }))
    ),
    protected: true,
    exact: true,
    strict: true,
  },
  {
    path: '/quotes-and-bookings/archived/bookings',
    component: lazy(() =>
      import('~/QuotesAndBookingsPage').then((module) => ({
        default: module.ArchivedBookingsPage,
      }))
    ),
    protected: true,
    exact: true,
    strict: true,
  },
  {
    path: '/quotes-and-bookings/add-quote',
    component: lazy(() =>
      import('~/QuotesAndBookingsPage').then((module) => ({
        default: module.AddQuotePage,
      }))
    ),
    protected: true,
    exact: true,
    strict: true,
  },
  {
    path: '/quotes-and-bookings',
    exact: true,
    redirectTo: '/quotes-and-bookings/quotes',
  },
  {
    path: '/analytics',
    component: lazy(() =>
      import('~/AnalyticsPage').then((module) => ({
        default: module.AnalyticsPage,
      }))
    ),
    protected: true,
    strict: true,
    featureFlag: 'rolloutEmbeddedAnalytics',
    headerItem: {
      name: 'Analytics',
      order: 5,
    },
  },
  {
    path: '/contracts',
    component: lazy(() =>
      import('~/contracts').then((module) => ({
        default: module.Page,
      }))
    ),
    protected: true,
    exact: true,
    strict: true,
    featureFlag: 'rolloutContracts',
    headerItem: {
      name: 'Contracts',
      group: 'Sales',
      order: 2,
    },
  },
  {
    path: '/customers',
    component: lazy(() =>
      import('~/CustomersPage').then((module) => ({
        default: module.CustomersPage,
      }))
    ),
    protected: true,
    headerItem: {
      name: 'Customers',
      order: 4,
    },
  },
  {
    path: '/drivers',
    component: lazy(() =>
      import('~/drivers').then((module) => ({
        default: module.DriversPage,
      }))
    ),
    protected: true,
    headerItem: {
      name: 'Drivers',
      group: 'Dispatch & Operations',
      order: 2,
    },
  },
  {
    path: '/deployments',
    featureFlag: 'rolloutDeployments',
    component: lazy(() =>
      import('~/DeploymentsPage').then((module) => ({
        default: module.DeploymentsPage,
      }))
    ),
    protected: true,
    exact: true,
    strict: true,

    headerItem: {
      name: 'Deployments',
      group: 'Dispatch & Operations',
      order: 3,
    },
  },
  {
    path: '/embed',
    component: lazy(() =>
      import('~/EmbeddedFormContainer').then((module) => ({
        default: module.EmbeddedFormContainer,
      }))
    ),
  },
  {
    path: '/organization-settings/dispatch',
    component: lazy(() =>
      import('~/settings').then((module) => ({
        default: module.DispatchLocationsSettings,
      }))
    ),
    protected: true,
    exact: true,
    strict: true,
  },
  {
    path: '/organization-settings/payments',
    component: lazy(() =>
      import('~/settings').then((module) => ({
        default: module.PaymentsSettings,
      }))
    ),
    protected: true,
    exact: true,
    strict: true,
  },
  {
    path: '/organization-settings/integration',
    component: lazy(() =>
      import('~/settings').then((module) => ({
        default: module.QuoteIntegrationSettings,
      }))
    ),
    protected: true,
    exact: true,
    strict: true,
  },
  {
    path: '/organization-settings/qnb',
    component: lazy(() =>
      import('~/settings').then((module) => ({
        default: module.QuotesSettings,
      }))
    ),
    protected: true,
    exact: true,
    strict: true,
  },
  {
    path: '/organization-settings/trip',
    component: lazy(() =>
      import('~/settings').then((module) => ({
        default: module.TripSettings,
      }))
    ),
    protected: true,
    exact: true,
    strict: true,
  },
  {
    path: '/organization-settings/embedded-trip-planner',
    component: lazy(() =>
      import('~/settings').then((module) => ({
        default: module.EmbeddedTripPlannerSettings,
      }))
    ),
    protected: true,
    exact: true,
    strict: true,
  },
  {
    path: '/organization-settings/plan',
    component: lazy(() =>
      import('~/settings').then((module) => ({
        default: module.Plan,
      }))
    ),
    protected: true,
    exact: true,
    strict: true,
  },
  {
    path: '/organization-settings/drivers',
    component: lazy(() =>
      import('~/settings').then((module) => ({
        default: module.DriversSettings,
      }))
    ),
    protected: true,
    exact: true,
    strict: true,
  },
  {
    path: '/profile',
    component: lazy(() =>
      import('~/profile').then((module) => ({
        default: module.Page,
      }))
    ),
    protected: true,
    strict: true,
  },
  {
    path: '/vehicles/my-vehicles',
    headerItem: {
      name: 'My Vehicles',
      group: 'Dispatch & Operations',
      order: 1,
      groupOrder: 2,
    },
  },
]

/**
 * Final route configuration array used by the application.
 * Routes are ordered with baseRouteConfig always appearing last,
 * ensuring it acts as a fallback route.
 * All other routes maintain their relative order defined in routeConfigs.
 *
 * @type {RouteConfig[]}
 */
export const routes: RouteConfig[] = [
  baseRouteConfig,
  ...routeConfigs,
].reverse()

/**
 * The component prop uses lazy() for code splitting and dynamic imports.
 *
 * Pattern:
 * component: lazy(() =>
 *   import("path/to/module").then((module) => ({
 *     default: module.ComponentName
 *   }))
 * )
 *
 * This structure is required because:
 * 1. lazy() needs a Promise that resolves to a module with a default export
 * 2. Many of our components are named exports, not default exports
 * 3. The .then() transforms the named export into a default export
 * 4. Webpack needs static string literals for dynamic imports to work correctly
 *
 * Example:
 * If ModuleA.tsx exports { ComponentA }, we need:
 * import("ModuleA").then(m => ({ default: m.ComponentA }))
 *
 * Attempts to abstract this pattern into helpers or use dynamic paths can break
 * webpack's ability to analyze the dependency graph for code splitting.
 */
