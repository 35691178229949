import React, { FC } from 'react'
import { styled } from '@mui/system'
import palette from '../theme/palette'
interface Props {
  children?: React.ReactNode
  color?: string
  className?: string
  margin?: string
  align?: 'left' | 'center' | 'right'
  fontWeight?: string
  fontSize?: string
  lineHeight?: string
}

const SubtitleStyled = styled('p')<Props>(({
  color = palette.black.main,
  margin = '0',
  align = 'left',
  fontWeight = '600',
  fontSize = '16px',
  lineHeight = '150%',
}) => {
  return {
    '&': {
      color: color,
      fontWeight: fontWeight,
      fontSize: fontSize,
      lineHeight: lineHeight,
      margin: margin,
      textAlign: align,
    },
  }
})

export const Subtitle: FC<React.PropsWithChildren<Props>> = (props) => {
  return <SubtitleStyled {...props}>{props.children}</SubtitleStyled>
}
