import { TextField } from '@mui/material'
import { styled } from '@mui/system'

export const TextFieldStyled = styled(TextField)(() => ({
  minWidth: '250px',
  input: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
}))
