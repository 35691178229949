import { centsToDollars, TripLeg } from '@busie/utils'
import { useEffect, useState } from 'react'

export const useTripLegSubtotal = (leg: TripLeg): number => {
  const [subtotal, setSubtotal] = useState(0)

  useEffect(() => {
    if (!leg) return setSubtotal(0)

    const subtotal = leg._legPrice.reduce((acc, { subtotal }) => {
      acc += centsToDollars(subtotal)
      return acc
    }, 0)

    setSubtotal(subtotal)
  }, [leg])

  return subtotal
}
