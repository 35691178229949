import React from 'react'
import cx from 'classnames'

import { CalculateIcon, FlexContainer, IconButton } from '@busie/ui-kit'
import { currencyFormatter, TripLeg } from '@busie/utils'

import { LegPriceBreakdown } from './LegPriceBreakdown'

interface Props {
  live: boolean
  subtotal: number
  leg: TripLeg
  dispatchLocationName: string
}

export const LegBlockSubtotal: React.FC<React.PropsWithChildren<Props>> = ({
  live,
  subtotal,
  leg,
  dispatchLocationName,
}) => {
  const [isPopupOpen, setIsPopupOpen] = React.useState(false)

  return (
    <div>
      <span
        className={cx({
          live,
          price: true,
        })}
      >
        {currencyFormatter(subtotal)}
      </span>
      <FlexContainer align="center" columnGap={2}>
        <IconButton
          icon={<CalculateIcon color="black" />}
          onClick={() => setIsPopupOpen(true)}
        />
        <LegPriceBreakdown
          dispatchLocationName={dispatchLocationName}
          isOpen={isPopupOpen}
          tripLeg={leg}
          isLiveLeg={live}
          onClose={() => setIsPopupOpen(false)}
        />
      </FlexContainer>
    </div>
  )
}
