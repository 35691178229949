import { styled } from '@mui/system'
import NumberFormat from 'react-number-format'
import palette from '../../theme/palette'

// import { Theme } from "@mui/system";
// interface Props {
//   theme?: Theme;
// }

const NumberInputStyled = styled(NumberFormat)(({
  disabled,
}: {
  disabled?: boolean
}) => {
  // const palette = props.theme?.palette;
  return {
    backgroundColor: disabled ? palette.black.plus85 : palette.black.plus100,
  }
})

export default NumberInputStyled
