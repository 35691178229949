import { PaletteOptions } from '@mui/material'

const palette = {
  primary: {
    main: '#222222',
  },
  black: {
    main: '#222222',
    plus10: '#3B3B3B',
    plus20: '#545454',
    plus30: '#6E6E6E',
    plus40: '#878787',
    plus50: '#A1A1A1',
    plus60: '#BABABA',
    plus70: '#D4D4D4',
    plus80: '#EDEDED',
    plus85: '#FAFAFA',
    plus90: '#F1F1F1',
    plus100: '#FFFFFF',
  },
  red: {
    minus5: '#CC0013',
    main: '#FF0018',
    plus5: '#FF3346',
    plus40: '#FFCCD1',
    plus45: '#FFE5E8',
    plus80: '#FFF0F1',
  },
  supernova: {
    minus10: '#E5B800',
    main: '#FFCC00',
    plus10: '#FFD633',
    plus40: '#FFF5CC',
  },
  green: {
    minus5: '#00AD11',
    main: '#00C814',
    plus5: '#00E016',
    plus55: '#E0FFE3',
    lineRun: '#AFD6A1',
  },
  blue: {
    minus10: '#008DCC',
    main: '#00B0FF',
    plus10: '#33C0FF',
    plus40: '#CCEFFF',
  },
  purple: {
    minus10: '#AF00CC',
    main: '#DA00FF',
    plus10: '#E233FF',
    plus40: '#F8CCFF',
  },
}

export default {
  ...palette,
  success: { main: palette.green.main },
  warning: { main: palette.supernova.main },
  error: { main: palette.red.main },
}

export const createCustomPalette = (customPalette: Partial<PaletteOptions>) => {
  return {
    ...palette,
    ...customPalette,
  }
}
