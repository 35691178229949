import React from 'react'

import {
  AmenityIconProps,
  ArmrestsIcon,
  CustomIcon,
  DvdPlayerIcon,
  FootrestsIcon,
  HeadrestsIcon,
  KneelingCoachIcon,
  OutletsIcon,
  RecliningSeatsIcon,
  RestroomIcon,
  SeatbeltsIcon,
  UsbPortsIcon,
  WifiIcon,
} from '@busie/ui-kit'
import { Amenity } from '@busie/utils'

interface Props extends AmenityIconProps {
  type: Amenity
}

export const AmenityIcon: React.FC<React.PropsWithChildren<Props>> = ({
  type,
  size = 56,
  color,
}) => {
  switch (type) {
    case Amenity.ARMREST:
      return <ArmrestsIcon size={size} color={color} />
    case Amenity.CUSTOM:
      return <CustomIcon size={size} color={color} />
    case Amenity.DVD_PLAYER:
      return <DvdPlayerIcon size={size} color={color} />
    case Amenity.FOOTRESTS:
      return <FootrestsIcon size={size} color={color} />
    case Amenity.HEADRESTS:
      return <HeadrestsIcon size={size} color={color} />
    case Amenity.KNEELING_COACH:
      return <KneelingCoachIcon size={size} color={color} />
    case Amenity.OUTLETS:
      return <OutletsIcon size={size} color={color} />
    case Amenity.RECLINING_SEATS:
      return <RecliningSeatsIcon size={size} color={color} />
    case Amenity.RESTROOM:
      return <RestroomIcon size={size} color={color} />
    case Amenity.SEATBELTS:
      return <SeatbeltsIcon size={size} color={color} />
    case Amenity.USB_PORTS:
      return <UsbPortsIcon size={size} color={color} />
    case Amenity.WIFI:
      return <WifiIcon size={size} color={color} />
    default:
      return <div>No icon</div>
  }
}
