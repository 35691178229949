import React from 'react'

import { IconButton } from '@mui/material'
// import CloseIcon from "@mui/icons-material/Close";

import PopupTitleStyled from './styled/PopupTitleStyled'
import { ArrowLeft, CloseIcon } from '../icons'
import palette from '../theme/palette'

interface Props {
  children?: string | React.ReactNode
  closeIconColor?: string
  backIconColor?: string
  subtitle?: string
  onClose?: () => void
  onBack?: () => void
}

const PopupHeader: React.FC<React.PropsWithChildren<Props>> = (props) => {
  return (
    <>
      {(props.onClose || props.onBack) && (
        <PopupTitleStyled sx={{ m: 0 }}>
          {props.onBack && (
            <IconButton
              aria-label="back"
              onClick={props.onBack}
              sx={{
                position: 'absolute',
                left: 8,
                top: 8,
              }}
            >
              <ArrowLeft
                color={props.backIconColor || palette?.black?.plus50 || 'black'}
              />
            </IconButton>
          )}
          {props.onClose && (
            <IconButton
              aria-label="close"
              onClick={props.onClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon
                color={
                  props.closeIconColor || palette?.black?.plus50 || 'black'
                }
              />
            </IconButton>
          )}
        </PopupTitleStyled>
      )}
      <PopupTitleStyled sx={{ m: 0 }}>
        {props.children && <div className="title">{props.children}</div>}
        {props.subtitle && <div className="subtitle">{props.subtitle}</div>}
      </PopupTitleStyled>
    </>
  )
}

export default PopupHeader
