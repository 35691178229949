import React from 'react'
import { Box, styled } from '@mui/material'

import { Modal as BaseModal } from '@mui/base'

import { IconButton } from '@mui/material'
import { CloseIcon } from '../icons'
import palette from '../theme/palette'

interface Props {
  children: React.ReactNode
  isOpen: boolean
  onClose?: () => void
}

const ModalStyled = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
`

const Backdrop = styled('div')`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`

const Modal: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  children,
  onClose,
}) => (
  <ModalStyled open={isOpen} onClose={onClose} slots={{ root: Backdrop }}>
    <Box
      sx={{
        outline: 'none',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        '@media (max-width: 960px)': {
          position: 'static',
          top: '0',
          left: '0',
          transform: 'none',
          width: '100%',
          height: '100%',
        },
      }}
    >
      {onClose && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8, marginTop: '10px' }}
        >
          <CloseIcon color={palette?.black?.plus50 || 'black'} />
        </IconButton>
      )}
      {children}
    </Box>
  </ModalStyled>
)
export default Modal
