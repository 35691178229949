import { styled } from '@mui/system'
import palette from '../theme/palette'

const TextAreaStyled = styled('textarea')(() => ({
  border: `1px solid ${palette.black.plus70}`,
  backgroundColor: palette.black.plus100,
  borderRadius: '4px',
  padding: '12px 8px 8px 16px',
  maxWidth: '100%',
}))

export default TextAreaStyled
