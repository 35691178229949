import { InfiniteQueryObserverResult, useQuery } from 'react-query'

import { getPlan } from '@busie/api'
import { queryClient } from './client'
import { useAuthTokenWithAudience } from '../lib/hooks'

const PLANS_QUERY_KEY = 'PLANS'
export const DEFAULT_STALE_TIME = 2 * 60 * 1000 // 30 minutes

export const invalidate = () => {
  queryClient.invalidateQueries(PLANS_QUERY_KEY)
}

export const usePlan = (
  organizationId?: string,
  notifyOnChangeProps?: (keyof InfiniteQueryObserverResult)[] | 'tracked'
) => {
  const plansAuthToken = useAuthTokenWithAudience('plans')

  const result = useQuery(
    [PLANS_QUERY_KEY],
    () => getPlan(plansAuthToken, organizationId as string),
    {
      enabled: !!plansAuthToken && !!organizationId,
      staleTime: DEFAULT_STALE_TIME,
      notifyOnChangeProps,
    }
  )

  return result
}
