import axios from 'axios'
import {
  CreateTripFormData,
  CreateTripLeg,
  OrganizationTripSettings,
} from '../../dataTypes'
import { env, Trip, Location } from '@busie/utils'
import { handleTripCreatedData } from '../../helpers/handle-trip-created-data'

const TRIPS_SERVICE_URL = `${env('BUSIE_TRIPS_SERVICE_API_URL')}/trip-service`

type FetchTripsParams = {
  populateLegs?: boolean
  status?:
    | 'PENDING'
    | 'CONFIRMED'
    | 'UPCOMING'
    | 'IN_PROGRESS'
    | 'COMPLETE'
    | 'CANCELED'
  dispatchId?: string
  tripPlannerId?: string
  quoteId?: string
  reservationId?: string
  integrationId?: string
}

export interface UpdateTripData {
  passengers: number
  wheelchairs: number
  additionalInformation: string
  dispatchId: string
  legs: CreateTripLegData[]
  mainContactId: string
  spab: boolean
}

export interface CreateLocationData {
  latitude: number
  longitude: number
  address?: string
  street?: string
  city?: string
  state?: string
  country?: string
  postalCode?: string
}

export interface CreateTripLegData {
  startLocation: CreateLocationData
  departureDatetime: string
  destinationLocation: CreateLocationData
  arrivalDatetime?: string
  meters: number
  hours: number
}

export interface CreateTripData {
  status?: string
  quoteId?: string
  mainContactId?: string
  organizationId: string
  passengers: number
  additionalInformation: string
  amenities: number[]
  wheelchairs: number
  tripPlannerId: string
  tripPlannerGroupId: string
  meters: number
  hours: number
  legs: CreateTripLegData[]
  spab: boolean
}

export interface UpdateTripSettingsParams {
  maxDailyDriveTime?: number
  maxPickupRadius?: number
  prorateHours?: boolean
  prorateMiles?: boolean
}

export const fetchTrips = async (
  authToken: string,
  params?: FetchTripsParams
): Promise<Trip[]> => {
  if (!authToken) return [] as Trip[]
  const { data } = await axios.get(`${TRIPS_SERVICE_URL}/trips`, {
    headers: { Authorization: `Bearer ${authToken}` },
    params,
  })
  return data
}

export const fetchTrip = async (
  authToken: string,
  id: string,
  params?: Pick<FetchTripsParams, 'populateLegs'>
): Promise<Trip> => {
  if (!authToken) return {} as Trip
  const { data } = await axios.get(`${TRIPS_SERVICE_URL}/trips/${id}`, {
    headers: { Authorization: `Bearer ${authToken}` },
    params,
  })
  return data
}

/**
 * @deprecated
 * future requests to create trips should leverage the createTrip function below.
 */
export const createTripAsOperator = async (
  authToken: string,
  formData: CreateTripFormData
): Promise<Trip> => {
  if (!authToken) return {} as Trip
  const data = await axios
    .post(`${TRIPS_SERVICE_URL}/trips/operator`, formData, {
      headers: { Authorization: `Bearer ${authToken}` },
    })
    .then((response) => {
      const trip: Trip = response.data
      return handleTripCreatedData(trip)
    })

  return data
}

export const createTrip = async (
  authToken: string,
  params: CreateTripData,
  orgType: 'operator' | 'network' = 'operator'
): Promise<Trip> => {
  const { data } = await axios.post<Trip>(
    `${TRIPS_SERVICE_URL}/trips/${orgType}`,
    params,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  )

  return handleTripCreatedData(data)
}

export const patchLegDestination = async (
  authToken: string,
  formData: { legId: string; newLocation: Location }
): Promise<void> => {
  const { data } = await axios.patch(
    `${TRIPS_SERVICE_URL}/trips/leg/${formData.legId}/destination`,
    formData.newLocation,
    { headers: { Authorization: `Bearer ${authToken}` } }
  )

  return data
}

/**
 * @deprecated
 * @param authToken
 * @param legId
 * @param formData
 * @returns
 */
export const patchTrip = async (
  authToken: string,
  legId: string,
  formData: {
    passengers: number
    wheelchairs: number
    additionalInformation: string
    dispatchId: string
    legs: CreateTripLeg[]
    mainContactId: string
  }
): Promise<void> => {
  if (!legId) return

  const { data } = await axios.patch(
    `${TRIPS_SERVICE_URL}/trips/${legId}`,
    formData,
    { headers: { Authorization: `Bearer ${authToken}` } }
  )

  return data
}

export const updateTrip = async (
  authToken: string,
  id: string,
  payload: UpdateTripData
): Promise<Trip> => {
  const { data } = await axios.patch<Trip>(
    `${TRIPS_SERVICE_URL}/trips/${id}`,
    payload,
    { headers: { Authorization: `Bearer ${authToken}` } }
  )

  return data
}

export const patchTripDispatchLocation = async (
  authToken: string,
  tripId: string,
  dispatchId: string
): Promise<void> => {
  const { data } = await axios.patch(
    `${TRIPS_SERVICE_URL}/trips/${tripId}/dispatch-location`,
    { dispatchId },
    { headers: { Authorization: `Bearer ${authToken}` } }
  )

  return data
}

export const patchTripAdditionalInfo = async (
  authToken: string,
  tripId: string,
  additionalInformation: string
): Promise<void> => {
  const { data } = await axios.patch(
    `${TRIPS_SERVICE_URL}/trips/${tripId}/additional-information`,
    {
      additionalInformation,
    },
    { headers: { Authorization: `Bearer ${authToken}` } }
  )

  return data
}

export const getTripsSettings = async (
  authToken: string,
  organizationId: string
): Promise<OrganizationTripSettings> => {
  if (!authToken || !organizationId) return {} as OrganizationTripSettings
  const { data } = await axios.get<OrganizationTripSettings>(
    `${TRIPS_SERVICE_URL}/settings/${organizationId}`,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  )

  return data
}

export const updateTripSettings = async (
  authToken: string,
  settingsId: string,
  params: UpdateTripSettingsParams
): Promise<OrganizationTripSettings> => {
  if (!authToken || !settingsId) return {} as OrganizationTripSettings
  const { data } = await axios.patch<OrganizationTripSettings>(
    `${TRIPS_SERVICE_URL}/settings/${settingsId}`,
    params,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  )

  return data
}

export const updateMaxDriveTime = async (
  authToken: string,
  settingsId: string,
  params: {
    maxDailyDriveTime?: number
  }
): Promise<OrganizationTripSettings> => {
  if (!authToken || !settingsId) return {} as OrganizationTripSettings
  const { data } = await axios.patch(
    `${TRIPS_SERVICE_URL}/settings/${settingsId}`,
    params,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  )
  return data
}
