import React from 'react'
import { useTripLegSubtotal } from '@busie/core'
import { metersToMiles, TripLeg } from '@busie/utils'

import { LegBlockSubtotal } from './LegBlockSubtotal'

interface Props {
  legNumber: number
  leg: TripLeg
  distanceUnit: 'mi' | 'm'
  isDeadLeg: boolean
  dispatchLocationName: string
  showPriceData?: boolean
}

export const LegBlockHeader: React.FC<React.PropsWithChildren<Props>> = ({
  leg,
  legNumber,
  distanceUnit,
  isDeadLeg,
  dispatchLocationName,
  showPriceData = true,
}) => {
  const subtotal = useTripLegSubtotal(leg)
  const { _meters: meters } = leg
  const distance = distanceUnit === 'mi' ? metersToMiles(meters) : meters

  const legBlockSubtotalProps = {
    subtotal,
    live: !isDeadLeg,
    leg,
    dispatchLocationName,
  }

  return (
    <div className="block-header">
      <div>
        <span className="index">{`Leg ${legNumber}`}</span>
        <span className="distance">{`${distance} ${distanceUnit}`}</span>
      </div>
      {(showPriceData && <LegBlockSubtotal {...legBlockSubtotalProps} />) ||
        null}
    </div>
  )
}
