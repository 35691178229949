import React from 'react'

import { NoteText, FlexContainer, palette } from '@busie/ui-kit'
import logo from './images/powered_by_logo.svg'

const ProviderNameLabel: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <FlexContainer
      fullWidth
      textAlign="center"
      justify="center"
      align="center"
      columnGap={1}
      py={2}
    >
      <NoteText color={palette.black.plus40}>Powered by:</NoteText>
      <img src={logo} width="46" height="9" loading="lazy" alt="Busie" />
    </FlexContainer>
  )
}

export default ProviderNameLabel
