import React from 'react'
import { VehicleIconProps as Props } from '.'

export const EntertainerCoachIcon: React.FC<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="5" y="37" width="4" height="1" rx="0.5" fill="#222222" />
      <rect x="18" y="25" width="8" height="8" rx="1" stroke="#FF0018" />
      <path
        d="M13.25 43H7C5.89543 43 5 42.1046 5 41V31.8379C5 31.6142 5.03752 31.3921 5.11101 31.1809L8.53288 21.343C8.81256 20.5389 9.57057 20 10.4219 20H48C49.1046 20 50 20.8954 50 22V41C50 42.1046 49.1046 43 48 43H42.75M20.875 43H27.5H35.125"
        stroke="#FF0018"
      />
      <path
        d="M7 25H13C13.5523 25 14 25.4477 14 26V32C14 32.5523 13.5523 33 13 33H5"
        stroke="#FF0018"
      />
      <rect x="28" y="25" width="8" height="8" rx="1" stroke="#FF0018" />
      <rect x="38" y="25" width="8" height="8" rx="1" stroke="#FF0018" />
      <path
        d="M19.0247 9.24917C19.0078 8.7651 19.6202 8.54221 19.9184 8.92389L20.4837 9.64752C20.8838 10.1596 21.5681 10.3558 22.1788 10.1336L23.0417 9.81949C23.4969 9.65383 23.8981 10.1674 23.6272 10.5689L23.1137 11.3302C22.7503 11.869 22.7752 12.5804 23.1753 13.0925L23.7406 13.8161C24.0388 14.1978 23.6744 14.7381 23.2088 14.6046L22.3261 14.3515C21.7014 14.1724 21.0325 14.4159 20.6691 14.9546L20.1556 15.7159C19.8847 16.1175 19.2582 15.9378 19.2413 15.4538L19.2093 14.536C19.1866 13.8866 18.7483 13.3256 18.1237 13.1465L17.2409 12.8934C16.7753 12.7598 16.7526 12.1085 17.2077 11.9429L18.0707 11.6288C18.6813 11.4065 19.0794 10.8164 19.0567 10.1669L19.0247 9.24917Z"
        stroke="#222222"
      />
      <path
        d="M43.825 12.5529L44.3247 12.5704L43.825 12.5529C43.8081 13.037 43.1816 13.2166 42.9108 12.8151L42.8035 12.6561C42.4402 12.1173 41.7712 11.8739 41.1465 12.053L40.9622 12.1058C40.4966 12.2393 40.1322 11.6991 40.4304 11.3174L40.5484 11.1663C40.9485 10.6542 40.9734 9.94273 40.61 9.40399L40.1955 9.68359L40.61 9.40399L40.5027 9.24501C40.2319 8.84346 40.6331 8.32991 41.0883 8.49557L41.2685 8.56116C41.8791 8.78342 42.5634 8.58719 42.9635 8.07511L43.0816 7.924C43.3798 7.54232 43.9922 7.76522 43.9753 8.24928L43.9686 8.44092L44.4683 8.45838L43.9686 8.44093C43.9459 9.09037 44.344 9.68056 44.9546 9.90281L45.1348 9.9684C45.59 10.1341 45.5672 10.7854 45.1016 10.9189L44.9173 10.9717C44.2926 11.1509 43.8544 11.7118 43.8317 12.3613L43.825 12.5529Z"
        stroke="#222222"
      />
      <path
        d="M29.6199 18.7742C29.6813 18.3377 30.2653 18.2347 30.4722 18.6239C30.7682 19.1804 31.3888 19.4831 32.0095 19.3737C32.4436 19.2971 32.722 19.8207 32.4158 20.1378C31.978 20.5912 31.8819 21.275 32.1778 21.8315C32.3847 22.2207 31.9727 22.6473 31.5766 22.4541C31.01 22.1778 30.33 22.2977 29.8922 22.7511C29.586 23.0681 29.053 22.8082 29.1143 22.3717C29.202 21.7475 28.8779 21.1379 28.3113 20.8615C27.9152 20.6683 27.9977 20.0811 28.4318 20.0045C29.0525 19.895 29.5322 19.3983 29.6199 18.7742Z"
        stroke="#222222"
      />
      <circle cx="35.5" cy="15.5" r="2" stroke="#FF0018" />
      <circle cx="12" cy="16" r="1.5" stroke="#FF0018" />
      <circle cx="30.5" cy="8.5" r="1" stroke="#222222" />
      <path d="M45 18L48 15" stroke="#FF0018" strokeLinecap="round" />
      <path d="M29 15.5L28 13.5" stroke="#FF0018" strokeLinecap="round" />
      <path
        d="M22.5257 21.9447L20.4741 21.0554"
        stroke="#FF0018"
        strokeLinecap="round"
      />
      <path d="M13.5 10.5L10 8" stroke="#FF0018" strokeLinecap="round" />
      <circle cx="17" cy="42" r="4" stroke="#222222" />
      <circle cx="39" cy="42" r="4" stroke="#222222" />
    </svg>
  )
}

export default EntertainerCoachIcon
