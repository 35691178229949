import { User as Auth0User } from '@auth0/auth0-spa-js'

type Roles =
  | 'Operator Admin'
  | 'Network Admin'
  | 'Charter Admin'
  | 'Charter User'
  | 'Dispatch Admin'
  | 'Dispatch User'
  | 'Pathfinder User'
  | 'Busie User'

interface Organization {
  branding: {
    colors: {
      page_background: string
      primary: string
    }
    logo_url: string
  }
  name: string
  id: string
  display_name: string
  metadata: {
    org_type: 'OPERATOR' | 'NETWORK'
    size: string
    [key: string]: string
  }
}

const ORGANIZATION_SETTINGS_ALLOWED: Roles[] = [
  'Operator Admin',
  'Network Admin',
]

const PATCH_DISPATCH_LOCATION_ALLOWED: Roles[] = [
  'Operator Admin',
  'Network Admin',
]

export interface User extends Auth0User {
  'https://getbusie.com/roles': Roles[]
  'https://getbusie.com/user_metadata': {
    enableQCN: boolean
  }
  'https://getbusie.com/organization': Organization
  org_id: string
}

export const checkUserRoles = (user: User, roles: Roles[]): boolean =>
  user['https://getbusie.com/roles'].filter((userRole) =>
    roles.includes(userRole)
  ).length > 0

export const isOrganizationSettingsAllowed = (user: User): boolean =>
  checkUserRoles(user, ORGANIZATION_SETTINGS_ALLOWED)

export const isDispatchLocationPatchAllowed = (user: User): boolean =>
  checkUserRoles(user, PATCH_DISPATCH_LOCATION_ALLOWED)

export const isQCNEnabled = (user: User): boolean =>
  !!user['https://getbusie.com/user_metadata'].enableQCN

export const getBrandingLogo = (user?: User): string | undefined => {
  if (!user) return undefined

  try {
    return user['https://getbusie.com/organization'].branding.logo_url
  } catch (e) {
    return undefined
  }
}

export const getRoles = (user: User): Roles[] =>
  user['https://getbusie.com/roles'] || []

export const getLogicalRole = (user: User): Roles => {
  const rolePriority = new Map([
    ['Operator Admin', 1],
    ['Network Admin', 1],
    ['Charter Admin', 2],
    ['Charter User', 3],
    ['Dispatch Admin', 2],
    ['Dispatch User', 3],
    ['Pathfinder User', 4],
    ['Busie User', 5],
  ])

  return getRoles(user).reduce((acc, role) => {
    const currentRolePriority = rolePriority.get(role) || Infinity
    const accRolePriority = rolePriority.get(acc) || Infinity

    if (currentRolePriority < accRolePriority) {
      return role
    }

    return acc
  }, 'Busie User')
}
