import React from 'react'

import Checkbox from '../Checkbox'
import CheckboxBlock from './styled/CheckboxBlock'

import TableCell from './TableCell'

interface Props {
  selectedRows: string[]
  checkboxKeys: string[]
  index: number
  addRow: (key: string) => void
  removeRow: (key: string) => void
  hidden?: boolean
}

const TableCellCheckbox: React.FC<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  const isChecked =
    props.selectedRows?.includes(
      props.checkboxKeys && props.checkboxKeys[props.index]
    ) || false

  const rowKey = props.checkboxKeys[props.index]
  return (
    <TableCell padding="checkbox">
      <CheckboxBlock hide={props.hidden}>
        <Checkbox
          checked={isChecked}
          onChange={() => {
            isChecked ? props.removeRow(rowKey) : props.addRow(rowKey)
          }}
        />
      </CheckboxBlock>
    </TableCell>
  )
}

export default TableCellCheckbox
