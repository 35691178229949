import React from 'react'

export const NotificationFailureIcon: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24 6V6C33.942 6 42 14.058 42 24V24C42 33.942 33.942 42 24 42V42C14.058 42 6 33.942 6 24V24C6 14.058 14.058 6 24 6Z"
        stroke="#FF0018"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24 25V15"
        stroke="#FF0018"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.998 32C23.722 32 23.498 32.224 23.5 32.5C23.5 32.776 23.724 33 24 33C24.276 33 24.5 32.776 24.5 32.5C24.5 32.224 24.276 32 23.998 32"
        stroke="#FF0018"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
