import React from 'react'

import { TableCellProps } from '@mui/material/TableCell'

import TableCellStyled from './styled/TableCellStyled'

const TableCell: React.FC<React.PropsWithChildren<TableCellProps>> = (
  props: TableCellProps
) => {
  return <TableCellStyled {...props} />
}

export default TableCell
