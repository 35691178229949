interface FeatureFlags {
  /** Flag to control contracts feature rollout */
  rolloutContracts: boolean
  /** Flag to control embedded analytics feature rollout */
  rolloutEmbeddedAnalytics: boolean
  /** Flag to control deployments feature rollout */
  rolloutDeployments: boolean
}

export const DEFAULT_FEATURE_FLAGS: FeatureFlags = {
  rolloutContracts: false,
  rolloutEmbeddedAnalytics: false,
  rolloutDeployments: process.env.NODE_ENV === 'development',
} as const

export type AppFeatures = keyof typeof DEFAULT_FEATURE_FLAGS
export type AppFeaturesType = Record<AppFeatures, boolean>
