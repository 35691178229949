import React from 'react'

interface Props {
  size?: number | string
}

export const SchoolIcon: React.FC<Props> = ({ size = 24 }) => (
  <svg
    width={size}
    height={size}
    style={{ minHeight: size, minWidth: size }}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_18502_33855)">
      <path d="M13 1.5H12.5V2V3H13V1.5Z" fill="#3B3B3B" />
      <path
        d="M16.9392 5.00987L15.9863 6.91559L12.0844 3.0137L13.0224 2.54472L13.9901 2.06084L16.9392 5.00987Z"
        fill="#BABABA"
        stroke="#3B3B3B"
        stroke-width="0.1"
      />
      <path
        d="M16.2414 12.9L17.0414 12.1H21.7586L20.9586 12.9H16.2414Z"
        fill="#A1A1A1"
        stroke="#3B3B3B"
        stroke-width="0.2"
      />
      <path
        d="M16.9 5.42361V11.9586L16.1 12.7586V7.02361L16.9 5.42361Z"
        fill="#A1A1A1"
        stroke="#3B3B3B"
        stroke-width="0.2"
      />
      <path
        d="M21.1 13.0414L21.9 12.2414V20.9H21.1V13.0414Z"
        fill="#A1A1A1"
        stroke="#3B3B3B"
        stroke-width="0.2"
      />
      <path
        d="M3.125 15.0889V13.125H7.875V15.0889V20.875H3.125V15.0889Z"
        stroke="#3B3B3B"
        stroke-width="0.25"
      />
      <path d="M4 14H5V14.2611V15H4V14.2611V14Z" fill="#A1A1A1" />
      <path d="M6 14H7V14.2611V15H6V14.2611V14Z" fill="#A1A1A1" />
      <path d="M4 16H5V16.2611V17H4V16.2611V16Z" fill="#A1A1A1" />
      <path d="M6 16H7V16.2611V17H6V16.2611V16Z" fill="#A1A1A1" />
      <path d="M4 18H5V18.2611V19H4V18.2611V18Z" fill="#A1A1A1" />
      <path d="M6 18H7V18.2611V19H6V18.2611V18Z" fill="#A1A1A1" />
      <path
        d="M8.125 10.6556V7.125H15.875V10.6556V20.875H8.125V10.6556Z"
        stroke="#3B3B3B"
        stroke-width="0.25"
      />
      <path
        d="M16.125 15.0889V13.125H20.875V15.0889V20.875H16.125V15.0889Z"
        stroke="#3B3B3B"
        stroke-width="0.25"
      />
      <path d="M17 14H18V14.2611V15H17V14.2611V14Z" fill="#A1A1A1" />
      <path d="M19 14H20V14.2611V15H19V14.2611V14Z" fill="#A1A1A1" />
      <path d="M9 9H10V9.26111V10H9V9.26111V9Z" fill="#A1A1A1" />
      <path d="M13 9H14V9.26111V10H13V9.26111V9Z" fill="#A1A1A1" />
      <path d="M11 9H12V9.26111V10H11V9.26111V9Z" fill="#A1A1A1" />
      <path d="M17 16H18V16.2611V17H17V16.2611V16Z" fill="#A1A1A1" />
      <path d="M19 16H20V16.2611V17H19V16.2611V16Z" fill="#A1A1A1" />
      <path d="M17 18H18V18.2611V19H17V18.2611V18Z" fill="#A1A1A1" />
      <path d="M19 18H20V18.2611V19H19V18.2611V18Z" fill="#A1A1A1" />
      <path d="M8 13V12L3 13H8Z" fill="#222222" />
      <path d="M9 11H10V11.2611V12H9V11.2611V11Z" fill="#A1A1A1" />
      <path d="M11 11H12V11.2611V12H11V11.2611V11Z" fill="#A1A1A1" />
      <path d="M9 13H10V13.2611V14H9V13.2611V13Z" fill="#A1A1A1" />
      <path d="M11 13H12V13.2611V14H11V13.2611V13Z" fill="#A1A1A1" />
      <path d="M13 11H14V11.2611V12H13V11.2611V11Z" fill="#A1A1A1" />
      <path d="M13 13H14V13.2611V14H13V13.2611V13Z" fill="#A1A1A1" />
      <path d="M9 15H10V15.2611V16H9V15.2611V15Z" fill="#A1A1A1" />
      <path d="M11 15H12V15.2611V16H11V15.2611V15Z" fill="#A1A1A1" />
      <path d="M13 15H14V15.2611V16H13V15.2611V15Z" fill="#A1A1A1" />
      <path d="M14 20H12V17H14V20Z" fill="#545454" />
      <path d="M12 20H10V17H12V20Z" fill="#545454" />
      <path d="M12 17V20" stroke="white" stroke-width="0.2" />
      <path
        d="M8.12071 6.95L12 3.07071L15.8793 6.95H8.12071Z"
        fill="#BABABA"
        stroke="#3B3B3B"
        stroke-width="0.1"
      />
      <path d="M15.5 0H12.5V1.5H15.5L15 0.75L15.5 0Z" fill="#6E6E6E" />
    </g>
    <defs>
      <clipPath id="clip0_18502_33855">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
