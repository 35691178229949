import type { FC } from 'react'

export const EmptyState: FC = () => {
  return (
    <svg
      width="191"
      height="190"
      viewBox="0 0 191 190"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.8302 70.4547C48.6963 78.8693 95.8926 45.211 95.8926 45.211C110.926 29.1321 127.755 16.8939 142.948 9.41458C159.553 1.23998 174.204 -1.24973 182.419 3.14429C195.084 9.91809 191.123 30.1695 182.419 49.6498C182.234 50.063 182.048 50.4759 181.859 50.8883C175.034 65.7974 165.568 80.0753 158.821 87.2957C143.089 112.533 111.625 137.777 119.491 154.606C106.905 168.069 88.0265 182.649 80.1605 188.259V154.6L88.0265 146.186V129.357C88.0265 129.357 77.7901 132.294 72.2944 129.357L80.1605 137.777L72.2944 146.192L68.3614 141.984L64.4284 137.777L56.5623 129.362L52.6293 125.155L48.6963 120.948L56.5623 112.533L64.4284 120.942C61.6808 115.064 64.4284 104.113 64.4284 104.113H48.6963L40.8302 120.942H1.5C9.36605 109.723 28.2446 83.918 40.8302 70.4547Z"
        fill="#FF0018"
      />
      <path
        d="M95.8926 45.211C95.8926 45.211 48.6963 78.8693 40.8302 70.4547C28.2446 83.918 9.36605 109.723 1.5 120.942H40.8302L48.6963 104.113C48.6963 104.113 58.2846 104.113 64.4284 104.113M95.8926 45.211C95.8926 45.211 64.4284 78.8749 64.4284 104.113M95.8926 45.211C110.926 29.1321 127.755 16.8939 142.948 9.41458C159.553 1.23998 174.204 -1.24973 182.419 3.14429C195.084 9.91809 191.123 30.1695 182.419 49.6498C182.234 50.063 182.048 50.4759 181.859 50.8883M64.4284 104.113C64.4284 104.113 61.6808 115.064 64.4284 120.942M158.821 87.2957C143.089 112.533 111.625 137.777 119.491 154.606C106.905 168.069 88.0265 182.649 80.1605 188.259V154.6L88.0265 146.186C88.0265 146.186 88.0265 135.929 88.0265 129.357M158.821 87.2957C139.47 108.004 119.491 129.362 88.0265 129.357M158.821 87.2957C165.568 80.0753 175.034 65.7974 181.859 50.8883M88.0265 129.357C88.0265 129.357 77.7901 132.294 72.2944 129.357M64.4284 120.942L56.5623 112.533L48.6963 120.948L52.6293 125.155L56.5623 129.362M64.4284 120.942L56.5623 129.362M64.4284 120.942C66.3706 125.096 68.4102 127.279 72.2944 129.357M72.2944 129.357L80.1605 137.777L72.2944 146.192L68.3614 141.984L64.4284 137.777M72.2944 129.357L64.4284 137.777M56.5623 129.362L64.4284 137.777M78.0927 68.3166L124.759 118.237M143.089 9.41458L181.859 50.8883"
        stroke="#222222"
      />
      <path
        d="M58.7555 135.401C64.585 141.906 64.3801 152.232 58.3019 158.466C57.5799 159.206 56.9297 159.518 56.2883 159.603C55.6295 159.69 54.9281 159.546 54.0868 159.273C53.729 159.156 53.3488 159.016 52.9432 158.867C51.4753 158.327 49.6752 157.665 47.4059 157.546C41.6016 157.243 32.7804 160.44 17.2503 176.958C15.7775 175.039 15.6573 172.092 16.5652 168.448C17.5291 164.58 19.6127 160.1 22.1566 155.649C27.243 146.749 34.0911 138.097 37.1946 134.914C43.2728 128.681 52.926 128.897 58.7555 135.401Z"
        fill="#FFD633"
        stroke="#222222"
      />
      <path
        d="M150.455 47.2802C150.455 54.0072 145.365 59.4021 139.156 59.4021C132.947 59.4021 127.857 54.0072 127.857 47.2802C127.857 40.5532 132.947 35.1583 139.156 35.1583C145.365 35.1583 150.455 40.5532 150.455 47.2802Z"
        fill="white"
        stroke="#222222"
      />
    </svg>
  )
}
