import { makeAutoObservable } from 'mobx'
import {
  linkAccount,
  getAccountOnboardingStatus,
  getMultipleRevenueShareConfigs,
  createRevenueShare,
  updateRevenueShare,
  Account,
  getAccountLink,
  getStripeDashboard,
  DashboardResponse,
} from '@busie/api'
import { FetchingStatus } from '@busie/core'
import {
  MessageType,
  MESSAGES,
  MessageContent,
} from '~/settings/store/constants'
import { notificationStore } from '@busie/features'
import { history } from '@busie/utils'

export interface Notification {
  type: MessageType
  content: MessageContent
}

class PaymentStore {
  linkFetchStatus: FetchingStatus = 'notFetched'
  authToken: string | null = null
  notification: Notification | null = null
  isAccountLinked: boolean | null = null
  account: Account | null = null
  dashboard: DashboardResponse | null = null
  revenueShares: Map<string, number> = new Map<string, number>()

  constructor() {
    makeAutoObservable(this)

    this.linkUserAccount = this.linkUserAccount.bind(this)
    this.setAuthToken = this.setAuthToken.bind(this)
    this.clearNotification = this.clearNotification.bind(this)
    this.getContinueOnboardingUrl = this.getContinueOnboardingUrl.bind(this)
  }

  setAuthToken(authToken: string) {
    this.authToken = authToken
  }

  setInitialData() {
    this.linkFetchStatus = 'notFetched'
    this.notification = null
  }

  setNotification(type: MessageType) {
    this.notification = {
      type,
      content: MESSAGES[type],
    }
  }

  clearNotification() {
    this.notification = null
    history.replace('/organization-settings/payments')
  }

  async linkUserAccount(orgId: string) {
    if (!this.authToken) return
    this.setInitialData()

    this.linkFetchStatus = 'fetching'

    try {
      const result = await linkAccount(this.authToken, orgId)
      window.location.replace(result.url)
    } catch (e) {
      this.linkFetchStatus = 'failedFetching'
      this.setNotification('error')
    }
  }

  async getContinueOnboardingUrl(account: Account) {
    if (!this.authToken || !account) return

    this.linkFetchStatus = 'fetching'
    try {
      const result = await getAccountLink(this.authToken, account._id)
      window.location.replace(result.url)
    } catch (e) {
      this.linkFetchStatus = 'failedFetching'
      this.setNotification('error')
    }
  }

  async getStripeDashboard() {
    if (!this.authToken) {
      return
    }
    try {
      const result = await getStripeDashboard(this.authToken)
      this.dashboard = result
    } catch (e) {
      this.setNotification('error')
    }
  }

  async getIsAccountLinked(orgId: string): Promise<boolean> {
    if (!this.authToken) {
      this.isAccountLinked = false
      return false
    }

    try {
      const result = await getAccountOnboardingStatus(this.authToken, orgId)
      this.isAccountLinked = result.onboardCompleted
      this.account = result
      return result.onboardCompleted
    } catch (e) {
      this.isAccountLinked = false
      this.account = null
      return false
    }
  }

  async getRevenueShares(configIds: string[], orgId?: string): Promise<void> {
    if (!orgId) {
      return
    }

    if (!this.authToken) {
      this.revenueShares = new Map<string, number>()
      return
    }

    try {
      const result = await getMultipleRevenueShareConfigs(
        this.authToken,
        configIds
      )
      this.revenueShares = new Map<string, number>()
      result.forEach((r) => {
        const organization = r.destinations.find(
          (d) => d.organizationId === orgId
        )
        if (organization) {
          this.revenueShares.set(r._id, organization.apportionmentValue)
        } else {
          this.revenueShares.set(r._id, 0)
        }
      })
    } catch (e) {
      console.error(e)
    }
  }

  async createRevenueShare(
    orgId: string,
    revenueValue: number,
    publicIntegrationKey: string
  ) {
    if (!this.authToken) {
      return
    }

    try {
      const revenueShare = await createRevenueShare(
        this.authToken,
        orgId,
        this.account?.currentPlatformFee || 0,
        revenueValue,
        publicIntegrationKey
      )
      return revenueShare
    } catch (e) {
      console.error(e)
      notificationStore.setNotificationFromError(e)
    }
    return null
  }

  async updateRevenueShare(
    orgId: string,
    otherOrgId: string,
    revenueShareConfigId: string,
    newRevenueValue: number
  ) {
    if (!this.authToken) {
      return
    }

    try {
      const revenueShare = await updateRevenueShare(
        this.authToken,
        orgId,
        otherOrgId,
        revenueShareConfigId,
        this.account?.currentPlatformFee || 0,
        newRevenueValue
      )
      return revenueShare
    } catch (e) {
      console.error(e)
      notificationStore.setNotificationFromError(e)
    }
    return null
  }

  get isFetching(): boolean {
    return this.isAccountLinked === null
  }

  get isLinkButtonDisabled(): boolean {
    return !!this.isAccountLinked
  }

  get isLinkButtonLoading() {
    return this.linkFetchStatus === 'fetching' || this.isFetching
  }
}

export default new PaymentStore()
