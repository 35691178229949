import { styled } from '@mui/system'
import { FlexContainer } from '../FlexContainer'
import { FlexContainerProps } from '../FlexContainer/FlexContainer'

interface Props extends FlexContainerProps {
  padding?: string
}

const Panel: React.FC<React.PropsWithChildren<Props>> = styled(FlexContainer)((
  props: Props
) => {
  return {
    padding: props.padding || '20px',
    borderRadius: '4px',
  }
})

export default Panel
