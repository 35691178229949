import { styled } from '@mui/system'

import { MenuProps } from '../index'

type Position = 'top' | 'bottom' | 'left' | 'right'
type MenuStyledProps = Pick<MenuProps, Position>

const MenuStyled = styled('div')(({
  top,
  bottom,
  left,
  right,
}: MenuStyledProps) => {
  return {
    position: 'relative',
    '& .menu-parent': {
      cursor: 'pointer',
    },
    '& .menu-block': {
      position: 'absolute',
      zIndex: 55,
      minWidth: '100%',
      top,
      bottom,
      left,
      right,
    },
  }
})

export default MenuStyled
