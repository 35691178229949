import React from 'react'

interface Props {
  color?: string
  size?: number
}

const ArmrestsIcon: React.FC<React.PropsWithChildren<Props>> = ({
  color = '#222',
  size = 56,
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 56 56"
      aria-label="armrests"
    >
      <path
        fill={color}
        stroke={color}
        d="M33.786 17.713a1 1 0 01.958-.713H35.8a2 2 0 011.992 2.19L36.11 36.843a1 1 0 01-1.238.875l-2.32-.58a1 1 0 01-.715-.683l-1.223-4.075c-.075-.251-.1-.514-.075-.774l.442-4.415c.013-.127.037-.253.074-.375l2.731-9.103z"
      ></path>
      <path
        fill={color}
        stroke={color}
        d="M19.667 34.5a1 1 0 01.931-.44L30 35l4.38.876a1 1 0 01.773.738l.536 2.144A1 1 0 0134.72 40H22.333a1 1 0 01-.6-.2l-2.976-2.232a1 1 0 01-.232-1.355l1.142-1.713zM22.646 27.354a1 1 0 01.849-.283l6.386.912a1 1 0 01.23.061l4.26 1.705a1 1 0 01.629.928v.157a1 1 0 01-1.152.989L22.324 30.05a1 1 0 01-.556-.282l-.06-.06a1 1 0 010-1.415l.938-.94z"
      ></path>
      <circle cx="33.5" cy="36.5" r="1.5" fill="#fff" stroke={color}></circle>
      <circle cx="32.5" cy="30.5" r="1.5" fill="#fff" stroke={color}></circle>
    </svg>
  )
}

export default ArmrestsIcon
