import { makeAutoObservable } from 'mobx'
import { dayjsExt, validateTime, DayjsExt } from '@busie/utils'
import {
  CreateTripFormData,
  fetchLegPrice,
  fetchLegPriceAsGuest,
  RouteResponse,
  createTripAsGuest,
  createTripAsOperator,
  CreateQuoteData,
} from '@busie/api'

import { RouteStopDeparture } from './store.types'
import { RouteForm } from '../routeForm/store.types'
import { validateForm, getArrivalDateTime } from './helpers'
import { Trip, TripLeg } from '@busie/utils'

class DeparturesForm {
  departures: RouteStopDeparture[] = [] // form data
  route: RouteResponse | null = null // pathfinder serivce response
  trip: Trip | null = null // trips serivce response
  legPriceMap: { [key: string]: number } = {}
  // estimatedPrice = 0;
  isLoading = false
  constructor() {
    makeAutoObservable(this)
  }
  public setDepartures(routeForm: RouteForm) {
    const waypoints = [
      routeForm.start,
      ...(routeForm.waypoints || []),
      routeForm.end,
    ]
    this.departures = []
    waypoints.forEach((waypoint, index, array): void => {
      this.departures.push({
        isLast: array.length === index + 1,
        date: null,
        time: null,
        minDateTime: null,
        name: waypoint?.formatted_address || '',
        location: {
          ...(waypoint?.geometry?.location || {
            lat: () => 0,
            lng: () => 0,
          }),
          address: waypoint?.formatted_address,
        },
        place_id: waypoint?.place_id || '',
      })
    })
    this.setMinimalDateTime()
  }
  public setRoute(routeResponse: RouteResponse) {
    this.route = routeResponse
  }
  public setDepartureTime(index: number, value: DayjsExt | null) {
    this.departures[index].time = value
    if (validateTime(value)) {
      this.setMinimalDateTime()
    }
  }
  public setDepartureDate(index: number, value: Date) {
    this.departures[index].date = value
    this.setMinimalDateTime()
  }
  public validateForm(): boolean {
    return validateForm(this.departures)
  }
  public setMinimalDateTime(): void {
    const legs = this.route?.legs || []

    // tomorrow  + round time to hours:
    const minimalDate: DayjsExt = dayjsExt()
      .add(1, 'day')
      .add(1, 'hour')
      .set('minute', 0)
      .set('second', 0)
      .set('millisecond', 0)

    this.departures.forEach((departure, index, array) => {
      if (index === 0) {
        departure.minDateTime = minimalDate.toDate()
        if (!departure.date) {
          departure.date = departure.minDateTime
        }
        return
      }
      const date =
        array[index - 1].date || (array[index - 1].minDateTime as Date)
      const time =
        array[index - 1].time || dayjsExt(array[index - 1].minDateTime)

      departure.minDateTime = getArrivalDateTime(
        date,
        time,
        legs[index - 1].estimateTravelTime
      )
    })
  }
  public async submitForm(
    data: CreateTripFormData,
    tokens: { guestAuthToken?: string; tripsAuthToken?: string },
    embedded?: boolean
  ): Promise<void> {
    const tripData = embedded
      ? await createTripAsGuest(tokens.guestAuthToken || '', data)
      : await createTripAsOperator(tokens.tripsAuthToken || '', data)
    await this.setTrip(tripData)
  }
  public setTrip(trip: Trip) {
    this.trip = trip
  }

  public async updateLegPriceMap(
    leg: TripLeg,
    tokens: {
      rateService?: string
      guest?: string
    },
    isFormEmbedded?: boolean
  ) {
    const fetchLegPricePromises = leg._legPrice.map(({ legPriceId }) =>
      isFormEmbedded
        ? fetchLegPriceAsGuest(legPriceId, tokens.guest || '')
        : fetchLegPrice(legPriceId, tokens.rateService || '')
    )
    const legPrices = await Promise.all(fetchLegPricePromises)

    this.legPriceMap[leg._id] = legPrices.reduce(
      (total, legPrice) => total + legPrice._subtotal,
      0
    )
  }

  public clearLegPriceMap() {
    this.legPriceMap = {}
  }

  public get estimatedPrice(): number {
    return (
      this.trip?._price ||
      Object.keys(this.legPriceMap).reduce(
        (total, legId) => total + this.legPriceMap[legId],
        0
      )
    )
  }

  public get createQuotePayload(): Omit<CreateQuoteData, 'customerGroupName'> {
    if (!this.trip)
      throw new Error(
        'Invalid use of `get createQuotePayload`: Trip data is not set.'
      )

    return {
      locationId: this.trip._dispatchId,
      organizationId: this.trip._organizationId,
      startDate: this.trip._startDate ?? this.trip._legs[0]._departureDateTime,
      customerGroupId: this.trip._tripPlannerGroupId ?? '',
      contactId: this.trip._mainContactId ?? this.trip._tripPlannerId ?? '',
      experienceId: this.trip._id,
      price: Math.ceil(this.estimatedPrice ?? 0),
      quotesIntegrationId: this.trip._integrationId || undefined,
      sourceId: this.trip._networkId || undefined,
    }
  }

  public toggleLoading(): void {
    this.isLoading = !this.isLoading
  }

  public reset() {
    this.departures = []
    this.route = null
  }
}

export default DeparturesForm
