/**
 * Interface representing a subscription plan
 * @interface Plan
 * @property {('FREEMIUM'|'STARTER'|'BASIC'|'PRO'|'ENTERPRISE')} subscriptionType - The type of subscription
 */

export enum SubscriptionType {
  FREEMIUM = 'FREEMIUM',
  STARTER = 'STARTER',
  BASIC = 'BASIC',
  PRO = 'PRO',
  ENTERPRISE = 'ENTERPRISE',
}

export interface Plan {
  _id: string
  subscriptionType: SubscriptionType
}
