import palette from '../../theme/palette'
import { StatusTextStyled } from './StatusTextStyled'

export const PendingStatusText = () => {
  return (
    <StatusTextStyled color={palette.supernova.minus10}>
      Pending
    </StatusTextStyled>
  )
}
