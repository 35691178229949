import React from 'react'

interface Props {
  color?: string
  size?: number
}

export const ConfigurationIcon: React.FC<React.PropsWithChildren<Props>> = ({
  color = 'currentColor',
  size = 24,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 7L8 5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 18L8 11"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 12L16 5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 17.999L16 16"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.58575 7.58527C7.36675 6.80427 8.63275 6.80427 9.41375 7.58527C10.1947 8.36627 10.1947 9.63227 9.41375 10.4133C8.63275 11.1943 7.36675 11.1943 6.58575 10.4133C5.80475 9.63227 5.80475 8.36627 6.58575 7.58527"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5857 12.5853C15.3667 11.8043 16.6328 11.8043 17.4138 12.5853C18.1948 13.3663 18.1948 14.6323 17.4138 15.4133C16.6328 16.1943 15.3668 16.1943 14.5858 15.4133C13.8047 14.6323 13.8047 13.3663 14.5857 12.5853"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
