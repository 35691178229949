import React from 'react'
import { VehicleIconProps as Props } from '.'

export const SchoolBusIcon: React.FC<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="2" y="33" width="4" height="1" rx="0.5" fill="#222222" />
      <rect x="14" y="33" width="40" height="1" rx="0.5" fill="#222222" />
      <rect x="2" y="35" width="4" height="1" rx="0.5" fill="#222222" />
      <rect x="14" y="35" width="40" height="1" rx="0.5" fill="#222222" />
      <rect x="18" y="24" width="6" height="6" rx="1" stroke="#FF0018" />
      <path
        d="M14 42H39M6 42H4C2.89543 42 2 41.1046 2 40V33.8116C2 32.8938 2.62459 32.0939 3.51493 31.8713L7.75963 30.8101C8.51588 30.621 9.09337 30.0098 9.23923 29.244L10.6903 21.6258C10.87 20.6825 11.6947 20 12.655 20H52C53.1046 20 54 20.8954 54 22V40C54 41.1046 53.1046 42 52 42H46.5"
        stroke="#FF0018"
      />
      <path
        d="M10 24H15C15.5523 24 16 24.4477 16 25V29C16 29.5523 15.5523 30 15 30H9"
        stroke="#FF0018"
      />
      <path
        d="M54 24H51C50.4477 24 50 24.4477 50 25V29C50 29.5523 50.4477 30 51 30H54"
        stroke="#FF0018"
        strokeLinecap="round"
      />
      <rect x="26" y="24" width="6" height="6" rx="1" stroke="#FF0018" />
      <rect x="34" y="24" width="6" height="6" rx="1" stroke="#FF0018" />
      <rect x="42" y="24" width="6" height="6" rx="1" stroke="#FF0018" />
      <circle cx="10" cy="42" r="4" stroke="#222222" />
      <circle cx="43" cy="42" r="4" stroke="#222222" />
    </svg>
  )
}

export default SchoolBusIcon
