import { QuoteWithAttrs } from '@busie/api'
import { Customer, Quote, Trip } from '@busie/utils'

export const collectQuotesWithTrips = (
  quotes: Quote[],
  data: {
    trips: Trip[]
    customers: Customer[]
  }
): QuoteWithAttrs[] => {
  const { trips, customers } = data
  const tripIdMap = new Map(trips.map((trip) => [trip._id, trip]))
  const customerIdMap = new Map(
    customers.map((customer) => [customer.id, customer])
  )
  const items = quotes.reduce((items: QuoteWithAttrs[], quote) => {
    const trip = tripIdMap.get(quote._experienceId)
    if (trip) {
      const customer = customerIdMap.get(
        trip._mainContactId || trip._tripPlannerId
      )

      items.push({ trip, tripPlanner: customer, ...quote })
    }
    return items
  }, [])
  return items
}
