import React from 'react'

import { history } from '@busie/utils'

import { FlexContainer } from '../FlexContainer'
import Menu from '../Menu'
import { ChevronDown } from '../icons'
import palette from '../theme/palette'

const NavigationMenuToggler: React.FC<{ name: string; active?: boolean }> = ({
  name,
  active = false,
}) => (
  <FlexContainer
    direction="row"
    align="center"
    columnGap={1}
    justify="space-between"
    sx={{
      boxShadow: active
        ? `inset 0px -4px 0px 0px ${palette?.black?.plus10}`
        : `inset 0px 0px 0px 0px ${palette.black.plus100}`,
      padding: '20px 24px',
      transition: 'color .2s ease, box-shadow .2s ease',
    }}
  >
    <span
      style={{
        lineHeight: '20px',
        textDecoration: 'none',
        fontWeight: '600',
        fontSize: '16px',
        color: active ? palette.black.plus10 : palette.black.plus50,
      }}
    >
      {name}
    </span>
    <ChevronDown
      size={24}
      color={active ? palette.black.plus10 : palette.black.plus50}
    />
  </FlexContainer>
)

const NavigationMenuItem: React.FC<{ name: string; active?: boolean }> = ({
  name,
  active = false,
}) => (
  <div
    style={{
      width: '100%',
      display: 'flex',
      textDecoration: 'none',
      fontWeight: '600',
      fontSize: '16px',
      paddingBottom: '10px',
      paddingTop: '10px',
      boxShadow: active
        ? `inset 0px -4px 0px 0px ${palette?.black?.plus10}`
        : `inset 0px 0px 0px 0px ${palette.black.plus100}`,
      color: active ? palette.black.plus10 : palette.black.plus50,
    }}
  >
    {name}
  </div>
)

interface Props {
  name: string
  items: { name: string; to: string; active: boolean }[]
  active?: boolean
}

export const NavigationMenu: React.FC<Props> = ({
  name,
  items,
  active = false,
}) => {
  return (
    <Menu
      top="100%"
      right="0"
      toggler={<NavigationMenuToggler name={name} active={active} />}
      items={items.map(({ to, active, name }) => ({
        content: <NavigationMenuItem name={name} active={active} />,
        action: () => history.push(to),
      }))}
    />
  )
}
