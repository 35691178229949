import React from 'react'
import { Link } from 'react-router-dom'

import FilterSelectorStyled from './FilterSelectorStyled'

interface Option {
  name: string
  link: string
}

interface Props {
  value: string
  options: Option[]
  withState?: boolean
  onChange?: (v: string) => void
}

const FilterTypeSelector: React.FC<React.PropsWithChildren<Props>> = ({
  value,
  options,
  withState = false,
  onChange,
}: Props) => {
  return (
    <FilterSelectorStyled>
      {options.map((type, index) => (
        <Link
          key={index}
          to={type.link}
          className={value === type.name ? 'active' : ''}
          type="button"
          onClick={(e) => {
            if (!withState) return

            e.preventDefault()
            onChange && onChange(type.name)
          }}
        >
          {type.name}
        </Link>
      ))}
    </FilterSelectorStyled>
  )
}

export default FilterTypeSelector
