import React from 'react'
import { VehicleIconProps as Props } from '.'

export const BlackCarSuvIcon: React.FC<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26 25C26 24.4477 26.4477 24 27 24H32C32.5523 24 33 24.4477 33 25V30C33 30.5523 32.5523 31 32 31H27C26.4477 31 26 30.5523 26 30V25Z"
        stroke="#222222"
      />
      <path
        d="M35 25C35 24.4477 35.4477 24 36 24H49.2457C49.6922 24 50.0846 24.296 50.2072 24.7253L51.6358 29.7253C51.8183 30.3641 51.3386 31 50.6743 31H36C35.4477 31 35 30.5523 35 30V25Z"
        stroke="#222222"
      />
      <path
        d="M18.7009 24.4188C18.8886 24.156 19.1917 24 19.5146 24H23C23.5523 24 24 24.4477 24 25V30C24 30.5523 23.5523 31 23 31H15.9432C15.1298 31 14.6567 30.0806 15.1295 29.4188L18.7009 24.4188Z"
        stroke="#222222"
      />
      <rect x="3" y="35" width="4" height="1" rx="0.5" fill="#222222" />
      <rect x="3" y="38" width="4" height="1" rx="0.5" fill="#222222" />
      <path
        d="M10.25 42H5C3.89543 42 3 41.1046 3 40V33.2901C3 32.271 3.76627 31.4149 4.77914 31.3023L11.1058 30.5994C11.6745 30.5362 12.1889 30.2324 12.5189 29.765L17.4024 22.8466C17.7771 22.3157 18.3865 22 19.0363 22H26H31H49.5119C50.3952 22 51.1738 22.5793 51.4276 23.4253L53.9157 31.7188C53.9716 31.9053 54 32.0989 54 32.2935V40C54 41.1046 53.1046 42 52 42H45.75M18.875 42H27.5H37.125"
        stroke="#222222"
      />
      <circle cx="14.5" cy="41.5" r="4.5" stroke="#222222" />
      <circle cx="41.5" cy="41.5" r="4.5" stroke="#222222" />
    </svg>
  )
}

export default BlackCarSuvIcon
