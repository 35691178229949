import React from 'react'
import pluralize from 'pluralize'
import ConfirmationModalStyled from '../ConfirmationModal/ConfirmationModalStyled'
import { Button } from '../Button'

interface Props {
  onDelete: () => void
  onSelectAll: () => void
  onDeselectAll: () => void
  selectedItemsCount: number
}

const DispatchLocationsList: React.FC<React.PropsWithChildren<Props>> = ({
  onDelete,
  onSelectAll,
  selectedItemsCount,
  onDeselectAll,
}) => {
  const handleDeleteAllSelected = (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e?.preventDefault()
    onDelete()
  }

  return (
    <ConfirmationModalStyled>
      <div className="content-block">
        <div className="items-count-label">
          {`${selectedItemsCount} ${pluralize(
            'item',
            selectedItemsCount
          )} selected`}
        </div>
        <div className="controls">
          <a
            href="#select-all"
            onClick={(e) => {
              e.preventDefault()
              onSelectAll()
            }}
          >
            Select all
          </a>
          <a
            href="#deselect-all"
            className="danger"
            onClick={(e) => {
              e.preventDefault()
              onDeselectAll()
            }}
          >
            Deselect all
          </a>
        </div>
      </div>
      <div className="controls-block">
        <Button typestyle="secondary" onClick={handleDeleteAllSelected}>
          Delete all selected
        </Button>
      </div>
    </ConfirmationModalStyled>
  )
}

export default DispatchLocationsList
