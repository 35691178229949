import { styled } from '@mui/system'
import palette from '../theme/palette'
interface Props {
  color?: string
  weight?: number
  fontSize?: string
}

export const Text = styled('p')<Props>(({
  color = palette.black.main,
  weight = 600,
  fontSize = '14px',
}) => {
  return {
    fontSize,
    color,
    fontWeight: weight,
    lineHeight: '150%',
    margin: 0,
  }
})
