export interface EmbeddedAnalyticsResponse {
  isLoading: boolean
  isFetching: boolean
  embedUrl: string
  isError: boolean
}

export interface EmbeddedAnalyticsDashboardRequest {
  dashboard: DashboardTypesMap
}
export enum DashboardTypesMap {
  Sales = 'sales',
  Operations = 'operations',
}
