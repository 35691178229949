import { env } from '@busie/utils'

// Backend For Frontend Service
export const BFF_API_URL = `${env(
  'BUSIE_ETP_BFF_SERVICE_API_URL'
)}/etp-bff` as const

// Trips Service
export const TRIPS_SERVICE_URL = `${env(
  'BUSIE_TRIPS_SERVICE_API_URL'
)}/trip-service` as const

// Customers Service
export const CUSTOMERS_SERVICE_URL = `${env(
  'BUSIE_CUSTOMERS_SERVICE_API_URL'
)}/customers` as const

// Guest Token
export const UNAUTHENTICATED_GUEST_TOKEN = '' as const

// Quotes and Bookings Service
export const QUOTES_AND_BOOKINGS_SERVICE_URL = `${env(
  'BUSIE_QUOTES_AND_BOOKINGS_SERVICE_API_URL'
)}/qnb`

// Payment Service
export const PAYMENT_SERVICE_URL = `${env(
  'BUSIE_PAYMENT_SERVICE_API_URL'
)}/ps` as const

// Embedded Analytics Service
export const EMBEDDED_ANALYTICS_SERVICE_URL = `${env(
  'BUSIE_EMBEDDED_ANALYTICS_SERVICE_API_URL'
)}/ea` as const

export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

export enum Endpoints {
  AUTH_FP = 'auth/_fp',
  AUTH_LOGIN = 'auth/login',
  TRIPS = 'trips',
  CUSTOMERS = 'customers',
  QUOTES = 'quotes',
  EMBEDDED_ANALYTICS = 'embed-url',
  ORGANIZATIONS = 'organizations',
  ROUTES = 'routes',
  LEG_PRICES = 'leg-prices',
}
