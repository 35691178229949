import { makeAutoObservable } from 'mobx'
import { AxiosError } from 'axios'
import { NotificationType } from './Notification'

interface Notification {
  type: NotificationType
  header?: string
  message?: string
  duration?: number
}

export interface NotificationStore {
  notification?: Notification
  setNotification: (notification: Notification) => void
  clearNotification: () => void
  setErrorNotification: (header: string, message: string) => void
  setSuccessNotification: (header: string, message: string) => void
  setNotificationFromError: (e: unknown) => void
  getErrorMessage: (e: unknown) => string
}

class NotificationsStore implements NotificationStore {
  notification: Notification | undefined = undefined

  constructor() {
    makeAutoObservable(this)
    this.setNotification = this.setNotification.bind(this)
    this.clearNotification = this.clearNotification.bind(this)
  }

  setNotification(notification: Notification) {
    this.notification = notification
  }
  clearNotification() {
    this.notification = undefined
  }

  setNotificationFromError(e: unknown) {
    this.setNotification({
      type: 'failure',
      header: (e as { name: string }).name,
      message: this.getErrorMessage(e),
    })
  }

  getErrorMessage(e: unknown) {
    const axiosError = e as AxiosError
    if (axiosError.response) {
      if (axiosError.response.data.errors?.length) {
        return axiosError.response.data.errors[0]
      } else if (axiosError.response.data.brokenRules?.length) {
        return axiosError.response.data.brokenRules.join('\n')
      }
      return axiosError.response.data.message
    }
    return (e as Error).message
  }

  setErrorNotification(header: string, message: string) {
    this.setNotification({
      type: 'failure',
      header,
      message,
    })
  }
  setSuccessNotification(header: string, message: string) {
    this.setNotification({
      type: 'success',
      header,
      message,
    })
  }
}

export const notificationStore = new NotificationsStore()
