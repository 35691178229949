import { styled } from '@mui/system'

const TooltipIconStyled = styled('div')(() => {
  // const palette = props.theme?.palette;
  return {
    display: 'flex',
  }
})

export default TooltipIconStyled
