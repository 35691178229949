import { styled } from '@mui/system'

interface Props {
  hide?: boolean
}

const CheckboxBlock = styled('div')<Props>((props: Props) => {
  return {
    visibility: props.hide ? 'hidden' : 'visible',
  }
})

export default CheckboxBlock
