import React from 'react'
import { VehicleIconProps as Props } from '.'

export const MotorcoachIcon: React.FC<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.55279 23.7764C3.42929 24.0234 3.5294 24.3237 3.77639 24.4472C4.02338 24.5707 4.32372 24.4706 4.44721 24.2236L3.55279 23.7764ZM7.5 21.5H5.61803V22.5H7.5V21.5ZM4.27639 22.3292L3.55279 23.7764L4.44721 24.2236L5.17082 22.7764L4.27639 22.3292ZM5.61803 21.5C5.04988 21.5 4.53048 21.821 4.27639 22.3292L5.17082 22.7764C5.25552 22.607 5.42865 22.5 5.61803 22.5V21.5Z"
        fill="#222222"
      />
      <rect x="3" y="39" width="4" height="1" rx="0.5" fill="#222222" />
      <path
        d="M15.8106 23.7575C15.9219 23.3123 16.3219 23 16.7808 23H53V31H15.2808C14.6302 31 14.1528 30.3886 14.3106 29.7575L15.8106 23.7575Z"
        stroke="#FF0018"
      />
      <path
        d="M10.25 43H5C3.89543 43 3 42.1046 3 41V34.0898C3 33.0402 3.20655 32.0008 3.60787 31.031L7.46653 21.7059C8.39471 19.4628 10.5831 18 13.0106 18H51C52.1046 18 53 18.8954 53 20V41C53 42.1046 52.1046 43 51 43H46.75M17.875 43H27.5H39.125"
        stroke="#FF0018"
      />
      <path
        d="M7 23H12.6907C13.3501 23 13.829 23.627 13.6555 24.2631L11.201 33.2631C11.0823 33.6982 10.6872 34 10.2362 34H3"
        stroke="#FF0018"
      />
      <circle cx="14" cy="42" r="4" stroke="#222222" />
      <circle cx="43" cy="42" r="4" stroke="#222222" />
    </svg>
  )
}

export default MotorcoachIcon
