import React from 'react'
import { VehicleIconProps as Props } from '.'

export const TrolleyIcon: React.FC<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 29.5C4.27614 29.5 4.5 29.2761 4.5 29C4.5 28.7239 4.27614 28.5 4 28.5V29.5ZM4.5 22.5H2.5V23.5H4.5V22.5ZM1.5 23.5V28.5H2.5V23.5H1.5ZM2.5 29.5H4V28.5H2.5V29.5ZM1.5 28.5C1.5 29.0523 1.94772 29.5 2.5 29.5V28.5H1.5ZM2.5 22.5C1.94772 22.5 1.5 22.9477 1.5 23.5H2.5H2.5V22.5Z"
        fill="#222222"
      />
      <rect x="4" y="40" width="4" height="1" rx="0.5" fill="#222222" />
      <rect x="48" y="40" width="4" height="1" rx="0.5" fill="#222222" />
      <rect x="17" y="16" width="6" height="1" rx="0.5" fill="#222222" />
      <rect x="25" y="16" width="6" height="1" rx="0.5" fill="#222222" />
      <rect x="33" y="16" width="6" height="1" rx="0.5" fill="#222222" />
      <path
        d="M10.25 43H5C4.44772 43 4 42.5523 4 42V32.5V20H52V42C52 42.5523 51.5523 43 51 43H44.75M15.875 43H27.5H39.125"
        stroke="#FF0018"
      />
      <path
        d="M20 23.5C20 23.2239 20.2239 23 20.5 23H27.5C27.7761 23 28 23.2239 28 23.5V42.5C28 42.7761 27.7761 43 27.5 43H20.5C20.2239 43 20 42.7761 20 42.5V23.5Z"
        stroke="#FF0018"
      />
      <path
        d="M6 25.5C6 24.1193 7.11929 23 8.5 23V23C9.88071 23 11 24.1193 11 25.5V32.7778C11 32.9005 10.9005 33 10.7778 33H6.22222C6.09949 33 6 32.9005 6 32.7778V25.5Z"
        stroke="#FF0018"
      />
      <path
        d="M13 25.5C13 24.1193 14.1193 23 15.5 23V23C16.8807 23 18 24.1193 18 25.5V32.7778C18 32.9005 17.9005 33 17.7778 33H13.2222C13.0995 33 13 32.9005 13 32.7778V25.5Z"
        stroke="#FF0018"
      />
      <path
        d="M30 23.5C30 23.2239 30.2239 23 30.5 23H36.5C36.7761 23 37 23.2239 37 23.5V32.5C37 32.7761 36.7761 33 36.5 33H30.5C30.2239 33 30 32.7761 30 32.5V23.5Z"
        stroke="#FF0018"
      />
      <path
        d="M39 23.5C39 23.2239 39.2239 23 39.5 23H49.5C49.7761 23 50 23.2239 50 23.5V32.5C50 32.7761 49.7761 33 49.5 33H39.5C39.2239 33 39 32.7761 39 32.5V23.5Z"
        stroke="#FF0018"
      />
      <path
        d="M14 15.5C14 15.2239 14.2239 15 14.5 15H41.5C41.7761 15 42 15.2239 42 15.5V17.5C42 17.7761 41.7761 18 41.5 18H14.5C14.2239 18 14 17.7761 14 17.5V15.5Z"
        stroke="#FF0018"
      />
      <path
        d="M2 19.6C2 18.7163 2.71634 18 3.6 18H52.4C53.2837 18 54 18.7163 54 19.6V19.6C54 19.8209 53.8209 20 53.6 20H2.4C2.17909 20 2 19.8209 2 19.6V19.6Z"
        stroke="#FF0018"
      />
      <circle cx="13" cy="43" r="3" stroke="#222222" />
      <circle cx="42" cy="43" r="3" stroke="#222222" />
    </svg>
  )
}

export default TrolleyIcon
