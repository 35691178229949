import { palette } from '@busie/ui-kit'
import CircularProgress from '@mui/material/CircularProgress'
import React, { useMemo } from 'react'

import { InputLabel, SearchIcon } from '@busie/ui-kit'

import SearchInputOption from './SearchInputOption'
import SearchInputFieldStyled from './styled/SearchInputFieldStyled'
import SelectInputStyled from './styled/SelectInputStyled'

import Autocomplete from '@mui/material/Autocomplete'

import { useUserContext } from '@busie/core'
import { Customer } from '@busie/utils'
import flatten from 'lodash.flatten'
import { useGroups } from './CustomerForm/hooks'

interface Props {
  setCustomer: (value: Customer | null) => void
  customer?: Customer | null
  disabled?: boolean
}

const SearchCustomerInput: React.FC<React.PropsWithChildren<Props>> = ({
  customer,
  setCustomer,
  disabled,
}) => {
  const { customersAuthToken } = useUserContext()
  const [inputValue, setInputValue] = React.useState<string>('')
  const [groups, isLoading] = useGroups(customersAuthToken, inputValue)
  const customers: Customer[] = useMemo(() => {
    return flatten(
      groups.map((group) => {
        return group.members.map((member) => ({
          ...member,
          groupName: group.name,
          groupId: group.id,
        }))
      })
    )
  }, [groups])

  const options = inputValue ? customers : []
  const noOptionsText = inputValue
    ? 'No Groups match your search'
    : 'Start typing to search'
  return (
    <SearchInputFieldStyled>
      <InputLabel>Search for a customer by Group name</InputLabel>
      <div className="search-input-block">
        <SearchIcon className="search-icon" color={palette?.black?.plus30} />
        <Autocomplete
          disabled={disabled}
          options={options}
          loading={isLoading}
          id={'select-autocomplete-users'}
          value={customer}
          onChange={(event, value) => {
            setCustomer(value)
          }}
          inputValue={inputValue}
          noOptionsText={noOptionsText}
          onInputChange={(e, value) => setInputValue(value)}
          isOptionEqualToValue={(option: Customer, value) =>
            option.id === value.id
          }
          getOptionLabel={(option: Customer) =>
            option.name + (', ' + (option.groupName || 'No group'))
          }
          renderOption={(props, option) => (
            <SearchInputOption {...props} groupname={option.groupName || ''}>
              {option.name}
            </SearchInputOption>
          )}
          renderInput={(params) => (
            <SelectInputStyled
              {...params}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="Enter Group name"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoading && (
                      <CircularProgress color="inherit" size={20} />
                    )}
                    {!isLoading && params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </div>
    </SearchInputFieldStyled>
  )
}

export default SearchCustomerInput
