import React from 'react'
import PopupStyled from './styled/PopupStyled'

import PopupHeader from './PopupHeader'
import { DialogProps, useMediaQuery } from '@mui/material'

interface Props {
  onClose?: () => void
  onBack?: () => void
  isPopupOpened: boolean
  title?: string | React.ReactNode
  subtitle?: string
  bgcolor?: string
  zIndex?: string
  children: JSX.Element | string
  PaperProps?: DialogProps['PaperProps']
}
const Popup: React.FC<React.PropsWithChildren<Props>> = ({
  subtitle,
  title,
  children,
  onClose,
  onBack,
  isPopupOpened,
  ...props
}) => {
  const isMobile = useMediaQuery('@media (max-width: 800px)')
  return (
    <PopupStyled
      {...props}
      onClose={onClose}
      open={isPopupOpened}
      scroll="body"
      fullScreen={isMobile}
    >
      <PopupHeader onBack={onBack} onClose={onClose} subtitle={subtitle}>
        {title}
      </PopupHeader>
      {children}
    </PopupStyled>
  )
}

export default Popup
