import { Amenity } from '@busie/utils'

export enum ETPAmenity {
  ARMREST = 'ARMREST',
  CUSTOM = 'CUSTOM',
  DVD_PLAYER = 'DVD_PLAYER',
  FOOTRESTS = 'FOOTRESTS',
  HEADRESTS = 'HEADRESTS',
  KNEELING_COACH = 'KNEELING_COACH',
  OUTLETS = 'OUTLETS',
  RECLINING_SEATS = 'RECLINING_SEATS',
  RESTROOM = 'RESTROOM',
  SEATBELTS = 'SEATBELTS',
  USB_PORTS = 'USB_PORTS',
  WIFI = 'WIFI',
}

const AmenityMap: Record<ETPAmenity, Amenity> = {
  [ETPAmenity.ARMREST]: Amenity.ARMREST,
  [ETPAmenity.CUSTOM]: Amenity.CUSTOM,
  [ETPAmenity.DVD_PLAYER]: Amenity.DVD_PLAYER,
  [ETPAmenity.FOOTRESTS]: Amenity.FOOTRESTS,
  [ETPAmenity.HEADRESTS]: Amenity.HEADRESTS,
  [ETPAmenity.KNEELING_COACH]: Amenity.KNEELING_COACH,
  [ETPAmenity.OUTLETS]: Amenity.OUTLETS,
  [ETPAmenity.RECLINING_SEATS]: Amenity.RECLINING_SEATS,
  [ETPAmenity.RESTROOM]: Amenity.RESTROOM,
  [ETPAmenity.SEATBELTS]: Amenity.SEATBELTS,
  [ETPAmenity.USB_PORTS]: Amenity.USB_PORTS,
  [ETPAmenity.WIFI]: Amenity.WIFI,
}

const ETPAmenityMap: Record<Amenity, ETPAmenity> = {
  [Amenity.ARMREST]: ETPAmenity.ARMREST,
  [Amenity.CUSTOM]: ETPAmenity.CUSTOM,
  [Amenity.DVD_PLAYER]: ETPAmenity.DVD_PLAYER,
  [Amenity.FOOTRESTS]: ETPAmenity.FOOTRESTS,
  [Amenity.HEADRESTS]: ETPAmenity.HEADRESTS,
  [Amenity.KNEELING_COACH]: ETPAmenity.KNEELING_COACH,
  [Amenity.OUTLETS]: ETPAmenity.OUTLETS,
  [Amenity.RECLINING_SEATS]: ETPAmenity.RECLINING_SEATS,
  [Amenity.RESTROOM]: ETPAmenity.RESTROOM,
  [Amenity.SEATBELTS]: ETPAmenity.SEATBELTS,
  [Amenity.USB_PORTS]: ETPAmenity.USB_PORTS,
  [Amenity.WIFI]: ETPAmenity.WIFI,
}

export const convertETPAmenityToAmenity = (etpAmenity: ETPAmenity): Amenity => {
  return AmenityMap[etpAmenity]
}

export const convertAmenityToETPAmenity = (amenity: Amenity): ETPAmenity => {
  return ETPAmenityMap[amenity]
}

export interface EmbeddedTripPlannerSettings {
  _id: string
  _domain: string
  _apiKey: string
  _type: string
  _hideInstantQuote: boolean
  _requestToBook: boolean
  _displayedAmenities: ETPAmenity[]
}
