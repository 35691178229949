import { styled } from '@mui/system'

interface StyledDivProps {
  label?: boolean
  hideErrorSpace?: boolean
  fullWidth?: boolean
}

export const StyledDiv = styled('div')<StyledDivProps>(
  ({ label, hideErrorSpace, fullWidth }) => ({
    paddingTop: label ? '8px' : 0,
    height: label && !hideErrorSpace ? '90px' : 'auto',
    width: fullWidth ? '100%' : undefined,
    display: 'flex',
    flexDirection: 'column',
  })
)
