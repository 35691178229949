import { styled } from '@mui/system'
// import { keyframes } from "@mui/styled-engine";

import { Theme } from '@mui/system'

interface Props {
  theme?: Theme
}

// const entering =
// keyframes
// `
// from {
//   bottom: -100px;
// }
// to {
//   bottom: 30px;
// }
// `;
const ConfirmationModalStyled = styled('div')((props: Props) => {
  const palette = props.theme?.palette
  return {
    '&': {
      position: 'fixed',
      left: '0',
      maxWidth: '801px',
      margin: 'auto',
      right: '0',
      background: palette?.black?.plus10,
      padding: '16px 24px',
      boxShadow: '0px 8px 24px rgba(34, 34, 34, 0.15)',
      borderRadius: '8px',
      fontWeight: '500',
      fontsize: '14px',
      lineHeight: '150%',
      letterSpacing: '0.04em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      bottom: '30px',
      // animation: `${entering} .3s ease`,
      '&.active': {},
      '.items-count-label': {
        color: palette?.black?.plus70,
        lineHeight: '21px',
        fontSize: '14px',
        marginBottom: '4px',
      },
      '.controls': {
        a: {
          fontWeight: '600',
          color: palette?.black?.plus100,
          marginRight: '20px',
          fontSize: '14px',
          textDecoration: 'none',
          lineHeight: 1,
          '&:hover, &:focus': {
            color: palette?.black?.plus70,
          },
          '&.danger': {
            color: palette?.red?.plus5,
            '&:hover, &:focus': {
              color: palette?.red?.main,
            },
          },
        },
      },
      '.controls-block': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        button: {
          borderColor: palette?.black?.plus100,
          background: palette?.black?.plus100,
          borderRadius: '4px',
        },
      },
    },
  }
})

export default ConfirmationModalStyled
