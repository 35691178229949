import { searchGroups } from '@busie/api'
import { useQuery } from 'react-query'
import { queryClient, STALE_TIMES, useAuthTokenWithAudience } from '../'

export const CUSTOMERS_QUERY_KEY = 'customers'

const groupsQueryKey = (query?: string) => [
  CUSTOMERS_QUERY_KEY,
  'groups',
  query,
]

export const invalidateCustomersQueries = () => {
  queryClient.invalidateQueries('customers')
}

export const invalidateGroupsQuery = (query: string) => {
  queryClient.invalidateQueries(groupsQueryKey(query))
}

export const useGroupsSearch = (
  authToken: string,
  query?: string,
  options?: { enabled?: boolean }
) => {
  const result = useQuery(
    groupsQueryKey(query),
    () =>
      searchGroups(authToken, {
        search: query,
        skip: 0,
        take: 100,
      }),
    {
      enabled: !!authToken && (options?.enabled ?? false),
      staleTime: STALE_TIMES.DEFAULT,
    }
  )
  return {
    ...result,
    invalidateQueryCallback: () => invalidateGroupsQuery(query || ''),
  }
}

export const useCustomersOrganizations = (
  { query }: { query?: string },
  { enabled = false }: { enabled?: boolean } = {}
) => {
  const authToken = useAuthTokenWithAudience('customers')
  return useGroupsSearch(authToken, query, { enabled })
}

export const useCustomersOrganization = (id: string) => {
  const { data: organizations } = useCustomersOrganizations(
    { query: undefined },
    { enabled: true }
  )

  return id ? organizations?.results?.find((org) => org.id === id) : undefined
}
