import React, { useEffect, useRef } from 'react'
import H from '@here/maps-api-for-javascript'

import ErrorBoundary from '../ErrorBoundary'

import { MapMarkerCoordinates, Props } from './map.types'
import { env } from '@busie/utils'
import palette from '../theme/palette'
import { getMapBounds, getMarkerIcons } from './helpers'

const Map: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const mapRef = useRef<HTMLDivElement>(null)
  const map = useRef<H.Map>()
  const platform = useRef<H.service.Platform>()
  const behavior = useRef<H.mapevents.Behavior>()

  const API_KEY = env('HERE_MAPS_API_KEY')

  useEffect(
    () => {
      // Check if the map object has already been created
      if (!map.current && API_KEY) {
        // Create a platform object with the API key
        platform.current = new H.service.Platform({ apikey: API_KEY })
        // Create a new Raster Tile service instance
        const rasterTileService = platform.current.getRasterTileService({
          queryParams: {
            style: 'explore.day',
            size: 512,
          },
        })
        // Creates a new instance of the H.service.rasterTile.Provider class
        // The class provides raster tiles for a given tile layer ID and pixel format
        const rasterTileProvider = new H.service.rasterTile.Provider(
          rasterTileService
        )
        // Create a new Tile layer with the Raster Tile provider
        const rasterTileLayer = new H.map.layer.TileLayer(rasterTileProvider)
        // Create a new map instance with the Tile layer, center and zoom level
        const newMap = new H.Map(
          mapRef.current as HTMLDivElement,
          rasterTileLayer,
          {
            pixelRatio: window.devicePixelRatio,
            center: props.center || { lat: 41.927, lng: -73.9974 },
            zoom: props.zoom || 14,
            padding: { top: 35, left: 40, bottom: 35, right: 40 },
          }
        )

        if (props.route) {
          const linestring = H.geo.LineString.fromLatLngArray(
            props.route.shapes
          )

          const routeline = new H.map.Polyline(linestring, {
            data: {},
            style: { strokeColor: palette.red.main, lineWidth: 2 },
          })
          newMap.addObjects([routeline])
        }

        const markerCoordinates: MapMarkerCoordinates = {
          lat: [],
          lng: [],
        }
        const markers: H.map.Object[] = []

        const markerIcons = getMarkerIcons()

        //  create markers and routes from coordiantes
        props.markers.forEach((point, index, arr) => {
          // select icon for marker
          let icon = markerIcons.trip
          if (index === 0) icon = markerIcons.start
          if (index === arr.length - 1) icon = markerIcons.end

          const lat = point.lat
          const lng = point.lng

          // collect coordinates to render bounds properly
          markerCoordinates.lat.push(lat)
          markerCoordinates.lng.push(lng)

          // collect markers
          const marker = new H.map.Marker({ lat, lng }, { data: [], icon })
          markers.push(marker)
        })
        // render markers
        newMap.addObjects(markers)

        if (markerCoordinates.lat.length && markerCoordinates.lng.length) {
          const rectCoordinates = getMapBounds(markerCoordinates)
          const mapBounds = new H.geo.Rect(
            rectCoordinates.top,
            rectCoordinates.left,
            rectCoordinates.bottom,
            rectCoordinates.right
          )
          newMap.getViewModel().setLookAtData({
            bounds: mapBounds,
          })
        }

        // Add panning and zooming behavior to the map
        behavior.current = new H.mapevents.Behavior(
          new H.mapevents.MapEvents(newMap)
        )

        // Set the map object to the reference
        map.current = newMap
      }

      if (behavior.current) {
        ;(props.interactive ?? true)
          ? behavior.current.enable()
          : behavior.current.disable()
      }
    },
    // Dependencies array
    [API_KEY, props]
  )

  if (!API_KEY) {
    return (
      <ErrorBoundary errorText="The Map is not properly configured">
        <div></div>
      </ErrorBoundary>
    )
  }

  return (
    <ErrorBoundary errorText="Something went wrong with map rendering">
      <div style={{ width: '100%', height: '500px' }} ref={mapRef} />
    </ErrorBoundary>
  )
}

export default Map
