import React from 'react'

import EditButton from './EditButton'
import PropertyString from '../PropertyString'

import FormContainer from '../FormContainer'

import TripForm from '../../store/tripForm'
import AmenitiesBlock from './AmenitiesBlock'

interface Props {
  onEdit: () => void
  tripForm: TripForm
}

const TripInformationBlock: React.FC<React.PropsWithChildren<Props>> = ({
  onEdit,
  tripForm,
}: Props) => {
  return (
    <FormContainer
      title="Trip Information"
      controls={
        <EditButton onClick={onEdit} aria-label="edit trip information" />
      }
    >
      <PropertyString name="Passengers amount">
        {tripForm.passengers.amount}
      </PropertyString>
      <PropertyString name="Accessible seating">
        {tripForm.passengers.accessibleSeating}
      </PropertyString>
      {tripForm.amenities.length ? (
        <PropertyString name="Amenities">
          <AmenitiesBlock />
        </PropertyString>
      ) : (
        ''
      )}
    </FormContainer>
  )
}

export default TripInformationBlock
