import React from 'react'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import TablePaginationStyled from './styled/TablePaginationStyled'

import palette from '../theme/palette'
import {
  ChevronPrev,
  ChevronNext,
  ChevronPageFirst,
  ChevronPageLast,
} from '../icons'
import IconButton from '../IconButton'

interface Props {
  pageNumber: number
  itemsTotal: number
  perPage: number
  nextOffset: number
  currentOffset: number
  onNextClick: () => void
  onPrevClick: () => void
  onToEndClick: () => void
  onToBeginningClick: () => void
  outlined?: boolean
}
const TablePagination: React.FC<React.PropsWithChildren<Props>> = ({
  pageNumber,
  itemsTotal,
  perPage,
  nextOffset,
  currentOffset,
  onNextClick,
  onPrevClick,
  onToEndClick,
  onToBeginningClick,
  outlined,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'))

  const pagesTotal = Math.ceil(itemsTotal / perPage)
  return (
    <TablePaginationStyled outlined={outlined}>
      <div className="controls">
        <IconButton
          type="button"
          disabled={pageNumber === 1}
          icon={<ChevronPageFirst color={palette?.black?.plus10} />}
          onClick={onToBeginningClick}
        />
        <IconButton
          type="button"
          disabled={pageNumber === 1}
          icon={<ChevronPrev color={palette?.black?.plus10} />}
          onClick={onPrevClick}
        />
        <span>
          Page {pageNumber} of {pagesTotal}
        </span>
        <IconButton
          type="button"
          disabled={pageNumber === pagesTotal}
          icon={<ChevronNext color={palette?.black?.plus10} />}
          onClick={onNextClick}
        />
        <IconButton
          type="button"
          disabled={pageNumber === pagesTotal}
          icon={<ChevronPageLast color={palette?.black?.plus10} />}
          onClick={onToEndClick}
        />
      </div>
      {!isMobile && (
        <div className="stats">
          <span>
            Showing {currentOffset + 1}-{nextOffset} of {itemsTotal} items
          </span>
        </div>
      )}
    </TablePaginationStyled>
  )
}

export default TablePagination
