import React from 'react'

import { Subtitle } from '@busie/ui-kit'

import FormContainerStyled from './styled/FormContainerStyled'
import { SxProps } from '@mui/system'

interface Props {
  title?: string
  children: React.ReactNode
  controls?: React.ReactNode
  fullWidth?: boolean
  sx?: SxProps
}

const FormContainer: React.FC<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  const { children, title, controls, fullWidth } = props
  return (
    <FormContainerStyled className={fullWidth ? 'fullWidth' : ''} sx={props.sx}>
      <div className="title-block">
        {props.title && <Subtitle className="title">{title}</Subtitle>}
        {props.controls && <div className="controls">{controls}</div>}
      </div>
      <div className="container-content">{children}</div>
    </FormContainerStyled>
  )
}

export default FormContainer
