import { useAuth0 } from '@auth0/auth0-react'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import React from 'react'

import Button from '../Button'
import FormContainer from '../FormContainer'
import LoadingBlock from '../LoadingBlock'
import DepartureBlock from './DepartureBlock'

import { createQuote, createQuoteAsGuest, CreateQuoteData } from '@busie/api'
import { useAmplitude } from '@busie/core'
import { Quote } from '@busie/utils'
import { notificationStore } from '../../../../Notifications/store'
import store from '../../store'

interface Props {
  children?: React.ReactNode
  tripsAuthToken: string
  quotesAuthToken: string
  guestAuthToken?: string
  embedded?: boolean
  goNext: () => void
}

const DeparturesForm: React.FC<React.PropsWithChildren<Props>> = observer(
  (props: Props) => {
    const form = store.departuresForm

    if (form.isLoading)
      return (
        <FormContainer title="">
          <LoadingBlock
            title="Please wait..."
            subtitle="Determining your instant quote"
          />
        </FormContainer>
      )

    const { user } = useAuth0()
    const { track } = useAmplitude()
    const isFormValid = computed(() => form.validateForm())

    const handlerSubmitForm = async () => {
      form.toggleLoading()
      try {
        const data = store.getTripFormData(user?.org_id)
        if (!data.legs.length) return
        await form.submitForm(
          data,
          {
            guestAuthToken: props.guestAuthToken,
            tripsAuthToken: props.tripsAuthToken,
          },
          props.embedded
        )
        track('trip created')

        const payload: CreateQuoteData = {
          ...store.departuresForm.createQuotePayload,
          customerGroupName: store.customerForm.customerGroup?.name ?? '',
          contactName: store.customerForm.customer?.name ?? '',
          contactEmail: store.customerForm.customer?.email ?? '',
        }

        let quote: Quote

        if (props.embedded)
          quote = await createQuoteAsGuest(props.guestAuthToken ?? '', payload)
        else quote = await createQuote(props.quotesAuthToken, payload)

        store.setQuote(quote)

        track('quote created')

        props.goNext()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        // notify the user that the session expired.
        // NOTE: this should only happen for the embedded form.
        // and should only occur after 30 minutes of inactivity.
        if (error.response && error.response.data?.statusCode === 401) {
          notificationStore.setErrorNotification(
            'Session Expired',
            'Your 30 minute session expired. Please refresh the page and try again.'
          )
        } else {
          notificationStore.setNotificationFromError(error)
        }
        track('trip creation failure')
      } finally {
        form.toggleLoading()
      }
    }
    return (
      <>
        <FormContainer title="Add Departure Dates">
          {form.departures.map((departure, i, array) => (
            <DepartureBlock
              key={i}
              index={i}
              departure={departure}
              prevDeparture={array[i - 1]}
            />
          ))}
        </FormContainer>
        <Button
          fullWidth
          disabled={!isFormValid.get()}
          onClick={handlerSubmitForm}
        >
          {store.quoteId ? 'Create Trip' : 'Review summary'}
        </Button>
      </>
    )
  }
)

export default DeparturesForm
