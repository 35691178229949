import React from 'react'

interface Props {
  color?: string
  size?: number
}
const OutletsIcon: React.FC<React.PropsWithChildren<Props>> = ({
  color = '#222',
  size = 56,
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 56 56"
      aria-label="outlets"
    >
      <rect width="32" height="32" x="12" y="12" stroke={color} rx="1"></rect>
      <circle cx="28" cy="28" r="12" stroke={color}></circle>
      <rect width="2" height="6" x="22" y="22" fill={color} rx="1"></rect>
      <path fill={color} d="M26 34a2 2 0 114 0 2 2 0 01-4 0z"></path>
      <rect width="2" height="6" x="32" y="22" fill={color} rx="1"></rect>
    </svg>
  )
}

export default OutletsIcon
