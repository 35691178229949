import React from 'react'
import { Box, CircularProgress } from '@mui/material'

const PageLoader: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
    }}
  >
    <CircularProgress />
  </Box>
)

export default PageLoader
