import React from 'react'

import Step from '@mui/material/Step'
import { StepIconProps } from '@mui/material/StepIcon'

import {
  StepperStyled,
  ColorlibStepIconRoot,
  ColorlibConnector,
  StepLabelStyled,
} from './StepperStyled'

const ColorlibStepIcon = (props: StepIconProps & { getIcon: IconResolver }) => {
  const { active, completed, getIcon } = props
  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }}>
      {getIcon(Number(props.icon), completed, active)}
    </ColorlibStepIconRoot>
  )
}

type IconResolver = (
  index: number,
  completed?: boolean,
  active?: boolean
) => React.ReactNode

interface Props {
  stepList: string[]
  step: number
  getIcon: IconResolver
}

const iconFactory = (getIcon: IconResolver) => (props: StepIconProps) => {
  return <ColorlibStepIcon {...props} getIcon={getIcon} />
}

const Stepper: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  return (
    <StepperStyled
      alternativeLabel
      activeStep={props.step}
      connector={<ColorlibConnector />}
      role="list"
    >
      {props.stepList.map((label, index) => {
        const isActive = index === props.step
        return (
          <Step
            key={label}
            className={isActive ? 'is-active' : ''}
            role="listitem"
          >
            <StepLabelStyled
              StepIconComponent={iconFactory(props.getIcon)}
              aria-label={`${label} ${isActive ? 'Current step' : ''}`}
            >
              {label}
            </StepLabelStyled>
          </Step>
        )
      })}
    </StepperStyled>
  )
}

export default Stepper
