import {
  EMBEDDED_ANALYTICS_SERVICE_URL,
  Endpoints,
  HttpMethod,
} from '../../constants'

import {
  EmbeddedAnalyticsDashboardRequest,
  EmbeddedAnalyticsResponse,
} from '../../dataTypes'
import { makeRequest } from '../../helpers/axios-request'

export const fetchAnalyticsEmbeddingUrl = async (
  authToken: string,
  data: EmbeddedAnalyticsDashboardRequest
): Promise<EmbeddedAnalyticsResponse> =>
  await makeRequest<
    EmbeddedAnalyticsDashboardRequest,
    EmbeddedAnalyticsResponse
  >({
    authToken,
    data,
    endpoint: Endpoints.EMBEDDED_ANALYTICS,
    baseUrl: EMBEDDED_ANALYTICS_SERVICE_URL,
    method: HttpMethod.GET,
  })
