import React from 'react'

interface Props {
  color?: string
  size?: number
}
const RestroomIcon: React.FC<React.PropsWithChildren<Props>> = ({
  color = '#222',
  size = 56,
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 56 56"
      aria-label="restroom"
    >
      <path stroke={color} strokeLinecap="round" d="M28 44V12"></path>
      <path
        fill={color}
        d="M36.5 18h8a2 2 0 012 2v11a1 1 0 11-2 0v-8a.5.5 0 00-1 0v21a1 1 0 01-1 1h-1a1 1 0 01-1-1V33.5a.5.5 0 00-1 0V44a1 1 0 01-1 1h-1a1 1 0 01-1-1V22.5a.5.5 0 00-1 0V31a1 1 0 11-2 0V20a2 2 0 012-2h1zM13 18h4.967a2 2 0 011.931 1.48l2.777 10.312a.959.959 0 11-1.857.482l-1.727-6.912a.478.478 0 10-.926.242l2.49 9.133A1 1 0 0119.692 34H18.5v10a1 1 0 01-1 1h-1a1 1 0 01-1-1v-9.5a.5.5 0 00-1 0V44a1 1 0 01-1 1h-1a1 1 0 01-1-1V34h-1.19a1 1 0 01-.966-1.263l2.491-9.133a.478.478 0 10-.926-.242l-1.727 6.912a.959.959 0 11-1.857-.482l2.776-10.312A2 2 0 0112.034 18H13z"
      ></path>
      <circle cx="40" cy="14" r="3" fill={color}></circle>
      <circle cx="15" cy="14" r="3" fill={color}></circle>
    </svg>
  )
}

export default RestroomIcon
