import React, { useState } from 'react'
import { InputAdornment } from '@mui/material'

import { TextFieldStyled } from './styled'
import { SearchIcon } from '../icons'
import palette from '../theme/palette'

interface Props {
  placeholder: string
  search: (q: string) => void
  fullWidth?: boolean
}

export const SearchInput: React.FC<React.PropsWithChildren<Props>> = ({
  placeholder,
  search,
  fullWidth,
}) => {
  const [queryInputValue, setQueryInputValue] = useState('')

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQueryInputValue(e.target.value)
    search(e.target.value)
  }

  return (
    <TextFieldStyled
      variant="outlined"
      placeholder={placeholder}
      value={queryInputValue}
      onChange={handleSearchInput}
      fullWidth={fullWidth}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color={palette?.black?.plus30} />
          </InputAdornment>
        ),
      }}
    />
  )
}
