export default function (phoneNumber: string): {
  areaCode: string
  countryCode: string
  phoneNumber: string
} {
  // determining where the country code ends based on the length of the phone number as a quick hack to fix the phone number formatting
  let endCountryCodeIndex = phoneNumber.length - 12 >= 0 ? 1 : 0
  if (phoneNumber.length - 12 > 0) {
    endCountryCodeIndex = 2
  }

  return {
    areaCode: phoneNumber.slice(
      endCountryCodeIndex + 1,
      endCountryCodeIndex + 4
    ),
    phoneNumber: phoneNumber.slice(endCountryCodeIndex + 4),
    countryCode: phoneNumber.slice(0, endCountryCodeIndex + 1),
  }
}
