import React from 'react'

import FormLabelStyled from './styled/FormLabelStyled'

interface Props {
  children?: React.ReactNode
}

const CustomFormLabel: React.FC<React.PropsWithChildren<Props>> = (props) => {
  return <FormLabelStyled>{props.children}</FormLabelStyled>
}

export default CustomFormLabel
