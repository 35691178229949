import { styled } from '@mui/system'

interface Props {
  color?: string
  weight?: number
  margin?: string
}

const H1 = styled('h1')<Props>((props) => {
  const palette = props.theme.palette
  return {
    '&': {
      color: props.color || palette.black.main,
      fontWeight: props.weight || '600',
      fontSize: '24px',
      lineHeight: '150%',
      margin: props.margin || '14px 0',
    },
  }
})

export default H1
