import { RouteForm } from './store.types'

export const validateForm = (routeForm: RouteForm): boolean => {
  if (routeForm.isRoundTrip && !routeForm.waypoints?.length) {
    // we should have at least 1 stop with round trip
    return false
  }

  const indexOfEmptyItem = [
    routeForm.start,
    routeForm.end,
    ...(routeForm.waypoints || []),
  ].findIndex((item) => {
    return item === null || (item && item.place_id === '')
  })

  return indexOfEmptyItem === -1
}
