import { HeaderItem } from '@busie/ui-kit'
import { Plan } from '@busie/utils'

import { useCallback } from 'react'
import { AppFeaturesType } from '../../../config/featureFlags'
import { RouteConfig } from '../types'

/**
 * Props for the useHeaderLinks hook
 */
interface UseHeaderLinksProps {
  /** Array of route configurations */
  routes: RouteConfig[]
  /** Feature flags object */
  flags: AppFeaturesType
  /** Optional subscription plan */
  plan?: Plan
}

/**
 * Hook that builds navigation header links from route configurations
 *
 * @param {UseHeaderLinksProps} props - Props containing routes, feature flags and plan
 * @param {RouteConfig[]} props.routes - Array of route configurations
 * @param {AppFeaturesType} props.flags - Feature flags object
 * @param {Plan} [props.plan] - Optional subscription plan
 * @returns {() => HeaderItem[]} Callback function that returns array of header items
 */
export const useHeaderLinks = ({
  routes,
}: UseHeaderLinksProps): (() => HeaderItem[]) => {
  return useCallback(() => {
    const groups = new Map<string, HeaderItem & { groupOrder?: number }>()
    const standalone: (HeaderItem & { order?: number })[] = []

    routes.forEach((route) => {
      if (!route.headerItem) return

      const headerItem: HeaderItem & { order?: number } = {
        name: route.headerItem.name,
        to: route.path,
        order: route.headerItem.order,
      }

      if (route.headerItem.group) {
        const group = groups.get(route.headerItem.group) || {
          name: route.headerItem.group,
          to: '',
          children: [],
          groupOrder: route.headerItem.groupOrder,
        }
        group.children = [
          ...(group.children || []),
          { name: headerItem.name, path: route.path, order: headerItem.order },
        ].sort((a, b) => (a.order || 0) - (b.order || 0))
        groups.set(route.headerItem.group, group)
      } else {
        standalone.push(headerItem)
      }
    })

    return [
      ...Array.from(groups.values()).sort(
        (a, b) => (a.groupOrder || 0) - (b.groupOrder || 0)
      ),
      ...standalone.sort((a, b) => (a.order || 0) - (b.order || 0)),
    ]
  }, [])
}
