import { Location } from '@busie/utils'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formatLocation = (rawLocation: any): Location => {
  if (rawLocation.id) {
    return rawLocation as Location
  }
  return {
    id: rawLocation._id,
    city: rawLocation._city,
    latitude: rawLocation._latitude,
    longitude: rawLocation._longitude,
    address: rawLocation._address,
    street: rawLocation._street,
    state: rawLocation._state,
    country: rawLocation._country,
    postalCode: rawLocation._postalCode,
    createdAt: rawLocation._createdAt,
    createdBy: rawLocation._createdBy,
  } as Location
}
