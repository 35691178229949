import { styled } from '@mui/system'

// import { Theme } from "@mui/system";

import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'

// interface Props {
//   theme?: Theme;
// }

const DateRangePickerStyled = styled(DateRangePicker)(() => {
  // const palette = props.theme?.palette;
  return {
    display: 'inline-flex',
    width: '100%',
  }
})

export default DateRangePickerStyled
