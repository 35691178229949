import { FC } from 'react'
import { DividerStyled } from './styled/DividerStyled'

interface Props {
  margin?: number
  width?: string
}
export const Divider: FC<React.PropsWithChildren<Props>> = (props) => (
  <DividerStyled {...props} />
)
