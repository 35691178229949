import React from 'react'

import PropertyStringStyled from './styled/PropertyStringStyled'

interface Props {
  name: string | React.ReactNode
  children: React.ReactNode
  size?: 'large' | 'short'
  justify?:
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-evenly'
    | 'center'
  margin?: string
}

const PropertyString: React.FC<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  return (
    <PropertyStringStyled
      justify={props.justify || 'flex-start'}
      margin={props.margin}
      size={props.size}
    >
      <div className="name">{props.name}</div>
      <div className="value">{props.children}</div>
    </PropertyStringStyled>
  )
}

export default PropertyString
