import React from 'react'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useAuth0 } from '@auth0/auth0-react'

import FormContainer from '../FormContainer'
import Button from '../Button'

import Amenities from './AmenitiesBlock'
import {
  AmountField,
  FlexContainer,
  InfoIcon,
  Text,
  TextArea,
  ToggleSwitch,
  TooltipIcon,
  palette,
} from '@busie/ui-kit'

import store from '../../store'
import { Tooltip } from '@mui/material'

interface Props {
  children?: React.ReactNode
  guestAuthToken?: string
  tripsAuthToken?: string
  embedded?: boolean
  embeddedFormOrgId?: string
  goNext: () => void
}

const TripForm: React.FC<React.PropsWithChildren<Props>> = observer(
  (props: Props) => {
    const { user } = useAuth0()

    const tripForm = store.tripForm

    const validateForm = computed(
      () =>
        tripForm.passengers &&
        tripForm.passengers.amount &&
        tripForm.passengers.amount > 0 &&
        (tripForm.passengers.accessibleSeating || 0) <=
          tripForm.passengers.amount
    )
    const passengersForm = tripForm.passengers
    const updateTrip = async (): Promise<void> => {
      const data = store.getTripFormData(
        props.embedded ? props.embeddedFormOrgId : user?.org_id
      )
      if (!data.legs.length) return
      await store.departuresForm.submitForm(
        data,
        {
          tripsAuthToken: props.tripsAuthToken,
          guestAuthToken: props.guestAuthToken,
        },
        props.embedded
      )
    }
    const submitForm = async () => {
      if (tripForm.editMode) {
        await updateTrip()
        tripForm.setEditMode(false)
      }
      await props.goNext()
    }

    return (
      <>
        <FormContainer title="Trip details">
          <FlexContainer direction="column" rowGap={1} pb={2}>
            <Text weight={600}>
              This trip will {tripForm.spab ? '' : 'not'} be transporting K-12
              School Pupils
            </Text>
            <FlexContainer direction="row" columnGap={2} align="center">
              <ToggleSwitch
                value={tripForm.spab}
                onChange={() => tripForm.setSpab(!tripForm.spab)}
                aria-label="K-12 School Students Onboard"
              />
              <Tooltip
                title="In certain jurisdictions, the transportation of K-12 School Pupils may require special certifications for drivers and vehicles. Please consult your local regulations for more information."
                placement="top"
              >
                <TooltipIcon>
                  <InfoIcon size={24} color={palette.black.plus10} />
                </TooltipIcon>
              </Tooltip>
            </FlexContainer>
          </FlexContainer>
          <AmountField
            id="Passengers amount"
            value={passengersForm.amount}
            label="Passengers amount"
            minValue={tripForm.passengers.accessibleSeating || 1}
            onChange={(value) => {
              if (Number.isInteger(value)) {
                tripForm.updatePassengersAmount('amount', Number(value))
              } else {
                tripForm.updatePassengersAmount('amount')
              }
            }}
            errorMessage={
              passengersForm.amount === 0 ? "Amount can't be zero" : ''
            }
          />
          <AmountField
            id="Accessible seating"
            value={passengersForm.accessibleSeating}
            label="Accessible seating"
            minValue={0}
            maxValue={tripForm.passengers.amount}
            onChange={(value) => {
              if (Number.isInteger(value)) {
                tripForm.updatePassengersAmount(
                  'accessibleSeating',
                  Number(value)
                )
              } else {
                tripForm.updatePassengersAmount('accessibleSeating')
              }
            }}
          />
          <TextArea
            id="Additional information"
            label="Additional information"
            value={tripForm.additionalInfo}
            onChange={(e) => tripForm.setAdditionalInfo(e.target.value)}
            sx={{
              width: '100%',
              resize: 'none',
              height: '200px',
              overflowY: 'scroll',
              overflowX: 'hidden',
            }}
          />
        </FormContainer>
        <FormContainer title="Amenities">
          <Amenities embedded={props.embedded} />
        </FormContainer>
        <Button
          fullWidth
          disabled={!validateForm.get()}
          async
          onClick={submitForm}
          aria-label="Continue to next step of this form"
        >
          Continue
        </Button>
      </>
    )
  }
)

export default TripForm
