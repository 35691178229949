import React from 'react'
import { observer } from 'mobx-react-lite'

import { LegBlockStyled } from './styled'

import { TripLeg } from '@busie/utils'
import { LegBlockHeader } from './LegBlockHeader'
import { LegBlockContent } from './LegBlockContent/LegBlockContent'

interface Props {
  legNumber: number
  distanceUnit: 'mi' | 'm'
  leg: TripLeg
  isDeadLeg: boolean
  dispatchLocationName: string
  showPriceData?: boolean
  distance?: number
  isFirstLeg?: boolean
  isLastLeg?: boolean
}

export const LegBlock: React.FC<React.PropsWithChildren<Props>> = observer(
  ({
    leg,
    legNumber,
    isDeadLeg,
    distanceUnit,
    dispatchLocationName,
    showPriceData = true,
    isLastLeg = false,
    isFirstLeg = false,
  }: Props) => {
    const headerProps = {
      leg,
      distanceUnit,
      isDeadLeg,
      legNumber,
      showPriceData,
      dispatchLocationName,
    }
    const contentProps = {
      leg,
      dispatchLocationName,
      isFirstLeg,
      isLastLeg,
      isDeadLeg,
    }
    return (
      <LegBlockStyled>
        <LegBlockHeader {...headerProps} />
        <LegBlockContent {...contentProps} />
      </LegBlockStyled>
    )
  }
)

export default LegBlock
