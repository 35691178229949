import React from 'react'

interface Props {
  color?: string
  size?: string
}

export const DoneIcon: React.FC<React.PropsWithChildren<Props>> = ({
  color = 'currentColor',
  size = '24',
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29 50.75V50.75C16.9867 50.75 7.25 41.0133 7.25 29V29C7.25 16.9867 16.9867 7.25 29 7.25V7.25C41.0133 7.25 50.75 16.9867 50.75 29V29C50.75 41.0133 41.0133 50.75 29 50.75Z"
        stroke={color}
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M38.6666 24.1667L26.5833 36.25L19.3333 29"
        stroke={color}
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
