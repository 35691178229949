import React from 'react'

export const VehicleGroupIcon: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <svg
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.74537 5.08696C7.7145 5.14871 7.73953 5.2238 7.80128 5.25467C7.86302 5.28554 7.93811 5.26051 7.96898 5.19877L7.74537 5.08696ZM8.67969 4.58929H8.33634V4.83929H8.67969V4.58929ZM7.84121 4.8953L7.74537 5.08696L7.96898 5.19877L8.06481 5.0071L7.84121 4.8953ZM8.33634 4.58929C8.12666 4.58929 7.93498 4.70776 7.84121 4.8953L8.06481 5.0071C8.11624 4.90426 8.22135 4.83929 8.33634 4.83929V4.58929Z"
        fill="#222222"
      />
      <rect
        x="7.64282"
        y="8.35718"
        width="0.857143"
        height="0.25"
        rx="0.125"
        fill="#222222"
      />
      <path
        d="M10.3474 5.25322C10.3951 5.06243 10.5665 4.92859 10.7632 4.92859H18.3571V6.64287H10.5489C10.2701 6.64287 10.0655 6.38085 10.1331 6.11036L10.3474 5.25322Z"
        stroke="#FF0018"
        stroke-width="0.25"
      />
      <path
        d="M9.19639 9.21426H8.49997C8.02658 9.21426 7.64282 8.8305 7.64282 8.35712V7.64561C7.64282 7.19577 7.73134 6.75034 7.90334 6.33468L8.27133 5.44535C8.66913 4.48402 9.607 3.85712 10.6474 3.85712H17.5C17.9734 3.85712 18.3571 4.24087 18.3571 4.71426V8.35712C18.3571 8.8305 17.9734 9.21426 17.5 9.21426H17.0178M10.8303 9.21426H12.8928H15.3839"
        stroke="#FF0018"
        stroke-width="0.25"
      />
      <path
        d="M8.49997 4.92859H9.43886C9.72145 4.92859 9.92668 5.19729 9.85233 5.46992L9.44324 6.96992C9.39239 7.15638 9.22303 7.28573 9.02977 7.28573H7.64282"
        stroke="#FF0018"
        stroke-width="0.25"
      />
      <circle
        cx="9.99996"
        cy="9.00003"
        r="0.857143"
        stroke="#222222"
        stroke-width="0.25"
      />
      <circle
        cx="16.2143"
        cy="9.00003"
        r="0.857143"
        stroke="#222222"
        stroke-width="0.25"
      />
      <path
        d="M17.5713 11.5715C17.5713 11.3348 17.7632 11.1429 17.9999 11.1429H18.6427C18.8794 11.1429 19.0713 11.3348 19.0713 11.5715V12.2143C19.0713 12.451 18.8794 12.6429 18.6427 12.6429H17.9999C17.7632 12.6429 17.5713 12.451 17.5713 12.2143V11.5715Z"
        stroke="#222222"
        stroke-width="0.25"
      />
      <path
        d="M19.5 11.5715C19.5 11.3348 19.6919 11.1429 19.9286 11.1429H22.391C22.5824 11.1429 22.7505 11.2697 22.8031 11.4537L22.9868 12.0966C23.065 12.3704 22.8594 12.6429 22.5747 12.6429H19.9286C19.6919 12.6429 19.5 12.451 19.5 12.2143V11.5715Z"
        stroke="#222222"
        stroke-width="0.25"
      />
      <path
        d="M15.9432 11.3224C16.0237 11.2097 16.1536 11.1429 16.292 11.1429H16.7143C16.951 11.1429 17.1429 11.3348 17.1429 11.5715V12.2143C17.1429 12.451 16.951 12.6429 16.7143 12.6429H15.8328C15.4842 12.6429 15.2814 12.2489 15.4841 11.9652L15.9432 11.3224Z"
        stroke="#222222"
        stroke-width="0.25"
      />
      <rect
        x="12.6428"
        y="13.5"
        width="0.857143"
        height="0.25"
        rx="0.125"
        fill="#222222"
      />
      <rect
        x="12.6428"
        y="14.1429"
        width="0.857143"
        height="0.25"
        rx="0.125"
        fill="#222222"
      />
      <path
        d="M14.1964 15H13.5C13.0266 15 12.6428 14.6163 12.6428 14.1429V13.5172C12.6428 13.0804 12.9712 12.7135 13.4053 12.6653L14.1882 12.5783C14.4319 12.5512 14.6523 12.421 14.7938 12.2207L15.601 11.0771C15.7616 10.8496 16.0227 10.7143 16.3012 10.7143H17.5714H18.6428H22.2908C22.6693 10.7143 23.003 10.9626 23.1118 11.3251L23.5352 12.7367C23.5592 12.8166 23.5714 12.8995 23.5714 12.983V14.1429C23.5714 14.6163 23.1876 15 22.7143 15H21.8035M16.0446 15H17.8928H19.9553"
        stroke="#222222"
        stroke-width="0.25"
      />
      <circle
        cx="15.1071"
        cy="14.8929"
        r="0.964286"
        stroke="#222222"
        stroke-width="0.25"
      />
      <circle
        cx="20.893"
        cy="14.8929"
        r="0.964286"
        stroke="#222222"
        stroke-width="0.25"
      />
      <rect
        x="0.428711"
        y="14.1429"
        width="0.857143"
        height="0.25"
        rx="0.125"
        fill="#222222"
      />
      <path
        d="M6.21436 12.2143C6.21436 11.9776 6.40623 11.7857 6.64293 11.7857H6.85721C7.09391 11.7857 7.28578 11.9776 7.28578 12.2143V15.2143H6.21436V12.2143Z"
        stroke="#FF0018"
        stroke-width="0.25"
      />
      <path
        d="M2.57157 15.2143H7.92871M1.28585 15.2143H0.857282C0.620589 15.2143 0.428711 15.0224 0.428711 14.7857V14.1025C0.428711 13.9058 0.562553 13.7344 0.753339 13.6867L1.62657 13.4684C1.82176 13.4196 1.99355 13.3037 2.11189 13.141L2.52913 12.5673C2.69043 12.3455 2.9481 12.2143 3.22233 12.2143H4.28585C4.4042 12.2143 4.50014 12.1184 4.50014 12V10.9286C4.50014 10.8102 4.59608 10.7143 4.71443 10.7143H11.143C11.3797 10.7143 11.5716 10.9062 11.5716 11.1429V14.7857C11.5716 15.0224 11.3797 15.2143 11.143 15.2143H9.10728"
        stroke="#FF0018"
        stroke-width="0.25"
      />
      <rect
        x="4.92871"
        y="11.7857"
        width="0.857143"
        height="1.5"
        rx="0.428571"
        stroke="#FF0018"
        stroke-width="0.25"
      />
      <path
        d="M2.87519 12.851C2.95264 12.7219 3.09215 12.6429 3.24269 12.6429H3.64289C3.87959 12.6429 4.07146 12.8348 4.07146 13.0715V13.2857C4.07146 13.5224 3.87959 13.7143 3.64289 13.7143H3.11412C2.78099 13.7143 2.57523 13.3509 2.74662 13.0652L2.87519 12.851Z"
        stroke="#FF0018"
        stroke-width="0.25"
      />
      <path
        d="M3.30366 10.9224C3.38111 10.7933 3.52061 10.7143 3.67115 10.7143H4.49993V11.7857H3.16411C2.99755 11.7857 2.89467 11.604 2.98037 11.4612L3.30366 10.9224Z"
        stroke="#FF0018"
        stroke-width="0.25"
      />
      <rect
        x="7.71436"
        y="11.7857"
        width="0.857143"
        height="1.5"
        rx="0.428571"
        stroke="#FF0018"
        stroke-width="0.25"
      />
      <rect
        x="9"
        y="11.7857"
        width="0.857143"
        height="1.5"
        rx="0.428571"
        stroke="#FF0018"
        stroke-width="0.25"
      />
      <rect
        x="10.2856"
        y="11.7857"
        width="0.857143"
        height="1.5"
        rx="0.428571"
        stroke="#FF0018"
        stroke-width="0.25"
      />
      <circle
        cx="1.9285"
        cy="15.2143"
        r="0.642857"
        stroke="#222222"
        stroke-width="0.25"
      />
      <circle
        cx="8.57157"
        cy="15.2143"
        r="0.642857"
        stroke="#222222"
        stroke-width="0.25"
      />
      <path
        d="M4.5 12V15.2143"
        stroke="#FF0018"
        stroke-width="0.25"
        stroke-linecap="round"
      />
    </svg>
  )
}
