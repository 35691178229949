import React from 'react'
import { observer } from 'mobx-react-lite'

import { Text } from '../../typography'
import { Button, FlexContainer, palette, Popup } from '../../../index'

type Props = React.PropsWithChildren<{
  title: string
  isPopupOpened: boolean
  onClose: () => void
  onConfirm: () => void
  confirmText?: string
  loading?: boolean
}>

const WarningPopup: React.FC<React.PropsWithChildren<Props>> = observer(
  ({
    isPopupOpened,
    onClose,
    title,
    onConfirm,
    confirmText,
    children,
    loading,
  }: Props) => {
    return (
      <Popup title={title} isPopupOpened={isPopupOpened} onClose={onClose}>
        <FlexContainer px={5} pb={5} rowGap={3} direction="column">
          <Text weight={500} color={palette.black.plus10}>
            {children}
          </Text>
          <FlexContainer justify="flex-end" columnGap={2} fullWidth>
            <Button typestyle="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              typestyle="accent"
              async
              onClick={onConfirm}
              loading={loading}
            >
              {confirmText || 'Confirm'}
            </Button>
          </FlexContainer>
        </FlexContainer>
      </Popup>
    )
  }
)

export default WarningPopup
