import { fetchVehicle } from '@busie/api'
import { Trip, TripLeg, Vehicle } from '@busie/utils'
import { useEffect, useState } from 'react'
import { useAuthTokenWithAudience } from './auth/audienceTokens'

export const useTripVehicles = (trip: Pick<Trip, '_vehicleIds'>): Vehicle[] => {
  const [vehicles, setVehicles] = useState<Vehicle[]>([])
  const authToken = useAuthTokenWithAudience('vehicles')

  useEffect(() => {
    const getVehicles = async (): Promise<void> => {
      if (!authToken || !trip) {
        return setVehicles([])
      }

      const vehicles = await Promise.all(
        trip._vehicleIds.map((id) => fetchVehicle(authToken, id))
      )

      setVehicles(vehicles)
    }

    getVehicles()

    return () => setVehicles([])
  }, [authToken, trip])

  return vehicles
}

export const useTripLegVehicles = (leg: TripLeg): Vehicle[] => {
  const [vehicles, setVehicles] = useState<Vehicle[]>([])
  const authToken = useAuthTokenWithAudience('vehicles')

  useEffect(() => {
    const getVehicles = async (): Promise<void> => {
      if (!authToken || !leg) {
        return setVehicles([])
      }

      const vehicles = await Promise.all(
        leg._legPrice.map(({ vehicleId }) => fetchVehicle(authToken, vehicleId))
      )

      setVehicles(vehicles)
    }

    getVehicles()

    return () => setVehicles([])
  }, [authToken, leg])

  return vehicles
}
