import { env } from '@busie/utils'
import { useAuthToken } from '../use-auth-token'

const defaultAuthTokenConf = {
  domain: env('BUSIE_AUTH_DOMAIN'),
  clientId: env('BUSIE_CLIENT_ID'),
  redirect_uri: window.location.origin,
}

const audienceUrls = {
  customers: 'https://customers.getbusie.com',
  dispatchLocations: 'https://dispatch-locations.getbusie.com',
  payments: 'https://payments.getbusie.com',
  plans: 'https://plans.getbusie.com',
  profile: 'https://profile.getbusie.com',
  quotesAndBookings: 'https://quotes-and-bookings.getbusie.com',
  rates: 'https://rates.getbusie.com',
  vehicles: 'https://vehicles.getbusie.com',
  tripPlanner: 'https://trip-planner.getbusie.com',
  pathfinder: 'https://pathfinder.getbusie.com',
  trips: 'https://trips.getbusie.com',
  drivers: 'https://drivers.getbusie.com',
  chef: 'https://chef.getbusie.com',
  embeds: 'https://ea-embed-retriever.getbusie.com',
}

type Audience = keyof typeof audienceUrls

export const useAuthTokenWithAudience = (audience: Audience): string => {
  return useAuthToken({
    ...defaultAuthTokenConf,
    audience: audienceUrls[audience],
  })
}
