import React from 'react'
import { observer } from 'mobx-react-lite'

import store from '../../store'

import AmenitiesBlockStyled from './styled/AmenitiesBlockStyled'
import { AmenityIcon } from '@busie/core'
import { AmenityValues } from '@busie/utils'

const AmenitiesBlock: React.FC<React.PropsWithChildren<unknown>> = observer(
  () => {
    const tripForm = store.tripForm
    const selectedAmenitiesIcons = AmenityValues.filter((amen) => {
      return tripForm.amenities.includes(amen)
    })
    return (
      <AmenitiesBlockStyled>
        {selectedAmenitiesIcons.map((amen) => {
          return <AmenityIcon key={amen} type={amen} />
        })}
      </AmenitiesBlockStyled>
    )
  }
)

export default AmenitiesBlock
