import { RatesFormData } from '../dataTypes/rates.data-type'
import {
  Ingredient,
  IngredientListType,
  IngredientType,
  dollarsToCents,
} from '@busie/utils'

export const isRateTypeFilled = (formData: RatesFormData): boolean => {
  let result = false
  const value = formData.ruleTypeValue[formData.ruleType]
  if (value !== '' && value !== null) result = true
  return result
}

export const isIngredientsFilled = (formData: RatesFormData): boolean => {
  let result = true
  const checkedIngredientGroups: Ingredient[] = getCreatedIngredients(formData)

  // if no ingredients selected
  if (checkedIngredientGroups.length === 0) {
    return false
  }
  // find checked Ingredient with empty value
  const hasFieldsWithNoValues = checkedIngredientGroups.find(
    ({ name, value }) => {
      return name === '' || value === ''
    }
  )

  // if found, return false
  if (hasFieldsWithNoValues) {
    result = false
  }

  return result
}

export const getCreatedIngredients = (
  formData: RatesFormData
): Ingredient[] => {
  const checkedIngredientGroups: Ingredient[] = []

  // get all (both) lists of ingredients
  const ingredientLists = Object.keys(
    formData.ingredientList
  ) as IngredientListType[]

  // push add ingredients in checked groups to checkedIngredientGroups
  ingredientLists.forEach((item: IngredientListType) => {
    formData.ingredientList[item].map((item) => {
      return (
        item.checked &&
        item.ingredients.forEach((ingredient) => {
          const serializedIngredient = {
            ...ingredient,
            type: item.type as IngredientType,
            value: dollarsToCents(Number(ingredient.value)),
          }
          checkedIngredientGroups.push(serializedIngredient)
        })
      )
    })
  })
  return checkedIngredientGroups
}
