import { getDateTimeISO } from '@busie/utils'
import { CreateTripLeg, toHours } from '@busie/api'
import { RouteStopDeparture } from './departuresForm/store.types'
import DeparturesForm from './departuresForm'
import { getArrivalDateTime } from './departuresForm/helpers'

export const createLeg = (
  estimateTravelTime: number,
  departure: RouteStopDeparture,
  arrival: RouteStopDeparture,
  meters: number,
  hours: number
): CreateTripLeg => {
  return {
    startLocation: {
      latitude: departure.location?.lat(),
      longitude: departure.location?.lng(),
      address: departure.location?.address,
    },
    departureDatetime: departure.date
      ? getDateTimeISO(departure.date, departure.time)
      : departure.minDateTime?.toISOString() || new Date().toISOString(),
    destinationLocation: {
      latitude: arrival.location?.lat(),
      longitude: arrival.location?.lng(),
      address: arrival.location?.address,
    },
    arrivalDatetime:
      (departure.date &&
        getArrivalDateTime(
          departure.date,
          departure.time,
          estimateTravelTime
        ).toISOString()) ||
      new Date().toISOString(),
    meters,
    hours,
  }
}

export const getTripLegs = (departuresForm: DeparturesForm) => {
  const legs: CreateTripLeg[] = []

  const departures = departuresForm.departures
  const routeLegs = departuresForm.route?.legs || []

  departures.forEach((departure, index, array): void => {
    if (!array[index + 1]) return
    const leg = createLeg(
      routeLegs[index].estimateTravelTime || 0,
      departure,
      array[index + 1],
      routeLegs[index].distance,
      toHours(routeLegs[index].estimateTravelTime)
    )
    legs.push(leg)
  })
  return legs
}
