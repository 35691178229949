import axios, { Method } from 'axios'

interface RequestOptions<TData> {
  url: string
  headers: Record<string, string>
  data?: TData
  params?: TData
}

interface MakeRequestOptions<TData> {
  authToken: string
  data?: TData
  endpoint: string
  baseUrl: string
  method?: Method
}
interface CreateRequestOptions<TData> {
  authToken: string
  data?: TData
  endpoint: string
  baseUrl: string
  method?: Method
}

const request = async <TData, TResponse>(
  method: Method,
  { url, headers, data, params }: RequestOptions<TData>
): Promise<TResponse> => {
  try {
    const response = await axios({
      method,
      url,
      headers,
      data,
      params,
    })
    return response.data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(
        `Request failed: ${error.response?.data?.message || error.message}`
      )
    }
    throw new Error(`Unexpected error occurred: ${error}`)
  }
}

export const createRequest = async <
  TData extends object | undefined,
  TResponse,
>(
  options: CreateRequestOptions<TData>
): Promise<TResponse> => {
  const { authToken, data, endpoint, baseUrl, method = 'GET' } = options
  const headers = {
    Authorization: `Bearer ${authToken}`,
  }

  const requestUrl = `${baseUrl}/${endpoint}/`

  return request<TData, TResponse>(method, {
    url: requestUrl,
    headers,
    data,
    params: data,
  })
}

export const makeRequest = <
  TData extends object | undefined,
  TResponse = unknown,
>(
  requestOptions: MakeRequestOptions<TData>
): Promise<TResponse> => {
  const { authToken, data, endpoint, baseUrl, method = 'GET' } = requestOptions

  if (method !== 'GET' && method !== 'DELETE' && !data) {
    throw new Error(`Data is required for ${method} requests`)
  }

  return createRequest<TData, TResponse>({
    authToken,
    data,
    endpoint,
    baseUrl,
    method,
  })
}
