import { styled } from '@mui/system'
import MatDrawer, { drawerClasses } from '@mui/material/Drawer'

import { Theme } from '@mui/system'

interface Props {
  theme?: Theme
  width?: number
}

const Drawer = styled(MatDrawer)<Props>((props) => {
  const palette = props.theme?.palette
  return {
    [`.${drawerClasses.paper}`]: {
      width: '100%',
      maxWidth: `${props.width || 650}px`,
      background: palette?.black?.plus100,
    },
  }
})

export default Drawer
