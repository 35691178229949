import React, { forwardRef } from 'react'
import InputAdornment from '@mui/material/InputAdornment'

import { InputBaseProps } from '@mui/material/InputBase'

import InputLabel from '../InputLabel'

import TextInputStyled from './styled/TextInputStyled'

import { createHtmlAttrId } from '../helpers'
import { ErrorMessage } from '../typography'
import palette from '../theme/palette'

export type TextInputEvent = React.ChangeEvent<HTMLInputElement>

export interface TextInputProps extends InputBaseProps {
  label?: string
  fullWidth?: boolean
  validated?: boolean
  errorMessage?: string
  darkBackground?: boolean
  hideErrorSpace?: boolean
}

export const TextInput: React.FC<React.PropsWithChildren<TextInputProps>> =
  forwardRef(
    (
      { label, errorMessage, endAdornment, darkBackground = false, ...props },
      ref
    ) => {
      const id =
        props.id || `input-text-${createHtmlAttrId(label || props.placeholder)}`

      const containerStyles = {
        paddingTop: label ? '8px' : 0,
        width: props.fullWidth ? '100%' : 'auto',
        height: label && !props.hideErrorSpace ? '90px' : 'auto',
      }

      return (
        <div
          aria-live="polite"
          aria-relevant="additions removals"
          style={containerStyles}
        >
          {label && (
            <InputLabel required={props.required} htmlFor={id}>
              {label}
            </InputLabel>
          )}
          <TextInputStyled
            inputRef={ref}
            {...props}
            error={!!errorMessage}
            id={id}
            notched={false}
            size="small"
            endAdornment={
              endAdornment && (
                <InputAdornment position="end">{endAdornment}</InputAdornment>
              )
            }
            sx={{
              background: darkBackground
                ? palette.black.plus85
                : palette.black.plus100,
              ...props.sx,
            }}
          />
          {errorMessage && errorMessage.length > 0 && (
            <ErrorMessage>{errorMessage}</ErrorMessage>
          )}
        </div>
      )
    }
  )
