import { styled } from '@mui/system'
import { palette } from '@busie/ui-kit'

const ItineraryReminderBlockStyled = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '13.33px 14.33px',
    columnGap: '9.33px',
    borderRadius: '4px',
    backgroundColor: palette.supernova.plus40 || '#F1F1F1',
    color: palette.black.plus10 || '#00000',
  }
})

export default ItineraryReminderBlockStyled
