import type { AppFeaturesType } from '../config/featureFlags'

export const isFeatureEnabled = (
  flags: AppFeaturesType,
  flag?: keyof AppFeaturesType
): boolean => {
  if (!flag) return true
  const isEnabled = flags[flag]
  return isEnabled
}
