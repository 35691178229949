import React from 'react'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'

import ButtonComponent from '../Button'
import {
  TextInput,
  Button,
  Checkbox,
  PlaceField,
  AddIcon,
  ToggleSwitch,
  H3,
  InfoIcon,
} from '@busie/ui-kit'

import ContentBlock from './styled/ContentBlock'
import LastStopBlock from './styled/LastStopBlock'
import ControlsStyled from './styled/ControlsStyled'

import FormContainer from '../FormContainer'
import LoadingBlock from '../LoadingBlock'

import store from '../../store'
import { RouteStop } from '@busie/utils'
import { notificationStore } from '../../../../Notifications/store'
import { Stack, Tooltip } from '@mui/material'
import TooltipIconStyled from '../styled/TooltipIconStyled'
import ItineraryReminderBlock from './ItineraryReminderBlock'
import { useAmplitude } from '@busie/core'

interface Props {
  children?: React.ReactNode
  guestAuthToken?: string
  embedded?: boolean
  pathfinderAuthToken: string
  goNext: () => void
}

const RouteForm: React.FC<React.PropsWithChildren<Props>> = observer(
  (props: Props) => {
    const { track } = useAmplitude()
    const form = store.routeForm

    if (form.isLoading)
      return (
        <FormContainer title="Route details">
          <LoadingBlock />
        </FormContainer>
      )

    const validateForm = computed(() => form.validateForm())

    const submitRouteForm = async () => {
      await form.toggleRouteFormLoading()
      try {
        const routeResponse = await form.submitForm(
          {
            guestAuthToken: props.guestAuthToken,
            pathfinderAuthToken: props.pathfinderAuthToken,
          },
          props.embedded
        )

        await store.departuresForm.setRoute(routeResponse)
        await store.departuresForm.setDepartures(form)
        track('route created')
        await props.goNext()
        await form.setFormIsNotChanged()
      } catch (err) {
        notificationStore.setNotificationFromError(err)
        track('route creation failure')
      } finally {
        await form.toggleRouteFormLoading()
      }
    }
    return (
      <>
        <FormContainer
          title="Route details"
          controls={
            <ControlsStyled>
              <ItineraryReminderBlock />
              <Stack direction="row" spacing={1} alignItems="center">
                <H3 color={form.isRoundTrip ? 'grey' : undefined}>One Way</H3>
                <Tooltip
                  title="A One Way Trip will leave you at the final destination. In the case where you wish to return to the pickup location, but there is time between your destination and return where the vehicle is not needed, two One Way Trips should be planned."
                  placement="top"
                >
                  <TooltipIconStyled>
                    <InfoIcon size={16} />
                  </TooltipIconStyled>
                </Tooltip>
                <ToggleSwitch
                  checked={form.isRoundTrip}
                  onChange={() => form.toggleRoundTrip()}
                />
                <H3 color={!form.isRoundTrip ? 'grey' : undefined}>
                  Round Trip
                </H3>
                <Tooltip
                  title="A Round Trip assumes you will be returning to the original pickup location at the end of your journey. This should only be used if the vehicle will stay with you for the duration of the trip."
                  placement="top"
                >
                  <TooltipIconStyled>
                    <InfoIcon size={16} />
                  </TooltipIconStyled>
                </Tooltip>
              </Stack>
            </ControlsStyled>
          }
          sx={{
            '.title-block': {
              flexDirection: 'column',
              alignItems: 'start',
            },
          }}
        >
          <ContentBlock aria-live="polite">
            <PlaceField
              id="Pickup address"
              label="Pickup address"
              placeholder="Enter pickup address"
              fieldValue={form.start?.formatted_address}
              onSelect={(address: RouteStop) => form.setTripStart(address)}
            />
            {form.waypoints &&
              form.waypoints.map((item, i) => {
                const id = `Stop ${i + 1}`
                return (
                  <PlaceField
                    key={item.id}
                    id={id}
                    label={id}
                    placeholder="Enter stop address"
                    fieldValue={item.formatted_address}
                    onSelect={(address: RouteStop) =>
                      form.setTripIntermediateStop(i, address)
                    }
                    onRemove={() => form.removeRouteStop(i)}
                  />
                )
              })}
            <div className="stop-places">
              <Button
                onClick={() => form.addRouteStop()}
                startIcon={<AddIcon />}
              >
                Add stop
              </Button>
            </div>
          </ContentBlock>
          <LastStopBlock>
            {form.isRoundTrip ? (
              <TextInput
                disabled={true}
                label="Last stop"
                placeholder="Select pickup address"
                value={form.end?.formatted_address}
              />
            ) : (
              <PlaceField
                id="Last stop"
                label="Last stop"
                placeholder="Enter last stop"
                fieldValue={form.end?.formatted_address}
                onSelect={(address: RouteStop) => form.setTripLastStop(address)}
              />
            )}
          </LastStopBlock>
        </FormContainer>
        <ButtonComponent
          fullWidth
          disabled={!validateForm.get()}
          onClick={() => (form.isChanged ? submitRouteForm() : props.goNext())}
          aria-label="Continue to next step of this form"
        >
          Continue
        </ButtonComponent>
      </>
    )
  }
)

export default RouteForm
