import palette from '../theme/palette'

export const inputStyles = {
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '150%',
  letterSpacing: '0.04em',
  color: palette?.black?.plus10,
  height: 'auto',
  padding: '8px 14px 9px',
  border: `1px solid ${palette?.black?.plus85}`,
  borderRadius: '4px',
}
