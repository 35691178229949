import React from 'react'

interface Props {
  color?: string
  size?: number
}

export const Recipe: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const size = props.size || 24
  return (
    <svg
      width={size}
      height={size}
      style={{
        minHeight: size,
        minWidth: size,
      }}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 20C4 19.337 4.26339 18.7011 4.73223 18.2322C5.20107 17.7634 5.83696 17.5 6.5 17.5H20"
        stroke={props.color || 'currentColor'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 2.5H20V22.5H6.5C5.83696 22.5 5.20107 22.2366 4.73223 21.7678C4.26339 21.2989 4 20.663 4 20V5C4 4.33696 4.26339 3.70107 4.73223 3.23223C5.20107 2.76339 5.83696 2.5 6.5 2.5V2.5Z"
        stroke={props.color || 'currentColor'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
