import React from 'react'

interface Props {
  color?: string
  size?: number
}
const SeatbeltsIcon: React.FC<React.PropsWithChildren<Props>> = ({
  color = '#222',
  size = 56,
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 56 56"
      aria-label="seatbelts"
    >
      <rect
        width="4"
        height="12"
        x="32.243"
        y="35.071"
        fill={color}
        rx="1"
        transform="rotate(-45 32.243 35.071)"
      ></rect>
      <path
        fill={color}
        d="M29.414 35.071a1 1 0 010-1.414l4.243-4.243a1 1 0 011.414 0l1.414 1.414a1 1 0 010 1.415l-4.242 4.242a1 1 0 01-1.415 0l-1.414-1.414z"
      ></path>
      <path
        stroke={color}
        d="M27.485 29.813a.5.5 0 01.053-.644l1.63-1.63a.5.5 0 01.645-.054l3.3 2.357a.5.5 0 01.062.76l-2.573 2.574a.5.5 0 01-.76-.063l-2.357-3.3z"
      ></path>
      <rect
        width="4"
        height="12"
        x="12.443"
        y="15.272"
        fill={color}
        rx="1"
        transform="rotate(-45 12.443 15.272)"
      ></rect>
      <path
        stroke={color}
        d="M19.868 24.818a.5.5 0 010-.707l4.243-4.243a.5.5 0 01.707 0l2.121 2.122a.5.5 0 010 .707l-4.242 4.242a.5.5 0 01-.707 0l-2.122-2.121z"
      ></path>
    </svg>
  )
}

export default SeatbeltsIcon
