import React from 'react'

import CustomerForm from './CustomerForm'
import CustomerFormEmbedded from './embedded/CustomerForm'

interface Props {
  guestAuthToken?: string
  embedded?: boolean
  children?: React.ReactNode
  goNext: () => void
}

const CustomerFormBlock: React.FC<React.PropsWithChildren<Props>> = ({
  guestAuthToken = '',
  ...props
}: Props) => {
  if (props.embedded) {
    return <CustomerFormEmbedded guestAuthToken={guestAuthToken} {...props} />
  }
  return <CustomerForm {...props} />
}

export default CustomerFormBlock
