import { styled } from '@mui/system'

const SearchInputFieldStyled = styled('div')(() => {
  return {
    // marginBottom: "12px",
    '.search-input-block': {
      position: 'relative',
      '.search-icon': {
        position: 'absolute',
        top: '7px',
        left: '15px',
      },
    },
  }
})

export default SearchInputFieldStyled
