import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'

import { ErrorMessage, FlexContainer, TimeInput } from '@busie/ui-kit'
import { TextInput } from '@busie/ui-kit'
import { DatePicker } from '@busie/ui-kit'

import store from '../../store'

import DepartureBlockStyled from './styled/DepartureBlockStyled'
import { RouteStopDeparture } from '../../store/departuresForm/store.types'
import {
  datetimeFormatter as dateFormat,
  validateTime,
  getDateTime,
} from '@busie/utils'

interface Props {
  index: number
  departure: RouteStopDeparture
  prevDeparture?: RouteStopDeparture
}

const DepartureBlock: React.FC<React.PropsWithChildren<Props>> = observer(
  ({ index, departure, prevDeparture }: Props) => {
    const form = store.departuresForm

    const { isLast, date, time, name, minDateTime } = departure
    const getLabel = (index: number, isLastStop: boolean): string => {
      if (isLastStop) return 'Last stop'
      switch (index) {
        case 0:
          return 'Pickup address'
        default:
          return `Stop ${index + 1}`
      }
    }
    const isDateTimePickDisabled =
      index > 0 &&
      !(
        prevDeparture?.date &&
        validateTime(prevDeparture?.time) &&
        prevDeparture?.time !== null &&
        getDateTime(prevDeparture?.date, prevDeparture?.time).isSameOrAfter(
          prevDeparture.minDateTime
        )
      )

    const showErrorMessage = useMemo(() => {
      return (
        date &&
        time &&
        minDateTime &&
        getDateTime(date, time).isBefore(minDateTime)
      )
    }, [date, time, minDateTime])

    const formattedMinDate = minDateTime
      ? dateFormat(minDateTime, 'datetime')
      : ''

    const helperCopy = () => {
      if (isLast) {
        return 'Last stop does not have a departure date.'
      }
      return departure.minDateTime ? `Available from ${formattedMinDate}` : ''
    }

    return (
      <DepartureBlockStyled className={isLast ? 'last-stop-block' : ''}>
        <div className="address-input">
          <TextInput
            label={getLabel(index, isLast)}
            disabled={true}
            value={name}
            hideErrorSpace
          />
        </div>
        {!isLast && (
          <>
            <DatePicker
              label="Departure date"
              minDate={minDateTime || new Date()}
              placeholder="Select date"
              value={date || (minDateTime as Date)}
              className="departure-date-input"
              disabled={isDateTimePickDisabled}
              onChange={(value: Date) => form.setDepartureDate(index, value)}
              locale={store.locale}
              hideErrorSpace
            />
            <TimeInput
              label="Departure time"
              disabled={isDateTimePickDisabled}
              onChange={(value) => form.setDepartureTime(index, value)}
              value={time}
              withPadding
            />
          </>
        )}
        <FlexContainer
          justify="flex-end"
          fullWidth
          className="error-message"
          h={14}
        >
          {showErrorMessage && (
            <ErrorMessage>Date can't be before {formattedMinDate}</ErrorMessage>
          )}
        </FlexContainer>
        {
          <div
            className={['helper-text', !isLast ? 'text-right' : ''].join(' ')}
          >
            {helperCopy()}
          </div>
        }
      </DepartureBlockStyled>
    )
  }
)

export default DepartureBlock
