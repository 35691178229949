import { styled, Theme } from '@mui/system'
import Select from '@mui/material/Select'
interface Props {
  theme?: Theme
}

const CustomSelectStyled = styled(Select)((props: Props) => {
  const palette = props.theme?.palette
  return {
    width: '100%',
    padding: '0',
    background: palette?.black?.plus100,
    '& .MuiSelect-select': {
      width: '100%',
      padding: '9px 16px 10px',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '150%',
      letterSpacing: '0.04em',
      // color: palette?.black?.plus10,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: palette?.black?.plus10,
    },
    'fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: palette?.black?.plus70,
    },
    '&.Mui-focused': {
      'fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: palette?.black?.plus70,
      },
    },
  }
})

export default CustomSelectStyled
