import { styled } from '@mui/system'
import buttonClasses from '@mui/material/Button/buttonClasses'

// import { Theme } from "@mui/system";

// interface Props {
//   theme?: Theme;
// }

const ButtonBlockStyled = styled('div')(() => {
  // const palette = props.theme?.palette;
  return {
    width: '100%',
    textAlign: 'center',
    margin: '44px 0 0',
    [`.${buttonClasses.root}`]: {
      width: '100%',
      maxWidth: '240px',
    },
  }
})

export default ButtonBlockStyled
