import CircularProgress from '@mui/material/CircularProgress'
import React from 'react'

import LoadingStyled from './styled/LoadingStyled'

export interface LoadingBlockProps {
  padding?: string
  size?: number | string

  width?: number | string
  height?: number | string

  display?: 'flex' | 'block'
  direction?: 'column' | 'row'

  justifyContent?: 'center' | 'flex-start' | 'flex-end'
  alignItems?: 'center' | 'flex-start' | 'flex-end'
}

const LoadingBlock: React.FC<React.PropsWithChildren<LoadingBlockProps>> = (
  props
) => {
  return (
    <LoadingStyled {...props}>
      <CircularProgress size={props.size} color="primary" />
    </LoadingStyled>
  )
}

export default LoadingBlock
