import React from 'react'

export const PaymentErrorIcon = () => {
  return (
    <svg
      width="63"
      height="61"
      viewBox="0 0 63 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.5833 28.0002C13.5833 27.5859 13.9191 27.2502 14.3333 27.2502H22.6667C23.0809 27.2502 23.4167 27.5859 23.4167 28.0002C23.4167 28.4144 23.0809 28.7502 22.6667 28.7502H14.3333C13.9191 28.7502 13.5833 28.4144 13.5833 28.0002Z"
        fill="#FF0018"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M44.25 46.5002V35.0002H45.75V46.5002H44.25Z"
        fill="#FF0018"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M45.5 59.5002C54.3366 59.5002 61.5 52.3367 61.5 43.5002C61.5 34.6636 54.3366 27.5002 45.5 27.5002C36.6634 27.5002 29.5 34.6636 29.5 43.5002C29.5 52.3367 36.6634 59.5002 45.5 59.5002ZM45.5 61.0002C55.165 61.0002 63 53.1651 63 43.5002C63 33.8352 55.165 26.0002 45.5 26.0002C35.835 26.0002 28 33.8352 28 43.5002C28 53.1651 35.835 61.0002 45.5 61.0002Z"
        fill="#FF0018"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M44.25 51.0002V49.0002L45.75 49.0002V51.0002L44.25 51.0002Z"
        fill="#FF0018"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 47.2502C5.89088 47.2502 1.75 43.1093 1.75 38.0002V15.4168H60.25V24.6668C60.25 25.081 60.5858 25.4168 61 25.4168C61.4142 25.4168 61.75 25.081 61.75 24.6668V11.3335C61.75 5.39595 56.9375 0.583496 51 0.583496H11C5.06245 0.583496 0.25 5.39595 0.25 11.3335V38.0002C0.25 43.9377 5.06245 48.7502 11 48.7502H21C21.4142 48.7502 21.75 48.4144 21.75 48.0002C21.75 47.5859 21.4142 47.2502 21 47.2502H11ZM11 2.0835C5.89088 2.0835 1.75 6.22438 1.75 11.3335V13.9168H60.25V11.3335C60.25 6.22438 56.1091 2.0835 51 2.0835H11Z"
        fill="#FF0018"
      />
    </svg>
  )
}
