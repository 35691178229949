import axios from 'axios'
import { env, Driver, DriversSettings } from '@busie/utils'

export interface ListDriversParams {
  firstName?: string
  lastName?: string
  email?: string
  userId?: string
  dateOfLastPhysical?: Date
  dateOfNextPhysical?: Date
}

export type CreateDriverParams = Omit<
  Driver,
  '_id' | 'createdBy' | 'createdAt' | 'organizationId'
>

export type UpdateDriverParams = Omit<Driver, '_id' | 'organizationId'>

export const fetchDrivers = async (
  token: string,
  params: ListDriversParams
): Promise<Driver[]> => {
  const { data } = await axios.get<Driver[]>(
    `${env('BUSIE_DRIVERS_SERVICE_API_URL')}/ds/drivers/`,
    {
      params,
      headers: { Authorization: `Bearer ${token}` },
    }
  )

  return data
}

export const fetchDriver = async (
  token: string,
  driverId: string
): Promise<Driver> => {
  const { data } = await axios.get<Driver>(
    `${env('BUSIE_DRIVERS_SERVICE_API_URL')}/ds/drivers/${driverId}`,
    { headers: { Authorization: `Bearer ${token}` } }
  )

  return data
}

export const fetchDriversSettings = async (
  token: string,
  organizationId: string
): Promise<DriversSettings> => {
  const { data } = await axios.get<DriversSettings>(
    `${env('BUSIE_DRIVERS_SERVICE_API_URL')}/ds/settings/${organizationId}`,
    { headers: { Authorization: `Bearer ${token}` } }
  )
  return data
}

export const createDriver = async (
  authToken: string,
  payload: CreateDriverParams
): Promise<Driver> => {
  const { data } = await axios.post<Driver>(
    `${env('BUSIE_DRIVERS_SERVICE_API_URL')}/ds/drivers/`,
    payload,
    { headers: { Authorization: `Bearer ${authToken}` } }
  )
  return data
}

export const editDriver = async (
  authToken: string,
  id: string,
  payload: UpdateDriverParams
): Promise<Driver> => {
  const { data } = await axios.put<Driver>(
    `${env('BUSIE_DRIVERS_SERVICE_API_URL')}/ds/drivers/${id}`,
    payload,
    { headers: { Authorization: `Bearer ${authToken}` } }
  )

  return data
}

export const deleteDriver = async (
  authToken: string,
  id: string
): Promise<void> => {
  await axios.delete(
    `${env('BUSIE_DRIVERS_SERVICE_API_URL')}/ds/drivers/${id}`,
    { headers: { Authorization: `Bearer ${authToken}` } }
  )
}
