import axios from 'axios'

import { RatesFormData } from '../../dataTypes/rates.data-type'
import { getCreatedIngredients } from '../../helpers/rateMenu.helpers'
import {
  env,
  BaseIngredient,
  RawIngredient,
  Cookbook,
  Ingredient,
  LegPrice,
  Menu,
  RateRecipe,
  Recipe,
  RuleType,
  CookbookType,
  RawCookbook,
} from '@busie/utils'
import { PaginatedResponse } from '../../dataTypes'
import qs from 'qs'

const prettifyIngredient = (rawIngredient: RawIngredient): Ingredient => {
  return {
    id: rawIngredient._id,
    name: rawIngredient._name,
    value: rawIngredient._value,
    type: rawIngredient._type,
    recipeId: rawIngredient._recipeId,
  }
}

export const fetchRecipe = async (
  recipeId: string,
  authToken: string
): Promise<Recipe> => {
  return axios
    .get(`${env('BUSIE_RATE_SERVICE_API_URL')}/rates/recipes/${recipeId}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
    .then((response) => {
      return response.data
    })
}

export const updateCookbookRulePriority = (
  authToken: string,
  id: string,
  form: RuleType[]
): Promise<Cookbook> => {
  return axios
    .patch(
      `${env('BUSIE_RATE_SERVICE_API_URL')}/rates/cookbooks/${id}/priority`,
      {
        rulePriority: form,
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
    .then((response) => {
      return response.data
    })
}

export const createRecipe = async (
  formData: RatesFormData,
  authToken: string
): Promise<RateRecipe> => {
  const requestBody = {
    name: formData.name,
    organization: '',
    // dispatches: ["string"],
    cookbookId: formData.cookbookId,
    ingredients: getCreatedIngredients(formData),
    rule: {
      value: formData.ruleTypeValue[formData.ruleType],
      type: formData.ruleType,
    },
  }
  return axios
    .post(`${env('BUSIE_RATE_SERVICE_API_URL')}/rates/recipes`, requestBody, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
    .then((response) => {
      return response.data
    })
}

export const deleteRecipe = (
  authToken: string,
  recipeId: string
): Promise<unknown> => {
  return axios
    .delete(`${env('BUSIE_RATE_SERVICE_API_URL')}/rates/recipes/${recipeId}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
    .then((response) => {
      return response.data
    })
}

export const updateRecipeName = (
  authToken: string,
  name: string,
  recipeId: string
): Promise<Recipe> => {
  return axios
    .patch(
      `${process.env.REACT_APP_BUSIE_RATE_SERVICE_API_URL}/rates/recipes/${recipeId}/name`,
      { name },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
    .then((response) => {
      return response.data
    })
}

export const updateRuleValue = (
  authToken: string,
  value: string | Date,
  ruleId: string
): Promise<unknown> => {
  return axios
    .patch(
      `${process.env.REACT_APP_BUSIE_RATE_SERVICE_API_URL}/rates/rules/${ruleId}/value`,
      { value },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
    .then((response) => {
      return response.data
    })
}

export const updateRuleType = (
  authToken: string,
  type: string,
  ruleId: string
): Promise<unknown> => {
  return axios
    .patch(
      `${process.env.REACT_APP_BUSIE_RATE_SERVICE_API_URL}/rates/rules/${ruleId}/type`,
      { type },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
    .then((response) => {
      return response.data
    })
}

export const deleteIngredient = (
  authToken: string,
  recipeId: string
): Promise<null> => {
  return axios
    .delete(
      `${env('BUSIE_RATE_SERVICE_API_URL')}/rates/ingredients/${recipeId}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
    .then((response) => {
      return response.data
    })
}

export const createIngredient = (
  authToken: string,
  ingredient: BaseIngredient
): Promise<Ingredient> => {
  return axios
    .post(
      `${process.env.REACT_APP_BUSIE_RATE_SERVICE_API_URL}/rates/ingredients`,
      ingredient,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
    .then((response) => {
      return prettifyIngredient(response.data)
    })
}

export const updateIngredientName = (
  authToken: string,
  name: string,
  ingredientId: string
): Promise<Ingredient> => {
  return axios
    .patch(
      `${process.env.REACT_APP_BUSIE_RATE_SERVICE_API_URL}/rates/ingredients/${ingredientId}/name`,
      { name },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
    .then((response) => {
      return prettifyIngredient(response.data)
    })
}

export const updateIngredientValue = (
  authToken: string,
  value: string | number,
  ingredientId: string
): Promise<Ingredient> => {
  return axios
    .patch(
      `${process.env.REACT_APP_BUSIE_RATE_SERVICE_API_URL}/rates/ingredients/${ingredientId}/value`,
      { value },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
    .then((response) => {
      return prettifyIngredient(response.data)
    })
}

export const updateIngredientType = (
  authToken: string,
  type: string,
  ingredientId: string
): Promise<Ingredient> => {
  return axios
    .patch(
      `${process.env.REACT_APP_BUSIE_RATE_SERVICE_API_URL}/rates/ingredients/${ingredientId}/type`,
      { type },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
    .then((response) => {
      return prettifyIngredient(response.data)
    })
}

/**
 * TODO:
 * The Below interfaces and functions were designed for testing/dev purposes only.
 * It is likely they will not be used or should be refactored for use in production environment.
 */
interface MenuData {
  organization: string
}

export const createMenu = async (
  createMenuData: MenuData,
  authToken: string
): Promise<Menu> => {
  const { organization } = createMenuData
  const requestBody = {
    organization,
  }

  const { data } = await axios.post(
    `${env('BUSIE_RATE_SERVICE_API_URL')}/rates/menus`,
    requestBody,
    { headers: { Authorization: `Bearer ${authToken}` } }
  )

  return data
}

export const fetchMenu = async (
  menuQueryData: MenuData,
  authToken: string
): Promise<Menu[]> => {
  const { organization } = menuQueryData
  const query = `organization=${organization}`

  const { data } = await axios.get(
    `${env('BUSIE_RATE_SERVICE_API_URL')}/rates/menus?${query}`,
    { headers: { Authorization: `Bearer ${authToken}` } }
  )

  return data
}

interface CookbookData {
  menuId: string
  name?: string
  type?: CookbookType
}

export const fetchCookbooks = async (
  authToken: string,
  params: {
    itemsPerPage?: number
    page?: number
  } & CookbookData
): Promise<PaginatedResponse<Cookbook>> => {
  const queryParams = qs.stringify(
    {
      take: params.itemsPerPage,
      skip:
        params.itemsPerPage && params.page && params.page > 1
          ? (params.page - 1) * params.itemsPerPage
          : 0,
      menuId: params.menuId,
      name: params.name,
      type: params.type,
    },
    { addQueryPrefix: true, arrayFormat: 'repeat' }
  )

  const { data } = await axios.get(
    `${env('BUSIE_RATE_SERVICE_API_URL')}/rates/cookbooks${queryParams}`,
    { headers: { Authorization: `Bearer ${authToken}` } }
  )

  return data
}

export const fetchCookbook = async (
  authToken: string,
  id: string
): Promise<RawCookbook> => {
  const { data } = await axios.get(
    `${env('BUSIE_RATE_SERVICE_API_URL')}/rates/cookbooks/${id}`,
    { headers: { Authorization: `Bearer ${authToken}` } }
  )

  return data
}

export const fetchLegPrice = async (
  id: string,
  authToken: string
): Promise<LegPrice> => {
  const { data } = await axios.get(
    `${env('BUSIE_RATE_SERVICE_API_URL')}/rates/leg-prices/${id}`,
    { headers: { Authorization: `Bearer ${authToken}` } }
  )

  return data
}

export const fetchLegsPrices = async (
  ids: string[],
  authToken: string
): Promise<LegPrice[]> => {
  const requests = ids.map((id) => fetchLegPrice(id, authToken))
  const data = await Promise.all(requests)
  return data
}
