import { Trip } from '@busie/utils'
import { formatLocation } from './format-location'

export const handleTripCreatedData = (trip: Trip): Trip => {
  return {
    ...trip,
    _legs: trip._legs.map((leg) => {
      return {
        ...leg,
        _startLocation: formatLocation(leg._startLocation),
        _destinationLocation: formatLocation(leg._destinationLocation),
      }
    }),
  }
}
