import { makeAutoObservable } from 'mobx'
import { Amenity } from '@busie/utils'

import { Passengers, PassengersKeys } from './store.types'

class tripForm {
  amenities: Amenity[] = []
  passengers: Passengers = {
    amount: 1,
    accessibleSeating: 0,
  }
  additionalInfo = ''
  spab = false
  editMode = false
  constructor() {
    makeAutoObservable(this)
  }
  public toggleAmenity(amen: Amenity) {
    if (!this.amenities.includes(amen)) {
      this.amenities.push(amen)
    } else {
      const index = this.amenities.indexOf(amen)
      if (index > -1) {
        this.amenities.splice(index, 1)
      }
    }
  }
  public updatePassengersAmount(key: PassengersKeys, value?: number) {
    this.passengers[key] = value
  }
  public setAdditionalInfo(value: string) {
    this.additionalInfo = value
  }

  public setSpab(value: boolean) {
    this.spab = value
  }

  public setEditMode(value: boolean) {
    this.editMode = value
  }

  public reset() {
    this.amenities = []
    this.passengers = {
      amount: 0,
      accessibleSeating: 0,
    }
    this.additionalInfo = ''
    this.editMode = false
  }
}

export default tripForm
