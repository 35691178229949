import { styled } from '@mui/system'

import { LoadingBlockProps } from '../Loading'

const LoadingStyled = styled('div')((props: LoadingBlockProps) => {
  return {
    padding: props.padding || '15vh 0',
    textAlign: 'center',
    display: props.display || 'block',
    flexDirection: props.direction || 'column',
    justifyContent: props.justifyContent || 'center',
    alignItems: props.alignItems || 'center',
    width: props.width || 'auto',
    height: props.height || 'auto',
  }
})

export default LoadingStyled
