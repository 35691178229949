import dayjs, { Dayjs } from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import 'dayjs/locale/es-mx'

dayjs.extend(localeData)
dayjs.extend(customParseFormat)
dayjs.extend(isBetween)
dayjs.extend(duration)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(utc)
dayjs.extend(timezone)

dayjs.tz.setDefault('America/New_York')

export const timeFormat = 'hh:mmA'

export const validateTime = (time: Dayjs | null): boolean => {
  if (!time) return true

  return time.isValid()
}

export const getDateTime = (date: Date, time: Dayjs | null): Dayjs => {
  return dayjs(date)
    .set('hour', time?.hour() || 12)
    .set('minute', time?.minute() || 0)
    .set('second', time?.second() || 0)
    .set('millisecond', 0)
}

export const getDateTimeISO = (date: Date, time: Dayjs | null): string => {
  return getDateTime(date, time).toISOString()
}

export type locale = 'en-us' | 'es-mx'

export type DayjsExt = Dayjs
export const dayjsExt = dayjs
