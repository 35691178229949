import { styled } from '@mui/system'
import AppBar from '@mui/material/AppBar'

import { Theme } from '@mui/system'
interface Props {
  theme?: Theme
}

const AppBarStyled = styled(AppBar)((props: Props) => {
  const palette = props.theme?.palette
  return {
    backgroundColor: palette?.black?.plus100,
    boxShadow: `0 1px 0 ${palette?.black?.plus80}`,
    maxHeight: '64px',
    a: {
      maxHeight: '64px',
    },
  }
})

export default AppBarStyled
