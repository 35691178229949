export type MessageType = 'error' | 'success'

export interface MessageContent {
  title: string
  text: string
}

export const MESSAGES: Record<MessageType, MessageContent> = {
  error: {
    title: 'Oops!',
    text: 'Something went wrong',
  },
  success: {
    title: 'Congratulations',
    text: 'Your account is set up',
  },
}
