import React from 'react'

import { Button, EditIcon } from '@busie/ui-kit'

interface Props {
  onClick: () => void
}

const EditButton: React.FC<React.PropsWithChildren<Props>> = (props) => {
  return (
    <Button
      typestyle="secondary"
      size="small"
      startIcon={<EditIcon />}
      sx={{
        ':focus-visible': {
          outline: 'none',
          boxShadow: '0 0 2px 2px #51a7e8',
        },
      }}
      {...props}
    >
      Edit
    </Button>
  )
}

export default EditButton
