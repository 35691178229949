import { FlexContainer } from '../FlexContainer'
import palette from '../theme/palette'
import { Text } from '../typography'

interface Props {
  text: string
  backgroundColor?: string
  textColor?: string
  bordered?: boolean
  px?: number
  py?: number
  icon?: React.ReactNode
  fontSize?: string
}

export const Banner: React.FC<React.PropsWithChildren<Props>> = ({
  text,
  backgroundColor = palette.black.plus30,
  textColor = palette.black.main,
  icon = null,
  px = 1.5,
  py = 1.75,
  bordered = true,
  fontSize = '14px',
}) => (
  <FlexContainer
    direction="row"
    align="center"
    px={px}
    py={py}
    columnGap={1}
    bordered={bordered}
    sx={{
      borderRadius: bordered ? '4px' : '0px',
      backgroundColor: backgroundColor,
      color: textColor,
    }}
  >
    {icon}
    <Text fontSize={fontSize} color={textColor}>
      {text}
    </Text>
  </FlexContainer>
)
