import React from 'react'

interface Props {
  color?: string
  size?: string
}

export const CheckmarkIcon: React.FC<React.PropsWithChildren<Props>> = ({
  color = 'currentColor',
  size = '24',
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 11.1515L10.9057 16L16.3019 10.6667L19 8"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
