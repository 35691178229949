import { useAuthTokenWithAudience } from '@busie/core'
import { notificationStore } from '@busie/features'
import { isOrganizationSettingsAllowed, User } from '@busie/utils'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { paymentStore } from '~/settings/store'

/**
 * Custom hook that manages user onboarding flow and validation
 *
 * This hook handles:
 * - Checking if organization has completed onboarding
 * - Redirecting non-onboarded users to appropriate pages
 * - Displaying notifications about pending onboarding
 * - Validating payment account status
 *
 * @param {User | undefined} user - The authenticated user object
 * @returns {boolean | null} Onboarding completion status:
 *   - true: onboarding completed
 *   - false: onboarding pending
 *   - null: status not yet determined
 *
 * @example
 * ```tsx
 * const onboardingCompleted = useOnboarding(user);
 * ```
 */
export const useOnboarding = (user: User | undefined) => {
  const history = useHistory()
  const [onboardingCompleted, setOnboardingCompleted] = useState<
    boolean | null
  >(null)
  const paymentAuthToken = useAuthTokenWithAudience('payments')

  const redirectNonOnboardedUser = useCallback(
    (hasCompletedOnboarding: boolean | null) => {
      if (user && hasCompletedOnboarding !== null && !hasCompletedOnboarding) {
        if (isOrganizationSettingsAllowed(user)) {
          if (
            !history.location.pathname.startsWith('/organization-settings/') &&
            !history.location.pathname.startsWith('/profile')
          ) {
            history.replace('/organization-settings/payments')
          }
        } else {
          if (history.location.pathname !== '/profile') {
            history.replace('/profile')
          }
        }

        notificationStore.setErrorNotification(
          'Onboarding pending',
          'Your organization is not yet onboarded, please check again later'
        )
      }
    },
    [history, user]
  )

  // TODO: [BUSIE-1573] Validate onboarding status using the Plans API, instead of Payment Accounts
  useEffect(() => {
    const validateOnboarding = async () => {
      if (paymentAuthToken && user?.org_id && !paymentStore.isAccountLinked) {
        paymentStore.setAuthToken(paymentAuthToken)
        const onbCompleted = await paymentStore.getIsAccountLinked(user.org_id)
        setOnboardingCompleted(onbCompleted)
        redirectNonOnboardedUser(onbCompleted)
      }
    }

    validateOnboarding()
  }, [paymentAuthToken, user, redirectNonOnboardedUser])

  useEffect(() => {
    return history.listen(() => {
      redirectNonOnboardedUser(onboardingCompleted)
    })
  }, [history, onboardingCompleted, redirectNonOnboardedUser])

  return onboardingCompleted
}
