import { Integration, QuotesIntegration } from '@busie/utils'

export const quoteIntegrationsToMap = (
  quotesIntegration: QuotesIntegration
): Map<string, Integration> => {
  const intMap = new Map<string, Integration>()

  quotesIntegration.integrations.forEach((int: Integration) => {
    intMap.set(int.id, int)
  })

  return intMap
}
