import { DateRange, datetimeFormatter as dateFormat } from '@busie/utils'

export const getInputTextValue = (newValue: DateRange) => {
  if (!newValue[0]) return ''
  if (!newValue[1]) return dateFormat(newValue[0], 'date')
  return `${dateFormat(newValue[0], 'date')} — ${dateFormat(
    newValue[1],
    'date'
  )}`
}
