import { ComponentType, Suspense } from 'react'
import { RouteComponentProps } from 'react-router-dom'

/**
 * Ensures a component is wrapped as a function component
 *
 * This utility function takes a React component and wraps it in a function component
 * that forwards all props to the original component. This is useful when you need
 * to ensure consistent function component behavior, especially when dealing with
 * components that may be class components or other component types.
 *
 * @param {ComponentType<RouteComponentProps>} Component - The React component to wrap
 * @returns {(props: RouteComponentProps) => JSX.Element} A function component that renders the input component
 *
 * @example
 * ```tsx
 * const WrappedComponent = ensureFunctionComponent(SomeComponent);
 * <WrappedComponent someProp="value" />
 * ```
 */
export const ensureFunctionComponent = (
  Component: ComponentType<RouteComponentProps>
): ComponentType<RouteComponentProps> => {
  return (props) => <Component {...props} />
}

/**
 * Wrapper to render lazy-loaded components with Suspense
 */
export const LazyRoute = ({
  component: Component,
}: {
  component: React.ComponentType<RouteComponentProps>
}): React.ComponentType<RouteComponentProps> => {
  return (props) => (
    <Suspense fallback={<div>Loading...</div>}>
      <Component {...props} />
    </Suspense>
  )
}
