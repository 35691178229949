import { styled, SxProps } from '@mui/system'
import palette from '../theme/palette'
import { FC } from 'react'

interface Props {
  children: React.ReactNode
  color?: string
  margin?: string
  className?: string
  sx?: SxProps
}

const InputTextStyled = styled('p')<Props>(({
  color = palette?.black?.main,
  margin = '0',
}: Props) => {
  return {
    color: color,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '150%',
    margin: margin,
  }
})

export const InputText: FC<React.PropsWithChildren<Props>> = ({
  color,
  children,
  margin,
  className,
  sx,
}) => {
  return (
    <InputTextStyled
      color={color}
      margin={margin}
      className={className}
      sx={sx}
    >
      {children}
    </InputTextStyled>
  )
}
