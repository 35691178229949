import { styled } from '@mui/system'

const PaymentBoxStyled = styled('div')(({ theme }) => ({
  width: '100%',
  border: `1px solid ${theme.palette?.black.plus70}`,
  height: '100%',
  backgroundColor: theme.palette?.black.plus100,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  a: {
    color: theme.palette?.black.plus60,
  },
}))

export const PaymentWrapperStyled = styled('div')(({ theme }) => ({
  padding: '33px',
  height: '100%',
  [theme.breakpoints.down('tablet')]: {
    padding: 0,
  },
}))

export default PaymentBoxStyled
