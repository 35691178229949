import { Checkbox } from '@mui/material'
import React from 'react'

import CheckboxBlock from './styled/CheckboxBlock'

import TableCell from './TableCell'

interface Props {
  onRowsSelect: () => void
  onRowsRemove: () => void
  dataLength: number
  selectedRowsLength: number
  hidden?: boolean
}

const TableCellSelectAll: React.FC<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  const isChecked =
    props.selectedRowsLength > 0 &&
    props.selectedRowsLength === props.dataLength
  const isIndeterminate =
    props.selectedRowsLength > 0 && props.selectedRowsLength < props.dataLength

  const handlerOnChange = () => {
    if (isIndeterminate) {
      props.onRowsSelect()
      return
    }
    isChecked ? props.onRowsRemove() : props.onRowsSelect()
  }
  return (
    <TableCell width="4%">
      <CheckboxBlock hide={props.hidden}>
        <Checkbox
          sx={{
            padding: 0,
          }}
          onChange={handlerOnChange}
          indeterminate={isIndeterminate}
          checked={isChecked}
        />
      </CheckboxBlock>
    </TableCell>
  )
}
export default TableCellSelectAll
