import { palette } from '@busie/ui-kit'

export const InputLabelDotStyles = {
  display: 'block',
  content: "''",
  position: 'absolute',
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  background: palette?.green?.main,
  top: '0',
  left: '-29px',
}
