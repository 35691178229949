import { useEffect, useState } from 'react'

export const useWindowResize = () => {
  const [isTablet, setIsTablet] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [isDesktop, setIsDesktop] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth <= 1124)
      setIsMobile(window.innerWidth <= 768)
      setIsDesktop(window.innerWidth > 1124)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return { isTablet, isMobile, isDesktop }
}
