import React from 'react'
import { VehicleIconProps as Props } from '.'

export const ExecutiveCoachIcon: React.FC<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="2" y="38" width="4" height="1" rx="0.5" fill="#222222" />
      <path
        d="M29 28C29 27.4477 29.4477 27 30 27H33C33.5523 27 34 27.4477 34 28V43H29V28Z"
        stroke="#FF0018"
      />
      <path
        d="M12 43H37M6 43H3C2.44772 43 2 42.5523 2 42V37.0308C2 36.5719 2.3123 36.1719 2.75746 36.0606L8.29501 34.6762C8.75044 34.5624 9.1513 34.292 9.42741 33.9123L12.401 29.8237C12.7773 29.3062 13.3786 29 14.0184 29H20.5C20.7761 29 21 28.7761 21 28.5V22.5C21 22.2239 21.2239 22 21.5 22H53C53.5523 22 54 22.4477 54 23V42C54 42.5523 53.5523 43 53 43H42.5"
        stroke="#FF0018"
      />
      <rect x="23" y="27" width="4" height="7" rx="1" stroke="#FF0018" />
      <path
        d="M13.7087 31.4855C13.8894 31.1843 14.2149 31 14.5662 31H18C18.5523 31 19 31.4477 19 32V35C19 35.5523 18.5523 36 18 36H12.7662C11.9889 36 11.5088 35.152 11.9087 34.4855L13.7087 31.4855Z"
        stroke="#FF0018"
      />
      <path
        d="M15.7087 22.4855C15.8894 22.1843 16.2149 22 16.5662 22H21V27H13.8831C13.4944 27 13.2544 26.576 13.4543 26.2428L15.7087 22.4855Z"
        stroke="#FF0018"
      />
      <rect x="36" y="27" width="4" height="7" rx="1" stroke="#FF0018" />
      <rect x="42" y="27" width="4" height="7" rx="1" stroke="#FF0018" />
      <rect x="48" y="27" width="4" height="7" rx="1" stroke="#FF0018" />
      <circle cx="9" cy="43" r="3" stroke="#222222" />
      <circle cx="40" cy="43" r="3" stroke="#222222" />
      <path d="M21 28V43" stroke="#FF0018" strokeLinecap="round" />
    </svg>
  )
}

export default ExecutiveCoachIcon
