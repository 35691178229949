import { styled } from '@mui/system'
interface Props {
  withPadding?: boolean
  isMobile?: boolean
  fixedWidth?: string
}

const DatePickerStyled = styled('div')<Props>((props) => {
  const palette = props.theme.palette
  return {
    position: 'relative',
    paddingTop: props.withPadding ? '8px' : 0,
    width: props.fixedWidth,
    minWidth: props.fixedWidth,

    '.calendar-toolbox': {
      position: 'absolute',
      background: palette?.black?.plus100,
      borderRadius: '4px',
      zIndex: '100',
      boxShadow: '0px 8px 24px rgb(34 34 34 / 15%)',
      left: props.isMobile ? '50%' : '',
      transform: props.isMobile ? 'translateX(-50%)' : '',
    },
  }
})

export default DatePickerStyled
