import { styled } from '@mui/system'

const StatisticsBlockStyled = styled('div')(() => {
  return {
    '&': {
      // marginRight: "40px",
      letterSpacing: '0.04em',
      display: 'inline-flex',
      '.icon-block': {
        maxHeight: '32px',
        alignSelf: 'flex-end',
        marginRight: '8px',
        marginBottom: '6px',
        svg: {
          maxWidth: '32px',
          maxHeight: '32px',
        },
      },
      '.content-block': {
        '.text-block': {
          letterSpacing: '0.04em',
        },
        '.value-block': {
          textAlign: 'left',
        },
      },
    },
  }
})

export default StatisticsBlockStyled
