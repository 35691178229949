import { SxProps, Theme } from '@mui/system'
import React from 'react'

import TableContainerStyled from './styled/TableContainerStyled'

interface Props {
  children: React.ReactNode
  fullwidth?: boolean
  sx?: SxProps<Theme> | undefined
  isMobile?: boolean
  grow?: boolean
  justify?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
}

const TableContainer: React.FC<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  return (
    <TableContainerStyled
      sx={props.sx}
      fullwidth={props.fullwidth}
      isMobile={!!props.isMobile}
      grow={props.grow}
      justify={props.justify}
    >
      {props.children}
    </TableContainerStyled>
  )
}

export default TableContainer
