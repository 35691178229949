import React from 'react'

interface Props {
  color?: string
  size?: number
}
const UsbPortsIcon: React.FC<React.PropsWithChildren<Props>> = ({
  color = '#222',
  size = 56,
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 56 56"
      aria-label="usb ports"
    >
      <circle cx="28" cy="45" r="3" fill={color}></circle>
      <circle cx="21" cy="20" r="2" fill={color}></circle>
      <path
        stroke={color}
        strokeLinecap="round"
        d="M28 43V12M28 41v-3.343a4 4 0 00-1.172-2.829l-4.656-4.656A4 4 0 0121 27.343V22M28 37v-3.343a4 4 0 011.172-2.829l4.656-4.656A4 4 0 0035 23.343V18"
      ></path>
      <path fill={color} d="M33 14H37V18H33z"></path>
      <path fill={color} d="M28 8l2 4h-4l2-4z"></path>
    </svg>
  )
}

export default UsbPortsIcon
