import FormGroup from '@mui/material/FormGroup'
import { Theme, styled } from '@mui/system'

import { inputStyles } from '../../shared/inputStyles'

interface Props {
  maxwidth?: number
  theme?: Theme
}

const DatePickerInputStyled = styled(FormGroup)<Props>((props) => {
  const palette = props.theme?.palette
  return {
    position: 'relative',
    maxWidth: props.maxwidth ? props.maxwidth : '100%',
    input: {
      ...inputStyles,
      borderColor: palette?.black?.plus70,
      width: '100%',
      boxSizing: 'border-box',
      padding: '8px 36px 9px 16px',
      fontFamily: 'inherit',
      cursor: 'pointer',
      margin: 0,
      '&:focus': {
        outline: `1px solid ${palette?.black?.plus10}`,
      },
      '&:disabled': {
        WebkitTextFillColor: 'rgba(0, 0, 0, 0.38)',
      },
    },
    '.icon': {
      position: 'absolute',
      right: '8px',
      top: '7px',
      cursor: 'pointer',
    },
  }
})

export default DatePickerInputStyled
