import React from 'react'

import { TableHeadProps } from '@mui/material/TableHead'

import TableHeadStyled from './styled/TableHeadStyled'

const TableHead: React.FC<React.PropsWithChildren<TableHeadProps>> = (
  props: TableHeadProps
) => {
  return <TableHeadStyled {...props} />
}

export default TableHead
