import { ReactNode } from 'react'
import {
  palette,
  CalendarIcon,
  CheckmarkIcon,
  CustomerIcon,
  DetailsIcon,
  SummaryIcon,
  TripIcon,
} from '@busie/ui-kit'

export const getIcon = (
  index: number,
  completed?: boolean,
  active?: boolean
): ReactNode => {
  let color = palette?.black?.plus10
  if (completed || active) color = palette?.black?.plus100
  if (completed) return <CheckmarkIcon key={0} color={color} />
  const icons = [
    <CustomerIcon key={0} color={color} />,
    <DetailsIcon key={1} color={color} />,
    <TripIcon key={2} color={color} />,
    <CalendarIcon key={3} color={color} />,
    <SummaryIcon key={4} color={color} />,
  ]
  return icons[index - 1]
}
