import React, { PropsWithChildren } from 'react'

import NoContentBlockStyled from './styled/NoContentBlockStyled'

type Props = PropsWithChildren<{
  icon: React.ReactNode
  text?: string
  controls: React.ReactNode
}>

const NoContentBlock: React.FC<React.PropsWithChildren<Props>> = (
  props: Props
) => {
  const hasChildren = React.Children.count(props.children) > 0
  return (
    <NoContentBlockStyled>
      <div className="icon-block">{props.icon}</div>
      <div className="text-block">
        {hasChildren ? props.children : props.text}
      </div>
      <div className="controls-block">{props.controls}</div>
    </NoContentBlockStyled>
  )
}

export default NoContentBlock
