import React from 'react'
import { observer } from 'mobx-react-lite'

import AmenitiesBlockStyled from './styled/AmenitiesBlockStyled'
import AmenityBlockStyled from './styled/AmenityBlockStyled'

import store from '../../store'
import { AmenityIcon, mapAmenityToName } from '@busie/core'
import { Amenity, AmenityValues } from '@busie/utils'
import { convertAmenityToETPAmenity, ETPAmenity } from '@busie/api'

interface Props {
  embedded?: boolean
}

const AmenitiesBlock: React.FC<React.PropsWithChildren<Props>> = observer(
  ({ embedded }) => {
    const tripForm = store.tripForm
    const activeAmenities = tripForm.amenities

    const displayedAmenitiesFilter = embedded
      ? (amenity: Amenity) =>
          store.displayedAmenities.includes(convertAmenityToETPAmenity(amenity))
      : () => true

    return (
      <AmenitiesBlockStyled role="listbox" aria-live="polite">
        {AmenityValues.filter(displayedAmenitiesFilter).map((amenity, i) => {
          const id = `amenity-btn-${i}`
          const label = mapAmenityToName(amenity)
          const isSelected = activeAmenities.includes(amenity)
          return (
            <AmenityBlockStyled key={i}>
              <button
                id={id}
                onClick={() => tripForm.toggleAmenity(amenity)}
                className={isSelected ? 'active' : ''}
                role="option"
                aria-selected={isSelected}
                aria-label={label}
              >
                <AmenityIcon type={amenity} />
              </button>
              <label htmlFor={id} className="amentity-label" aria-hidden>
                {label}
              </label>
            </AmenityBlockStyled>
          )
        })}
      </AmenitiesBlockStyled>
    )
  }
)

export default AmenitiesBlock
