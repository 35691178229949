import { styled } from '@mui/system'

// import { Theme } from "@mui/system";

// interface Props {
//   theme?: Theme;
// }

const AmenitiesBlockStyled = styled('div')(() => {
  // const palette = props.theme?.palette;
  return {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: '16px',
    rowGap: '20px',
  }
})

export default AmenitiesBlockStyled
