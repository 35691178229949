import React from 'react'

interface Props {
  color?: string
  size?: string
}

export const WarningIcon: React.FC<React.PropsWithChildren<Props>> = ({
  color = 'currentColor',
  size = '24',
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29 7.25V7.25C41.0133 7.25 50.75 16.9867 50.75 29V29C50.75 41.0133 41.0133 50.75 29 50.75V50.75C16.9867 50.75 7.25 41.0133 7.25 29V29C7.25 16.9867 16.9867 7.25 29 7.25Z"
        stroke={color}
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M29.0001 30.2083V18.125"
        stroke={color}
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M28.9977 38.6667C28.6642 38.6667 28.3935 38.9373 28.3959 39.2708C28.3959 39.6043 28.6666 39.875 29.0001 39.875C29.3336 39.875 29.6043 39.6043 29.6043 39.2708C29.6043 38.9373 29.3336 38.6667 28.9977 38.6667"
        stroke={color}
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
