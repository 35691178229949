import React from 'react'

import { TableProps } from '@mui/material/Table'

import TableStyled from './styled/TableStyled'

const Table: React.FC<React.PropsWithChildren<TableProps>> = (
  props: TableProps
) => {
  return <TableStyled {...props} />
}

export default Table
