import React from 'react'

interface Props {
  color?: string
  size?: number
}
const DvdPlayerIcon: React.FC<React.PropsWithChildren<Props>> = ({
  color = '#222',
  size = 56,
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 56 56"
      aria-label="dvd player"
    >
      <circle
        cx="28"
        cy="28"
        r="16"
        fill={color}
        transform="rotate(-45 28 28)"
      ></circle>
      <path
        fill="#fff"
        d="M18.808 18.808a13.018 13.018 0 00-2.831 4.24L26.586 28 28 26.586l-4.952-10.61a13.019 13.019 0 00-4.24 2.832zM32.952 40.023a13.018 13.018 0 004.24-2.83 13.019 13.019 0 002.831-4.24l-11.316-5.66-1.414 1.414 5.66 11.316z"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        d="M18.808 18.808a13.018 13.018 0 00-2.831 4.24L26.586 28 28 26.586l-4.952-10.61a13.019 13.019 0 00-4.24 2.832zM32.952 40.023a13.018 13.018 0 004.24-2.83 13.019 13.019 0 002.831-4.24l-11.316-5.66-1.414 1.414 5.66 11.316z"
      ></path>
      <circle
        cx="28"
        cy="28"
        r="6"
        fill={color}
        transform="rotate(-45 28 28)"
      ></circle>
      <circle
        cx="28"
        cy="28"
        r="4"
        stroke="#fff"
        transform="rotate(-45 28 28)"
      ></circle>
      <circle
        cx="28"
        cy="28"
        r="2"
        fill="#fff"
        transform="rotate(-45 28 28)"
      ></circle>
    </svg>
  )
}

export default DvdPlayerIcon
