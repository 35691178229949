import { Theme } from '@mui/material'
import { styled } from '@mui/system'
import palette from '../theme/palette'

interface Props {
  theme: Theme
  fixedWidth?: string
  withPadding?: boolean
}

const TimeInputStyled = styled('div')(({ withPadding, fixedWidth }: Props) => {
  return {
    width: '100%',
    fontWeight: 500,
    fontFamily: 'Poppins, sans-serif',
    paddingTop: withPadding ? '8px' : 0,
    input: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '150%',
      letterSpacing: '0.04em',
      color: palette?.black?.plus10,
      height: 'auto',
      padding: '8px 14px 9px',
      border: `1px solid ${palette?.black?.plus70}`,
      borderRadius: '4px',
      width: fixedWidth ? '170px' : '100px',
      boxSizing: 'content-box',
      margin: 0,
      '&:focus': {
        border: `1px solid ${palette?.black?.plus10}`,
        outline: '0 none',
      },
      '&:disabled': {
        color: '#00000061',
      },
    },
  }
})

export default TimeInputStyled
