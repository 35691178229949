import React from 'react'

interface Props {
  color?: string
  size?: number
}
const WifiIcon: React.FC<React.PropsWithChildren<Props>> = ({
  color = '#222',
  size = 56,
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 56 56"
      aria-label="wifi"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeWidth="2"
        d="M42.142 22.858c-7.81-7.81-20.474-7.81-28.284 0M37.9 27.1c-5.468-5.467-14.332-5.467-19.8 0M33.657 31.343a8 8 0 00-11.314 0"
      ></path>
      <circle cx="28" cy="36" r="2" fill={color}></circle>
    </svg>
  )
}

export default WifiIcon
