import { FlexContainer } from '../../../FlexContainer'
import IconButton from '../../../IconButton'
import palette from '../../../theme/palette'
import { styled } from '@mui/material'

interface Props {
  expanded: boolean
}

export const AccordeonHeaderStyled = styled(FlexContainer)<Props>(({
  expanded,
}) => {
  return {
    cursor: 'pointer',
    position: 'relative',
    '& .right-most-icon': {
      display: expanded ? 'block' : 'none',
    },
    '& .hover-icon': {
      display: expanded ? 'flex' : 'none',
    },
    '&:hover': {
      backgroundColor: palette.black.plus85,
      '.right-most-icon': {
        display: 'block',
      },
      '.hover-icon': {
        display: 'flex',
      },
    },
  }
})

export const RightMostIconContainer = styled(IconButton)(({
  right,
}: {
  right?: number
}) => {
  return {
    zIndex: 2,
    position: 'absolute',
    right: right || 10,
  }
})
