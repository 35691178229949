import React from 'react'

interface Props {
  size?: number | string
  color?: string
}

export const InfoIcon: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const size = props.size || 24
  const color = props.color || '#323232'
  return (
    <svg
      width={size}
      height={size}
      style={{
        minHeight: size,
        minWidth: size,
      }}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99868 5.33366C7.90668 5.33366 7.83202 5.40833 7.83268 5.50033C7.83268 5.59233 7.90735 5.66699 7.99935 5.66699C8.09135 5.66699 8.16602 5.59233 8.16602 5.50033C8.16602 5.40833 8.09135 5.33366 7.99868 5.33366"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 14V14C4.686 14 2 11.314 2 8V8C2 4.686 4.686 2 8 2V2C11.314 2 14 4.686 14 8V8C14 11.314 11.314 14 8 14Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.99935 8V11.3333"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
