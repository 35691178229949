import { LegPrice, Quote, Trip } from '@busie/utils'

import {
  BFF_API_URL,
  Endpoints,
  HttpMethod,
  UNAUTHENTICATED_GUEST_TOKEN,
} from '../../constants'
import {
  CreateTripFormData,
  EmbeddedTripPlannerSettings,
  ETPAmenity,
  RouteRequest,
  RouteResponse,
} from '../../dataTypes'
import { makeRequest } from '../../helpers/axios-request'
import getPhoneNumberComponents from '../../helpers/get-phone-number-components'
import { handleTripCreatedData } from '../../helpers/handle-trip-created-data'

import { CreateCustomerFormData } from '../customers'
import { CreateQuoteData } from '../quotesAndBookings'

interface CustomerProfileWithGroup {
  name: string
  email: string
  countryCode: string
  areaCode: string
  phoneNumber: string
  group: { name: string }
}

export interface TripPlannerSettingsUpdateParams {
  domain: string
  hideInstantQuote: boolean
  requestToBook: boolean
  displayedAmenities: ETPAmenity[]
}

interface CreateOrganizationParams {
  orgId: string
  domain: string
}

interface GetOrganizationParams {
  apiKey: string
}

interface TriggerLocalVisitorIdParams {
  linkedId: string
  url: string
  visitorId: string
}

interface AuthenticateGuestParams {
  username: string
  password: string
}

export const createOrganizationAsGuest = (orgId: string, domain: string) =>
  makeRequest<CreateOrganizationParams, EmbeddedTripPlannerSettings>({
    authToken: UNAUTHENTICATED_GUEST_TOKEN,
    data: { orgId, domain },
    endpoint: Endpoints.ORGANIZATIONS,
    baseUrl: BFF_API_URL,
    method: HttpMethod.POST,
  })

export const getOrganizationAsGuest = (
  authToken: string,
  organizationApiKey: string
) =>
  makeRequest<GetOrganizationParams, EmbeddedTripPlannerSettings>({
    authToken,
    data: { apiKey: organizationApiKey },
    endpoint: Endpoints.ORGANIZATIONS,
    baseUrl: BFF_API_URL,
    method: HttpMethod.GET,
  })

export const triggerLocalVisitorIdHook = (
  linkedId: string,
  url: string,
  visitorId: string
) =>
  makeRequest<TriggerLocalVisitorIdParams, void>({
    authToken: UNAUTHENTICATED_GUEST_TOKEN,
    data: { linkedId, url, visitorId },
    endpoint: Endpoints.AUTH_FP,
    baseUrl: BFF_API_URL,
    method: HttpMethod.POST,
  })

export const authenticateGuestUser = async (
  organizationApiKey: string,
  organizationDomain: string,
  visitorId: string
) => {
  const response = await makeRequest<
    AuthenticateGuestParams,
    { access_token: string }
  >({
    authToken: UNAUTHENTICATED_GUEST_TOKEN,
    data: {
      username: `${organizationApiKey}:${organizationDomain}`,
      password: visitorId,
    },
    endpoint: Endpoints.AUTH_LOGIN,
    baseUrl: BFF_API_URL,
    method: HttpMethod.POST,
  })
  return response.access_token || UNAUTHENTICATED_GUEST_TOKEN
}

export const createCustomerAsGuest = (
  authToken: string,
  formData: CreateCustomerFormData
) => {
  const requestBody: CustomerProfileWithGroup = {
    name: formData.name.trim(),
    email: formData.email.trim(),
    group: { name: formData.groupname?.trim() || UNAUTHENTICATED_GUEST_TOKEN },
    ...getPhoneNumberComponents(formData.phoneNumber),
  }
  // TODO [BUSIE-2369]: define return type

  return makeRequest<
    CustomerProfileWithGroup,
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [key: string]: any
    }
  >({
    authToken,
    data: requestBody,
    endpoint: Endpoints.CUSTOMERS,
    baseUrl: BFF_API_URL,
    method: HttpMethod.POST,
  })
}

export const createTripAsGuest = async (
  authToken: string,
  formData: CreateTripFormData
): Promise<Trip> => {
  const trip = await makeRequest<CreateTripFormData, Trip>({
    authToken,
    data: formData,
    endpoint: Endpoints.TRIPS,
    baseUrl: BFF_API_URL,
    method: HttpMethod.POST,
  })
  return handleTripCreatedData(trip)
}

export const createQuoteAsGuest = (
  authToken: string,
  formData: CreateQuoteData
) =>
  makeRequest<CreateQuoteData, Quote>({
    authToken,
    data: formData,
    endpoint: Endpoints.QUOTES,
    baseUrl: BFF_API_URL,
    method: HttpMethod.POST,
  })

export const createRouteRequestAsGuest = (
  authToken: string,
  formData: RouteRequest
) =>
  makeRequest<RouteRequest, RouteResponse>({
    authToken,
    data: formData,
    endpoint: Endpoints.ROUTES,
    baseUrl: BFF_API_URL,
    method: HttpMethod.POST,
  })

export const getEmbeddedTripPlannerSettings = (
  authToken: string,
  organizationId: string
) =>
  makeRequest<Record<string, unknown>, EmbeddedTripPlannerSettings>({
    authToken,
    data: {},
    endpoint: `${Endpoints.ORGANIZATIONS}/${organizationId}`,
    baseUrl: BFF_API_URL,
    method: HttpMethod.GET,
  })

export const updateEmbeddedTripPlannerSettings = async (
  authToken: string,
  organizationId: string,
  params: TripPlannerSettingsUpdateParams
): Promise<EmbeddedTripPlannerSettings> =>
  makeRequest<TripPlannerSettingsUpdateParams, EmbeddedTripPlannerSettings>({
    authToken,
    data: params,
    endpoint: `${Endpoints.ORGANIZATIONS}/${organizationId}`,
    baseUrl: BFF_API_URL,
    method: HttpMethod.PUT,
  })

export const fetchLegPriceAsGuest = (id: string, authToken: string) =>
  makeRequest<Record<string, unknown>, LegPrice>({
    authToken,
    data: {},
    endpoint: `${Endpoints.LEG_PRICES}/${id}`,
    baseUrl: BFF_API_URL,
    method: HttpMethod.GET,
  })
