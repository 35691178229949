import { styled } from '@mui/system'

export const ModalContent = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    width: '480px',
    padding: theme.spacing(5),
    paddingTop: 0,
  }
})
