import axios from 'axios'
import { env, DispatchLocation } from '@busie/utils'
import { DispatchLocationFormData } from '../../dataTypes'

const BASE_URL = `${env('BUSIE_DISPATCH_LOCATION_SERVICE_API_URL')}`

export const getDispatchLocations = async (
  authToken: string
): Promise<DispatchLocation[]> => {
  const { data } = await axios.get(`${BASE_URL}/dispatch-locations`, {
    headers: { Authorization: `Bearer ${authToken}` },
  })

  return data
}

export const deleteDispatchLocation = async (
  id: string,
  authToken: string
): Promise<void> => {
  const { data } = await axios.delete(`${BASE_URL}/dispatch-locations/${id}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  })

  return data
}

export const createDispatchLocation = async (
  formData: DispatchLocationFormData,
  authToken: string
): Promise<void> => {
  const { data } = await axios.post(
    `${BASE_URL}/dispatch-locations`,
    formData,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  )

  return data
}

export const updateDispatchLocation = async (
  id: string,
  formData: DispatchLocationFormData,
  authToken: string
): Promise<void> => {
  const { data } = await axios.put(
    `${BASE_URL}/dispatch-locations/${id}`,
    formData,
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  )

  return data
}

export const getDispatchLocation = async (
  id: string,
  authToken: string
): Promise<DispatchLocation> => {
  const { data } = await axios.get(`${BASE_URL}/dispatch-locations/${id}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  })

  return data
}
