import React from 'react'
import { User } from '@auth0/auth0-spa-js'
import { useHistory } from 'react-router-dom'

import Menu from '../Menu'

import ProfilePictureBlock from '../ProfilePictureBlock'
import { CustomerIcon, LogoutIcon } from '../icons'
import { OrganizationsIcon } from '../icons/organizations'
import { styled } from '@mui/system'

const MenuItem = styled('div')(() => {
  return {
    display: 'flex',
    span: {
      paddingLeft: '10px',
    },
  }
})
interface Props {
  user?: User
  logout: () => void
  isSettingsAllowed: boolean
}

const ProfileMenu: React.FC<React.PropsWithChildren<Props>> = ({
  user,
  logout,
  isSettingsAllowed,
}: Props) => {
  const history = useHistory()

  const goToProfile = () => {
    history.push('/profile')
  }
  const goToOrganizations = () => {
    history.push('/organization-settings/dispatch')
  }
  const handleLogout = () => {
    logout()
  }
  return (
    <Menu
      right="0"
      top="100%"
      toggler={<ProfilePictureBlock user={user} size="40px" />}
      items={[
        {
          content: (
            <MenuItem>
              <CustomerIcon />
              <span>Profile</span>
            </MenuItem>
          ),
          action: goToProfile,
        },
        isSettingsAllowed
          ? {
              content: (
                <MenuItem>
                  <OrganizationsIcon />
                  <span>Organizations</span>
                </MenuItem>
              ),
              action: goToOrganizations,
            }
          : null,
        {
          content: (
            <MenuItem>
              <LogoutIcon />
              <span>Log Out</span>
            </MenuItem>
          ),
          action: handleLogout,
        },
      ]}
    />
  )
}

export default ProfileMenu
