import { styled } from '@mui/system'
import { inputLabelClasses } from '@mui/material/InputLabel'

import { palette } from '@busie/ui-kit'
import { InputLabelDotStyles } from '../../../../styles'

export const ContentBlockStyles = {
  maxWidth: '496px',
  paddingLeft: '24px',

  paddingBottom: '20px',

  marginLeft: '4px',
  borderLeft: `2px dashed ${palette?.green?.main}`,

  [`.${inputLabelClasses.root}`]: {
    position: 'relative',
    overflow: 'initial',
    marginTop: '0',
    height: '12px',
    lineHeight: '1',
    '&:before': { ...InputLabelDotStyles },
  },
}
const ContentBlock = styled('div')(() => {
  //   const palette = props.theme?.palette;
  return { ...ContentBlockStyles }
})

export default ContentBlock
