import React from 'react'

import { TableProps } from '@mui/material/Table'

import TableStyled from './styled/TableStyled'

import TableHead from './TableHead'
import TableBody from './TableBody'
import TableRow from './TableRow'
import TableCell from './TableCell'
import TableCellCheckbox from './TableCellCheckbox'
import TableCellSelectAll from './TableCellSelectAll'
import { SortDirection, TableSortLabel } from '@mui/material'
import { tableColumns, tableData } from './types'

interface Props extends TableProps {
  // required props to render table
  columnNames: tableColumns
  data: tableData

  //optional props for select row functionality
  withCheckbox?: boolean
  checkboxKeys?: string[]
  rowTypes?: string[]
  selectedRows?: string[]
  onRowClick?: (rowSelector: string, type?: string) => void
  onRowsSelect?: (rows: string[]) => void
  onRowsRemove?: (rows: string[]) => void
  onSort?: (id: string) => void
  order?: SortDirection
  orderBy?: string
  hideColumns?: boolean
  columnsToHide?: number[]
}

const Table: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  return (
    <TableStyled>
      {props.columnNames.length && (
        <TableHead>
          <TableRow>
            {props.withCheckbox && (
              <TableCellSelectAll
                onRowsSelect={() =>
                  props.onRowsSelect &&
                  props.onRowsSelect(props.checkboxKeys || [])
                }
                onRowsRemove={() =>
                  props.onRowsRemove &&
                  props.onRowsRemove(props.checkboxKeys || [])
                }
                dataLength={props.data.length}
                selectedRowsLength={props.selectedRows?.length || 0}
              />
            )}
            {props.columnNames.map((column, index) =>
              props.hideColumns &&
              props.columnsToHide?.includes(index) ? null : (
                <TableCell
                  key={index}
                  width={column.width}
                  sortDirection={
                    props.orderBy === column.id ? props.order : false
                  }
                >
                  {column.ordered ? (
                    <TableSortLabel
                      active={props.orderBy === column.id}
                      direction={
                        (props.orderBy === column.id && props.order) || 'asc'
                      }
                      onClick={() => props.onSort && props.onSort(column.id)}
                    >
                      {column.name}
                    </TableSortLabel>
                  ) : (
                    column.name
                  )}
                </TableCell>
              )
            )}
          </TableRow>
        </TableHead>
      )}
      {props.data.length ? (
        <TableBody>
          {props.data.map((row, rowIndex) => {
            return (
              <TableRow key={rowIndex}>
                {props.withCheckbox && (
                  <TableCellCheckbox
                    hidden={!props.withCheckbox}
                    selectedRows={props.selectedRows || []}
                    checkboxKeys={props.checkboxKeys || []}
                    addRow={(key: string) =>
                      props.onRowsSelect && props.onRowsSelect([key])
                    }
                    removeRow={(key: string) =>
                      props.onRowsRemove && props.onRowsRemove([key])
                    }
                    index={rowIndex}
                  />
                )}
                {Object.keys(row).map((cell, i) => {
                  return props.hideColumns &&
                    props.columnsToHide?.includes(i) ? null : (
                    <TableCell
                      key={i}
                      className={props.onRowClick ? 'has-action' : ''}
                      onClick={() =>
                        props.onRowClick &&
                        props.onRowClick(
                          (props.checkboxKeys &&
                            props.checkboxKeys[rowIndex]) ||
                            '',
                          (props.rowTypes && props.rowTypes[rowIndex]) || ''
                        )
                      }
                    >
                      {row[cell]}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      ) : (
        <tbody />
      )}
    </TableStyled>
  )
}

export default Table
