import React from 'react'
import { User } from '@auth0/auth0-react'
import { RumInitConfiguration } from '@datadog/browser-rum'
import { ReactNode } from 'react'
import { useDatadog } from '../hooks/use-datadog'

interface Props extends RumInitConfiguration {
  enabled?: boolean | undefined
  sessionReplayRecording?: boolean | undefined
  user?: User | undefined
  enableHeatmap?: boolean | undefined
  enableFeatureFlags?: boolean | undefined
  children: ReactNode
}

const Datadog: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  ...restInitProps
}) => {
  useDatadog({ ...restInitProps })

  return <div style={{ height: '100%' }}>{children}</div>
}

export default Datadog
