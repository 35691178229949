import { UseQueryResult, useQuery } from 'react-query'
import { Recipe } from '@busie/utils'
import { fetchRecipe } from '@busie/api'

const RECIPE_QUERY_KEY = Symbol()

export const useRecipe = (
  authToken: string,
  recipeId: string
): UseQueryResult<Recipe> => {
  return useQuery<Recipe, Error>(
    [RECIPE_QUERY_KEY, recipeId],
    () => fetchRecipe(recipeId, authToken),
    {
      enabled: !!authToken && !!recipeId,
      onSuccess: (recipeResponse) => undefined,
    }
  )
}
