import React from 'react'
import DeparturesForm from './components/DeparturesForm'
import RouteForm from './components/RouteForm'
import TripForm from './components/TripForm'
import SummaryBlock from './components/SummaryBlock'
import CustomerForm from './components/CustomerForm'
import store from './store'

export const stepList: string[] = [
  'Customer',
  'Trip',
  'Route',
  'Departures',
  'Summary',
]

const steps = {
  Customer: CustomerForm,
  Trip: TripForm,
  Route: RouteForm,
  Departures: DeparturesForm,
  Summary: SummaryBlock,
} as const

export type StepName = keyof typeof steps

const getFormByStep = (props: Props) => {
  const forms = props.stepList.map((step) => {
    const Component = steps[step]
    return <Component key={step} {...props} />
  })
  return forms[props.step]
}

interface Props {
  customersAuthToken: string
  pathfinderAuthToken: string
  tripsAuthToken: string
  quotesAuthToken: string
  rateServiceAuthToken: string
  guestAuthToken: string
  embedded?: boolean
  embeddedFormOrgId?: string
  step: number
  goNext: () => void
  goPrev: () => void
  setCurrentStep: (step: number) => void
  setNextStep: (step: number) => void
  stepList: StepName[]
  quoteId?: string
  onClose?: () => void
}

const FormBlock: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  if (props.quoteId) {
    store.setQuoteId(props.quoteId)
  }

  return <>{getFormByStep(props)}</>
}

export default FormBlock
