import React from 'react'
import InputLabelStyled from './styled/InputLabelStyled'

interface Props {
  required?: boolean
  children: string | React.ReactNode
  htmlFor?: string
}

const InputLabel: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  required,
  htmlFor,
}) => {
  return (
    <InputLabelStyled htmlFor={htmlFor}>
      {children}
      {required && <span className="red"> *</span>}
    </InputLabelStyled>
  )
}

export default InputLabel
